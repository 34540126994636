import React, { useEffect, useRef, useState } from "react";
import "./CustomDropDownStyle.css";
import Icons from "../../Constants/Icons";
function CustomDropdown({ items, onSelect, dropDownLabel,onItemOpen, customLabelStyle }) {

  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(dropDownLabel);

  useEffect(()=>{
    setSelectedItem(dropDownLabel)
  },[dropDownLabel])

  const toggleDropdown = () => {
    if(onItemOpen){
    onItemOpen(!isOpen)
    }
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    onSelect(item);
  };

  const handleMouseLeave = () => {
    if(onItemOpen){
    onItemOpen(false)
    }
    setIsOpen(false);

  };

  return (
    <div
      onMouseLeave={() => handleMouseLeave()}
      onClick={toggleDropdown}
      className="custom-dropdown"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 26,
        }}
      >
        <div
          onClick={toggleDropdown}
          style={customLabelStyle}
          className="fontSize16"
        >
          {selectedItem}
        </div>
        <div className={` ${isOpen ? 'validmenuround' : 'menuround'}`} style={{ display: "flex" }}>
          <img className="downArrowIcon" alt="" src={Icons.ic_down} />
        </div>
      </div>
      {isOpen && (
        <div style={{ position: "absolute", width: "100%" }}>
          <div style={{ height: 10 }}></div>
          <ul style={{width:"100%"}} className="dropdown-menu">
            {items.map((item) => (
              <li key={item} onClick={() => handleItemClick(item)}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
