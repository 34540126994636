import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import Header from "../../../Components/Header/Header";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Icons from "../../../Constants/Icons";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import { CustomUploadBannerImage } from "../../../Core/CustomUploadBannerImage";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  onApiCallAddBAnner,
  onApiCallEditBanner,
  onApiCallShopGetAllShopCategories,
} from "../../../Core/Apicall";
import { toast } from "react-toastify";

function AddBanner() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, dataEdit } = location.state || {};
  const navigate = useNavigate();
  const isProfileData = useSelector((state) => state.isProfileData);
  const fileProductInputRef = useRef(null);
  const [dropZIndexManage, setDropZIndexManage] = useState(false);
  const [astrologerValidation, setAstrologerValidationMsg] = useState(false);
  const [categoriesShop, setCategoriesShop] = useState([]);

  const [initalCategoriesShop, setInitialCategoriesShop] = useState([]);

  const [formData, setFormData] = useState({
    bannerImage: data?.bannerImage ?? "",
    type: data?.type ?? "",
    astrologerPer: data?.astrologerPer ?? "",
    title: data?.title ?? "",
    shop_category: "",
  });
  const [formErrors, setFormErrors] = useState({
    bannerImage: false,
    astrologerPer: false,
    type: false,
    title: false,
    shop_category: false,
  });

  useEffect(() => {
    onApiCallShopCategory();
  }, []);
  const onApiCallShopCategory = async () => {
    dispatch(setLoadingTrue());
    try {
      const res = await onApiCallShopGetAllShopCategories();
      setInitialCategoriesShop(res?.Categories);
      const categoryNames = res?.Categories.map((item) => item?.categoryName);
      const shopTypeSelect =
        res?.Categories?.find((item) => item?._id === data?.shop_category)
          ?.categoryName ?? "";
      setFormData((prevFormData) => ({
        ...prevFormData,
        shop_category: shopTypeSelect,
      }));
      setCategoriesShop(categoryNames);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };
  const handleImageChangeProduct = async (event) => {
    const formData = new FormData();
    formData.append("bannerImage", event.target.files[0]);
    try {
      const data = await CustomUploadBannerImage(formData, isProfileData.token);
      setFormData((prevFormData) => ({
        ...prevFormData,
        bannerImage: data.location,
      }));
    } catch (error) {
    }
  };

  const validateForm = () => {
    const errors = {
      type: formData.type == "",
      astrologerPer:
        formData.type === "Astrologer" && formData.astrologerPer === "",
      bannerImage: formData.bannerImage == "",
      title: formData.title == "",
      shop_category: formData.type === "Shop" && formData?.shop_category == "",
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const onClickAddBanner = async () => {
    if (validateForm()) {
      let param = {
        bannerType:
          formData.type === "Astrologer"
            ? "astrologer"
            : formData.type === "Shop"
            ? "shop"
            : formData.type === "Live"
            ? "live"
            : "wallet",
        imageUrl: formData.bannerImage,
        title: formData.title,
      };

      if (formData.type === "Astrologer") {
        param.discountPercentage = formData.astrologerPer;
      }

      if (formData.type === "Shop") {
        param.categoryId = initalCategoriesShop.filter(
          (item) => item?.categoryName == formData?.shop_category
        )[0]._id;
      }
      try {
        dispatch(setLoadingTrue());
        if (dataEdit) {
          param.bannerId = data.bannerId;
          await onApiCallEditBanner(param);
        } else {
          await onApiCallAddBAnner(param);
        }
        toast.success(
          dataEdit
            ? "Banner updated successfully"
            : "Banner added successfully",
          {
            autoClose: 1200,
            // hideProgressBar: false,
            // draggable: false,
          }
        );

        const event = new CustomEvent("subTabEventListner", {
          detail: "/BannerList",
        });
        window.dispatchEvent(event);

        navigate("/BannerList", { state: {} });
        clearData();
        dispatch(setLoadingFalse());
      } catch (error) {
        dispatch(setLoadingFalse());
      }
    }
  };

  const clearData = () => {
    setFormData({
      type: "",
      bannerImage: "",
      astrologerPer: "",
      title: "",
    });
  };

  const handleInputChange = (e, fieldName) => {
    let value = e.target.value;

    if (fieldName == "title") {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    } else {
      value = value.replace(/[^0-9]/g, "");

      if (value > 100) {
        setAstrologerValidationMsg(true);
        return;
      }
      setAstrologerValidationMsg(false);
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
  };

  return (
    <div
      style={{
        padding: 20,

        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={`Banner`} />
      <div
        style={{
          display: "flex",
          borderRadius: "10px",
          padding: "10px",
          alignItems: "center",
          background: Colors.mainDarkColor,
          justifyContent: "space-between",
        }}
      >
        <div className="fontSize24" style={{ fontWeight: 400 }}>
          {`${dataEdit ? "Edit" : "Add"} Banner`}
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <div
            className="fontSize16"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 500,
              border: "1px solid #ffffff10",
              background: "#FFF",
              color: "#1E1E25",
              borderRadius: "50px",
              padding: "4px 10px 4px 10px",
            }}
            onClick={() => onClickAddBanner()}
          >
            {dataEdit ? "Update" : "Submit"}
          </div>
        </div>
      </div>
      <div
        style={{ maxHeight: "33rem", paddingBottom: "20px", marginTop: "20px" }}
        className="scrollable-container"
      >
        <div className="add-inventory-container">
          <div style={{}}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                fontSize: "1rem",
              }}
            >
              <div
                style={{ marginTop: "20px", background: "#25252D" }}
                className="add-inventory-main-div-section"
              >
                <div style={{ display: "flex", gap: "20px", zIndex: 11 }}>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Banner Type
                    </div>
                    <div
                      style={{
                        zIndex: 1,
                        padding: "2.5px 10px 2.5px 10px",
                        border: formErrors.type
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-from-to-div"
                    >
                      <CustomDropdown
                        customLabelStyle={{
                          color: formData?.type ? "#FFF" : "#FFFFFF33",
                          fontSize: "0.75rem",
                          fontWeight: "normal",
                        }}
                        onItemOpen={(item) => setDropZIndexManage(item)}
                        dropDownLabel={
                          formData?.type == ""
                            ? "Select Banner Type"
                            : formData?.type
                        }
                        items={["Astrologer", "Live", "Shop", "Wallet"]}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            type: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Banner Title
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.title
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="Title"
                      value={formData.title}
                      onChange={(e) => handleInputChange(e, "title")}
                    />
                  </div>
                </div>

                {formData.type === "Shop" && (
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <div className="modal-second-div-title">
                      <div style={{}} className="fontSize16">
                        Select Category
                      </div>
                      <div
                        style={{
                          zIndex: dropZIndexManage ? -1 : 1,
                          padding: "2.5px 10px 2.5px 10px",
                          border: formErrors.shop_category
                            ? "1px solid red"
                            : "1px solid rgba(255, 255, 255, 0.2)",
                        }}
                        className="modal-from-to-div"
                      >
                        <CustomDropdown
                          customLabelStyle={{
                            color: formData?.shop_category
                              ? "#FFF"
                              : "#FFFFFF33",
                            fontSize: "0.75rem",
                            fontWeight: "normal",
                          }}
                          dropDownLabel={
                            formData?.shop_category == ""
                              ? "Select Category"
                              : formData?.shop_category
                          }
                          items={categoriesShop}
                          onSelect={(value) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              shop_category: value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {formData.type == "Astrologer" && (
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Astrologer In Percentage
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        border: formErrors.astrologerPer
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        className="modal-dropdown"
                        type="text"
                        placeholder="Percentage"
                        value={formData.astrologerPer}
                        onChange={(e) => handleInputChange(e, "astrologerPer")}
                      />
                    </div>

                    {astrologerValidation && (
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "5px",
                          color: "red",
                          width: "40vh",
                        }}
                        className="fontSize16"
                      >
                        Please enter value less than 100
                      </div>
                    )}
                  </div>
                )}
                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Banner image
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      alignSelf: "stretch",
                      borderRadius: "16px",
                      border: formErrors.bannerImage
                        ? "1px dashed red"
                        : "1px dashed rgba(255, 255, 255, 0.2)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "2rem 1rem",
                      gap: "0.5rem",
                      color: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileProductInputRef}
                      onChange={handleImageChangeProduct}
                    />
                    <img
                      style={{
                        position: "relative",
                        width: formData.bannerImage ? "18rem" : "5rem",
                        height: formData.bannerImage ? "12rem" : "5rem",
                        cursor: "pointer",
                        borderRadius: "7px",
                        objectFit: formData.bannerImage ? "contain" : "contain",
                      }}
                      alt=""
                      src={
                        formData.bannerImage
                          ? formData.bannerImage
                          : Icons.ic_upload_photo
                      }
                      onClick={() => {
                        fileProductInputRef.current.value = "";
                        fileProductInputRef.current.click();
                      }}
                    />
                    <div
                      style={{ color: "#FFFFFF80", textAlign: "center" }}
                      className="fontSize16"
                    >
                      Upload Banner Image{<br />}
                      <span style={{ fontSize: "0.7rem" }}>
                        (750px x 740px)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBanner;
