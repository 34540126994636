import instance from "./ApiConfig";

export const baseApiCall = (config) => {
  return new Promise((resolve, reject) => {
    instance(config)
      .then((response) => {
        console.log('response Api', response);
         if (response?.status === 200) {
          resolve(response?.data);
        } 
      })
      .catch((e) => {
        console.log('api error', e);
        reject(e.response.data.message);
      });
  });
};
