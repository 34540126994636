import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import Icons from "../../../Constants/Icons";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Header from "../../../Components/Header/Header";
import "./UserScreenStyle.css";
import { useNavigate } from "react-router-dom";
import { getAllUser } from "../../../Core/Apicall";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import UseDebounce from "../../../Components/UseDebounce";

function User() {
  const dispatch = useDispatch();

  const statusDropdownItem = ["All", "Online", "Busy", "Offline"];
  const [userListData, setUserListData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalUsersOverview, setTotalUsersOverview] = useState(0);
  const [newJoinedUsersToday, setNewJoinedUsersToday] = useState(0);
  const [totalOnlineUsers, setTotalOnlineUsers] = useState(0);
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  const handleSelect = (status) => {};

  const debouncedInput = UseDebounce(searchValue, 500);

  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  useEffect(() => {
    setPageComments(0);
    setUserListData([]);
    onClickUserMainListApiCall(searchValue, 0);
  }, [debouncedInput]);
  const onClickUserMainListApiCall = (searchValue, pageComments) => {
    let param = {
      offset: 30 * pageComments,
      limit: 30,
      search: searchValue,
    };
    try {
      dispatch(setLoadingTrue());
      getAllUser(param).then((response) => {
        setTotalUsers(Math.ceil(response.totalCountForPagination / 30));
        setTotalUsersOverview(response.totalUsers);
        setNewJoinedUsersToday(response.newJoinedUsersToday);
        setTotalOnlineUsers(response.totalOnlineUsers =="" ? 0 :response.totalOnlineUsers);
        setUserListData((prevData) => [
          ...prevData,
          ...response.UsersProfilesData,
        ]);

        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalUsers > pageComments + 1) {
        setPageComments((prevPage) => {
          const nextPage = prevPage + 1;
          onClickUserMainListApiCall(searchValue, nextPage);
          return nextPage;
        });
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"User"} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
        }}
      >
        <div style={{}} className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total users</div>
                <div className="fontSize24">{totalUsersOverview}</div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total online</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {totalOnlineUsers}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">New joined today</div>
                <div style={{ color: Colors.darkBlue }} className="fontSize24">
                  {newJoinedUsersToday}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div className="fontSize24">Users list</div>
        <div className="frame-parent2">
          <div className="custom-box-user">
            {/* <div className="custom-container-dropdown-div">
              <CustomDropdown
                dropDownLabel={dropdownSelected}
                items={statusDropdownItem}
                color="blue"
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: "400",
                  color: Colors.greyColor50,
                }}
              />
            </div> */}

            <div className="custom-box-search-div">
              <img className="custom-image" alt="" src={Icons.ic_search} />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="list-user-div-container">
        <div className="list-user-div-parent-container">
          <div
            style={{
              width: "20%",
            }}
            className="fontSize16WithoutFlex"
          >
            User ID
          </div>
          <div
            style={{
              width: "20%",
            }}
            className="fontSize16WithoutFlex"
          >
            User name
          </div>
          <div
            style={{
              width: "22%",
            }}
            className="fontSize16WithoutFlex"
          >
            Contact
          </div>
          <div
            style={{
              width: "20%",
            }}
            className="fontSize16WithoutFlex"
          >
            Birth Details
          </div>
          <div
            style={{
              width: "12%",
            }}
            className="fontSize16WithoutFlex"
          >
            Languages
          </div>
          {/* <div
            style={{
              width: "9%",
            }}
            className="fontSize16WithoutFlex"
          >
            Status
          </div> */}
        </div>
        <div
          style={{ maxHeight: "500px" }}
          onScroll={handleScroll}
          ref={commentsContainerRef}
          className="scrollable-container"
        >
          {userListData?.map((transaction, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "stretch",
                }}
              >
                <div
                  onClick={() =>
                    navigate("/UserProfile", {
                      state: {
                        userId: transaction.userId,
                        userShortId:transaction?.userShortId ??transaction.userId,
                      },
                    })
                  }
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "1rem",
                    boxSizing: "border-box",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "0.5rem",
                      fontSize: "1.13rem",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "0.8rem",
                        fontWeight: 400,
                        color: "#fff",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {transaction?.userShortId??transaction?.userId}
                    </div>
                    {/* <img
                      style={{
                        position: "relative",
                        width: "1.13rem",
                        height: "1.13rem",
                        overflow: "hidden",
                        flexShrink: "0",
                      }}
                      alt=""
                      src={Icons.ic_copy}
                    /> */}
                  </div>
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "0.5rem",
                      fontSize: "1.13rem",
                    }}
                  >
                    <div style={{}} className="indian-man-smiling-astrologer">
                      <img
                        style={{
                          position: "relative",
                          width: "2.63rem",
                          height: "2.63rem",
                        }}
                        alt=""
                        src={transaction?.profileUrl ?? Icons.ic_default_user}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="itemTextDisplay">
                        {transaction.userName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          fontSize: "1rem",
                          color: "#818181",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <img
                            className="icon_style_list"
                            alt=""
                            src={Icons.ic_wallet}
                          />
                          <div
                            className="itemTextDisplay"
                            style={{
                              position: "relative",
                              color: "rgba(129, 129, 129, 1)",
                            }}
                          >
                            {transaction.walletBalance}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "22%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "0.25rem",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                      }}
                    >
                      <img
                        className="icon_style_list"
                        alt=""
                        src={Icons.ic_sms}
                      />
                      <div className="itemTextDisplay">{transaction.email}</div>
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                      }}
                    >
                      <img
                        className="icon_style_list"
                        alt=""
                        src={Icons.ic_call}
                      />
                      <div className="itemTextDisplay">
                        {transaction.mobile}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "0.25rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                      }}
                    >
                      <img
                        className="icon_style_list"
                        alt=""
                        src={Icons.ic_calendar}
                      />
                      <div style={{}} className="itemTextDisplay">
                        {transaction.birthDate
                          ? `${transaction.birthDate} , ${transaction.birthTime}`
                          : "-"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                      }}
                    >
                      <img
                        className="icon_style_list"
                        alt=""
                        src={Icons.ic_location}
                      />
                      <div className="itemTextDisplay">
                        {transaction.birthPlace == ""
                          ? "-"
                          : transaction.birthPlace}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "12%",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                    }}
                    className="itemTextDisplay"
                  >
                    {transaction.languages.map((languageObject, index) => (
                      <span key={index}>
                        {" "}
                        {languageObject.language}
                        {index !== transaction.languages.length - 1 && ", "}
                      </span>
                    ))}
                  </div>
                  {/* <StatusButton
                    style={{
                      backgroundColor:
                        transaction.status === "Online"
                          ? Colors.mainGreen01
                          : transaction.status === "Busy"
                          ? Colors.mainOrange01
                          : Colors.mainRed01,
                      borderColor:
                        transaction.status === "Online"
                          ? Colors.darkGreen
                          : transaction.status === "Busy"
                          ? Colors.darkOrange
                          : Colors.darkRed,
                      color:
                        transaction.status === "Online"
                          ? Colors.darkGreen
                          : transaction.status === "Busy"
                          ? Colors.darkOrange
                          : Colors.darkRed,
                    }}
                  >
                    {transaction.status}
                  </StatusButton> */}
                </div>
                <div className="borderTopStyle" />
              </div>
            );
          })}
        </div>
      </div>

      {userListData?.length <= 0 && <EmptyData />}
      {/* {userListData?.length != 0 && 
      <div
        style={{
          alignItems: "flex-end",
          display: "flex",
          flex: 1,
          alignSelf: "stretch",
          justifyContent: "flex-end",
        }}
      >
        <CustomPagination
          pageCount={totalUsers}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={onClickUserMainListApiCall}
        />
      </div>
      } */}
    </div>
  );
}

export default User;
