// Home.js
import React, { useCallback, useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import Icons from "../../../Constants/Icons";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Header from "../../../Components/Header/Header";
import "./CustomerScreenStyle.css";
import StatusButton from "../../../Components/StatusButton/StatusButton";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import SocketCall from "../../../Core/SocketCall";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  apiGetTicketList,
  postSupportAllMessage,
  postUpdateTicket,
} from "../../../Core/Apicall";
import UseDebounce from "../../../Components/UseDebounce";

const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    padding: 0,
    margin: "auto",
    maxWidth: "400px",
    width: "100%",
    border: "none",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function Customer() {
  const isProfile = useSelector((state) => state.isProfileData);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isModalOpen, setIsMenuOpen] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isModalCloseTicket, setIsModalCloseTicket] = useState(false);
  const [supportChatVisible, setSupportChatVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [imageSelectedUrl, setImageSelectedUrl] = useState(null);

  const [userImageUrl, setUserImageUrl] = useState("");

  const [totalTicket, setTotalticket] = useState(0);
  const [totalTicketPagination, setTotalticketPagination] = useState(0);
  const [closedTicket, setClosedTicket] = useState(0);
  const [newTicket, setNewTicket] = useState(0);
  const [openTicket, setOpenTickeT] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const statusDropdownItem = ["All", "Open", "New", "Close"];
  const [roomId, setRoomID] = useState("");
  const [userReceiverId, setUserReceiverId] = useState("");
  const [currentTicketId, setCurrentTicketId] = useState("");
  const [message, setMessage] = useState("");
  const [selectItemBackgroundColor, setSelectBackgroundChange] = useState(null);
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  const scrollableDivRef = useRef(null);
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const debouncedInput = UseDebounce(searchValue, 500);

  const dispatch = useDispatch();


  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText)
  };

  const handleCheckboxChange = () => {
    setIsModalCloseTicket(true);
  };
  const closeModal = () => {
    setIsMenuOpen(false);
  };
  const supportChatApiCall = (item) => {
    dispatch(setLoadingTrue());
    setIsChecked(item.status == "Close" ? true : false);
    setCurrentTicketId(item.ticketId);
    setUserReceiverId(item.userId);
    setUserImageUrl(item.userImage);
    setRoomID(item.roomId);
    const data = {
      userId: item.userId,
      adminId: isProfile?.adminId,
      roomId: item.roomId,
      ticketId: item.ticketId,
    };
    const dataJoinRoom = {
      roomId: item.roomId,
    };
    SocketCall.emit("support-create-session", data);
    SocketCall.emit("join-room", dataJoinRoom);
    setSelectBackgroundChange(item.ticketId);
    postSupportAllMessage({ userId: item.userId, roomId: item.roomId })
      .then((response) => {
        setMessageList(response?.data);
        dispatch(setLoadingFalse());
        setSupportChatVisible(true);
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };
  const onClickSendMessage = () => {
    const data = {
      senderId: isProfile?.adminId,
      receiverId: userReceiverId,
      roomId: roomId,
      message: message,
      senderType: "Admin",
      messageType: "text",
      imageUrl: "",
    };
    SocketCall.emit("support-send-message", data);
    setMessage("");
  };

  const apiCallCloseTicket = () => {
    setIsModalCloseTicket(false);
    dispatch(setLoadingTrue());
    postUpdateTicket({ ticketId: currentTicketId, status: "Close" })
      .then((response) => {
        const data = {
          roomId: roomId,
        };
        SocketCall.emit("support-close-session", data);
        setSupportChatVisible(false);
        const updatedCustomerList = customerList?.map((customer) => {
          if (customer.roomId === roomId) {
            return { ...customer, status: "Close" };
          }
          return customer;
        });
        setCustomerList(updatedCustomerList);
        setIsChecked(true);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  useEffect(() => {
    setPageComments(0);
    setCustomerList([])
    const searchQuery = debouncedInput || searchValue;
    onApiCallTicketList(dropdownSelected,searchQuery, 0);
  }, [dropdownSelected,debouncedInput]);
  const onApiCallTicketList = (dropdownSelected,search, pageComments) => {
    const searchStatus = search || "";
    dispatch(setLoadingTrue());
    let param = {
      offset: 30 * pageComments,
      limit: 30,
      search: searchStatus,
      ticketStatus:dropdownSelected=="All"?"":dropdownSelected
    };
    apiGetTicketList(param)
      .then((response) => {
        setCustomerList((prevData) => [
          ...prevData,
          ...response?.data?.ticketlist,
        ]);
        setTotalticketPagination(Math.ceil(response?.data?.totalticket / 30));
        setTotalticket(response?.data?.totalticket);
        setClosedTicket(response?.data?.closedticket);
        setNewTicket(response?.data?.newticket);
        setOpenTickeT(response?.data?.openticket);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [messageList]);
  useEffect(() => {
    SocketCall.connect();
  }, []);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalTicketPagination > pageComments + 1) {
        setPageComments((prevPage) => {
          const nextPage = prevPage + 1;
          onApiCallTicketList(dropdownSelected,searchValue, nextPage);
          return nextPage;
        });
      }
    }
  };

  const renderCouponContent = () => {
    return (
      <div className="list-user-div-parent-container-coupon">
        <div
          style={{
            width: "30%",
          }}
          className="fontSize16WithoutFlex"
        >
          Ticket ID
        </div>
        <div
          style={{
            width: "25%",
          }}
          className="fontSize16WithoutFlex"
        >
          Ticket rise by
        </div>
        <div
          style={{
            width: "15%",
          }}
          className="fontSize16WithoutFlex"
        >
          Date & Time
        </div>
        <div
          style={{
            width: "20%",
          }}
          className="fontSize16WithoutFlex"
        >
          Ticket type
        </div>
        <div
          style={{
            width: "10%",
          }}
          className="fontSize16WithoutFlex"
        >
          Status
        </div>
        {/* <div style={{ width: "3px" }}></div> */}
      </div>
    );
  };
  const renderCouponList = () => {
    return (
      <div
        style={{ maxHeight: "500px" }}
        ref={commentsContainerRef}
        onScroll={handleScroll}
        className="scrollable-container"
      >
        {customerList?.map((item, index) => {
          {
            {
              /* postUpdateTicket({ ticketId: item.ticketId, status: "New" })   */
            }
          }
          return (
            <div
              onClick={() => supportChatApiCall(item)}
              key={index}
              style={{
                cursor: "pointer",
                flex: 1,
                background:
                  selectItemBackgroundColor == item.ticketId
                    ? "#25252d"
                    : "transparent",
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // alignSelf: "stretch",
                  padding: "1rem",
                  // boxSizing: "border-box",
                  gap: "0.5rem",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    fontSize: "1.13rem",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "0.8rem",
                      fontWeight: 400,
                      color: "#fff",
                    }}
                  >
                    {item.ticketId}
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "1.13rem",
                      height: "1.13rem",
                      overflow: "hidden",
                      flexShrink: "0",
                    }}
                    alt=""
                    src={Icons.ic_copy}
                  />
                </div>
                <div style={{ width: "25%" }} className="customer_userCard">
                  <div style={{}} className="indian-man-smiling-astrologer">
                    <img
                      style={{
                        position: "relative",
                        width: "2.63rem",
                        height: "2.63rem",
                      }}
                      alt=""
                      src={item.userImage}
                    />
                  </div>
                  <div className="customer_userInfo">
                    <div className="itemTextDisplay">{item.username}</div>
                    <div className="customer_userDetails">
                      <div
                        style={{
                          position: "relative",
                          color: "#818181",
                        }}
                        className="itemTextDisplay"
                      >
                        {item.type}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "15%",
                  }}
                  className="itemTextDisplay"
                >
                  {moment(item.datetime).format("YYYY-MM-DD")} <br />
                  {moment(item.datetime).format("hh:mm:ss a")}
                </div>
                <div
                  style={{
                    width: "20%",
                    borderRadius: "20px",
                    height: "1.8rem",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    border: Colors.darkBorderColor,
                  }}
                  className="itemTextDisplay"
                >
                  {item.ticketType}
                </div>
                <StatusButton
                  style={{
                    width: "10%",
                    backgroundColor:
                      item.status === "Open"
                        ? Colors.mainGreen01
                        : item.status === "New"
                        ? Colors.mainBlue01
                        : Colors.mainRed01,
                    borderColor:
                      item.status === "Open"
                        ? Colors.darkGreen
                        : item.status === "New"
                        ? Colors.darkBlue
                        : Colors.darkRed,
                    color:
                      item.status === "Open"
                        ? Colors.darkGreen
                        : item.status === "New"
                        ? Colors.darkBlue
                        : Colors.darkRed,
                  }}
                >
                  {item.status}
                </StatusButton>
              </div>
              <div className="borderTopStyle" />
            </div>
          );
        })}
      </div>
    );
  };

  const renderSuppotChat = () => {
    return (
      <div
        style={{
          padding: "0.5rem",
          flex: 0.3,
        }}
      >
        <div
          style={{ marginTop: "0.5rem", color: Colors.secondary }}
          className="fontSize16"
        >
          TicketID#{currentTicketId}
        </div>
        <div
          ref={scrollableDivRef}
          style={{
            marginTop: "1rem",
            flex: "1",
            height: "30rem",
            gap: "0.5rem",
            overflowY: "scroll",
            maxHeight: "30rem",
          }}
          className="scrollable-container"
        >
          {messageList?.length == 0 ? (
            <div>
              <EmptyData />
            </div>
          ) : (
            messageList?.map((chat, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    marginTop: "15px",
                    flexDirection: "row",
                    margin: 5,
                    justifyContent:
                      chat.senderId === isProfile?.adminId
                        ? "flex-end"
                        : "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  {chat.senderId === isProfile?.adminId && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        gap: "0.13rem",
                        flex: 1,
                      }}
                    >
                      {chat.messageType == "text" ? (
                        <div
                          style={{
                            borderRadius: "8px",
                            border: "1px solid rgba(255, 255, 255, 0.1)",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            padding: "0.5rem 0.75rem",
                            fontSize: "0.7rem",
                            wordBreak: "break-word",
                          }}
                          className="itemTextDisplay"
                        >
                          {chat.message}
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            document.body.style.overflow = "hidden";
                            setIsImageModalOpen(true);
                            setImageSelectedUrl(chat.imageUrl);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            style={{
                              objectFit: "contain",
                              width: "12rem",
                              borderRadius: "10px",
                              height: "12rem",
                            }}
                            alt=""
                            src={chat.imageUrl}
                          />
                        </div>
                      )}

                      <div
                        className="itemTextDisplay"
                        style={{ color: Colors.secondary }}
                      >
                        {moment.utc(chat.datetime).local().format("hh:mm")}
                      </div>
                    </div>
                  )}
                  <div style={{}} className="indian-man-smiling-astrologer">
                    <img
                      style={{
                        position: "relative",
                        width: "2.63rem",
                        height: "2.63rem",
                      }}
                      alt=""
                      src={
                        chat.senderId === isProfile?.adminId
                          ? isProfile?.ImageURL
                          : userImageUrl
                      }
                    />
                  </div>
                  {chat.senderId !== isProfile?.adminId && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "0.13rem",
                        flex: 1,
                      }}
                    >
                      {chat.messageType == "text" ? (
                        <div
                          style={{
                            borderRadius: "8px",
                            border: "1px solid rgba(255, 255, 255, 0.1)",
                            display: "flex",
                            flexDirection: "row",
                            padding: "0.5rem 0.75rem",
                            fontSize: "0.7rem",
                            wordBreak: "break-word",
                          }}
                          className="itemTextDisplay"
                        >
                          {chat.message}
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            document.body.style.overflow = "hidden";
                            setIsImageModalOpen(true);
                            setImageSelectedUrl(chat.imageUrl);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            style={{
                              objectFit: "contain",
                              width: "12rem",
                              borderRadius: "10px",
                              height: "12rem",
                            }}
                            alt=""
                            src={chat.imageUrl}
                          />
                        </div>
                      )}

                      <div
                        className="itemTextDisplay"
                        style={{ color: Colors.secondary }}
                      >
                        {moment.utc(chat.datetime).local().format("hh:mm")}
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          )}
        </div>
        {isChecked ? (
          <div
            style={{
              textAlign: "center",
              color: Colors.secondary,
            }}
            className="fontSize16"
          >
            This ticket is closed
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",

              bottom: 0,
            }}
          >
            <input
              className="modal-main-input"
              type="text"
              placeholder="Message"
              style={{
                color: Colors.white,
                flex: 1,
                background: "#2D2D33",
              }}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter" && message !== "") {
                  onClickSendMessage();
                }
              }}
            />
            <img
              onClick={() => message != "" && onClickSendMessage()}
              style={{
                position: "relative",
                width: "2.3rem",
                height: "2.3rem",
                cursor: message == "" ? "not-allowed" : "pointer",
              }}
              alt=""
              src={Icons.ic_send_message}
            />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    SocketCall.on("support-send-message", (data) => {
      if (data.roomId == roomId) {
        const newMessage = {
          senderId: data.senderId,
          message: data.message,
          imageUrl: data?.imageUrl,
          receiverId: data.receiverId,
          datetime: moment.utc().format(),
          messageType: data.messageType,
        };
        setMessageList((prevMessages) => [...prevMessages, newMessage]);
      }
    });
    return () => {
      SocketCall.off("support-send-message");
    };
  }, [roomId]);
  useEffect(() => {
    SocketCall.on("support-create-session", (data) => {
      if (data.isSessionCreated) {
        const updatedCustomerList = customerList?.map((customer) => {
          if (customer.roomId === data.roomId) {
            return { ...customer, status: "Open" };
          }
          return customer;
        });
        setCustomerList(updatedCustomerList);
      }
    });
    return () => {
      SocketCall.off("support-create-session");
    };
  }, [customerList]);
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Customer support"} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
        }}
      >
        <div style={{}} className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total tickets</div>
                <div className="fontSize24">{totalTicket}</div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Open tickets</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {openTicket}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Closed tickets</div>
                <div style={{ color: Colors.darkRed }} className="fontSize24">
                  {closedTicket}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">New tickets</div>
                <div style={{ color: Colors.darkBlue }} className="fontSize24">
                  {newTicket}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ paddingTop: "1rem", gap: "0.5rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: supportChatVisible ? 0.7 : 1,
            alignSelf: "stretch",
          }}
        >
          <div className="fontSize24">Ticket list</div>
          <div className="frame-parent2">
            <div className="custom-box-user">
              <div className="custom-container-dropdown-div">
                <CustomDropdown
                  dropDownLabel={dropdownSelected}
                  items={statusDropdownItem}
                  onSelect={setDropDownSelectedValue}
                  customLabelStyle={{
                    fontWeight: "400",
                    color: Colors.greyColor50,
                  }}
                />
              </div>

              <div className="custom-box-search-div">
                <img className="custom-image" alt="" src={Icons.ic_search} />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        {supportChatVisible && (
          <div
            style={{
              flex: 0.3,
              alignSelf: "stretch",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <div className="fontSize24">Support chat</div>
            <div
              style={{
                fontSize: "0.7rem",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "0.3rem",
              }}
              className="fontSize24"
            >
              <input
                type="checkbox"
                style={{ height: "1.1rem", width: "1.1rem" }}
                checked={isChecked}
                disabled={isChecked == true ? true : false}
                onChange={handleCheckboxChange}
              />{" "}
              Close this ticket
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          alignSelf: "stretch",
          flexDirection: "row",
          gap: "0.5rem",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            flex: customerList?.length == 0 ? 1 : !supportChatVisible ? 1 : 0.7,
          }}
          className="customer-container"
        >
          {renderCouponContent()}
          {renderCouponList()}
        </div>
        {supportChatVisible && customerList?.length != 0 && (
          <div
            style={{ alignSelf: "stretch", flex: 0.3 }}
            className="customer-container"
          >
            {renderSuppotChat()}
          </div>
        )}
      </div>
      {customerList?.length <= 0 && <EmptyData />}
    
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
      >
        <div className="modal-main-container">
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="fontSize24">Create new coupon code</div>
            <img
              onClick={() => setIsMenuOpen(false)}
              style={{ cursor: "pointer" }}
              src={Icons.ic_close}
              alt="ic_close"
              className="modal-ic_close"
            />
          </div>
        </div>
      </Modal>

      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isModalCloseTicket}
        onRequestClose={() => setIsModalCloseTicket(false)}
      >
        <div
          style={{
            background: Colors.mainDarkColor,
            width: "80%",
            padding: 20,
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="fontSize24">
              Are you sure you want to close this ticket?
            </div>
            <img
              src={Icons.ic_close}
              alt="ic_close"
              onClick={() => setIsModalCloseTicket(false)}
              style={{
                height: "1.3rem",
                cursor: "pointer",
                width: "1.3rem",
                objectFit: "contain",
              }}
            />
          </div>
          <div style={{ display: "flex", paddingTop: "40px", gap: "1rem" }}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "2rem",
                flex: 1,
                cursor: "pointer",
                borderRadius: "10rem",
                border: "1px solid #fff",
              }}
              onClick={() => setIsModalCloseTicket(false)}
              className="fontSize16"
            >
              No
            </div>
            <div
              onClick={() => apiCallCloseTicket()}
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "2rem",
                cursor: "pointer",
                flex: 1,
                background: "#ffff",
                borderRadius: "10rem",
                color: Colors.black,
              }}
              className="fontSize16"
            >
              Yes, close ticket
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            padding: 0,
            margin: "auto",
            maxWidth: "70%",
            width: "70%",
            border: "none",
            background: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        ariaHideApp={false}
        isOpen={isImageModalOpen}
        onRequestClose={() => {
          document.body.style.overflow = "unset";
          setIsImageModalOpen(false);
          setImageSelectedUrl(null);
        }}
      >
        {/* <div></div> */}
        <div
          style={{
            background: Colors.mainDarkColor,
            padding: 20,
            borderRadius: "10px",
            height: "80%",
            width: "70%",
          }}
        >
          <div style={{ height: "100%", width: "100%", borderRadius: "10px" }}>
            <img
              style={{
                objectFit: "contain",
                width: "100%",
                borderRadius: "10px",
                height: "100%",
              }}
              alt=""
              src={imageSelectedUrl}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Customer;
