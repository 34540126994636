import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import Loader from "../../../Components/Loader/Loader";
import Icons from "../../../Constants/Icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./auth.css";
import Colors from "../../../Constants/Colors";
import { postLogin } from "../../../Core/Apicall";
import { setProfileData } from "../../../Config/Actions/profileActions";
import { onMessageListener, requestForToken } from "../../../../firebase-init";
const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("punit@zigzek.com");
  const [newPassword, setNewPassword] = useState("abc123");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const isProfileData = useSelector((state) => state.isProfileData);
  const [isLoader, setIsLoader] = useState(true);
  const [firebaseToken, setFirebaseToken] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (isProfileData?.token) {
      navigate("/Home");
    } else {
      setIsLoader(false);
    }
  }, [isProfileData]);

  const getFCMToken = async () => {
    const token = await requestForToken();
    setFirebaseToken(token);
  };

  useEffect(() => {
    getFCMToken();
    onMessageListener();
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(setLoadingTrue());
      dispatch(setLoadingTrue());
      let param = {
        email: email,
        password: newPassword,
      };
      postLogin(param)
        .then((response) => {
          // axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.adminData.token}`;
          dispatch(setProfileData(response.adminData));
          // toast.success(response.message);
          navigate("/Home");
          onLogin();
          dispatch(setLoadingFalse());
        })
        .catch((err) => {
          toast.error(err);
          dispatch(setLoadingFalse());
        });
    }
  };
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Email validation
    if (!email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }
    if (!newPassword) {
      errors.newPassword = "Password is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <div
      style={{
        display: "flex",
        background: Colors.darkBlack,
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      {!isLoader && (
        <div
          style={{
            display: "flex",
            gap: 7,
            marginTop: "50px",
            alignItems: "center",
          }}
        >
          <img
            src={Icons.ic_zigzek_logo}
            style={{ objectFit: "contain", height: "1.6rem", width: "1.6rem" }}
          />
          <div
            style={{
              color: "#FFF",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Zigzek
          </div>
        </div>
      )}
      {!isLoader && (
        <div
          style={{
            marginTop: "20px",
            background: Colors.mainDarkColor,
            border: "1px solid rgba(255, 255, 255, 0.1)",
            borderRadius: "15px",
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              padding: "10px 20px 10px 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{ textAlign: "center", marginBottom: "10px" }}
              className="title_reset_password"
            >
              Login
            </div>
            <div className="title_password_main">Email</div>
            <div
              style={{
                border: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "30px",
                width: "45vh",
                display: "flex",
                padding: "6px 15px",
              }}
            >
              <input
                type={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="searchTextInput"
                placeholder="Enter email"
                style={{
                  width: "100%", // Ensure the input fills the parent div
                }}
              />
            </div>
            {errors.email && (
              <div style={{ fontSize: "0.77rem", color: "red" }}>
                {errors.email}
              </div>
            )}
            <div className="title_password_main">Enter password</div>
            <div
              style={{
                border: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "30px",
                width: "45vh",
                display: "flex",
                padding: "6px 15px",
              }}
            >
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="searchTextInput"
                placeholder="Enter password"
                style={{
                  width: "100%", // Ensure the input fills the parent div
                }}
              />
              <img
                onClick={() => setShowNewPassword(!showNewPassword)}
                src={
                  showNewPassword ? Icons.show_password : Icons.hide_password
                }
                style={{ cursor: "pointer", height: "1rem", width: "1rem" }}
              />
            </div>
            {errors.password && (
              <div style={{ fontSize: "0.77rem", color: "red" }}>
                {errors.password}
              </div>
            )}
            <div
              onClick={() => navigate("ForgotPassword")}
              style={{
                marginTop: "0.5rem",
                color: "#2E90FA",
                fontSize: "0.7rem",
                cursor: "pointer",
                textAlign: "end",
              }}
              className="fontSize16"
            >
              Forgot Password?
            </div>
            <div
              style={{
                marginTop: "20px",
              }}
              onClick={handleLogin}
              className="reset_password_on_click"
            >
              Login
            </div>
          </div>
          <ToastContainer />
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Login;
