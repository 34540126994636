import { useEffect, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import "./SendNotification.css";
import Header from "../../../Components/Header/Header";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import CreateNotifiations from "./CreateNotifiations";
import ScheduleNotifications from "./ScheduleNotifications";
import HistoryNotifications from "./HistoryNotifications";
const SendNotification = () => {
  const dispatch = useDispatch();

  const tabs = ["Create notification", "Schedule notification", "History"];
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setSelectNotification();
    setActiveTab(index);
  };
  const [selectNotificationData, setSelectNotification] = useState();

  const onClickEdit = (item) => {
    setSelectNotification(item);
    setActiveTab(0);
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Send Notifications"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            gap: "1rem",
            alignSelf: "stretch",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{
                padding: "10px",
                fontWeight: "400",
                color:
                  index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
                borderBottom: index != activeTab ? "" : "1px solid #fff",
                cursor: "pointer",
              }}
              className="fontSize18"
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </div>
          ))}
        </div>
        {activeTab === 0 && (
          <CreateNotifiations
            setSelectedDataClear={() => setSelectNotification()}
            selectNotificationData={selectNotificationData}
          />
        )}
        {activeTab === 1 && <ScheduleNotifications onClickEdit={onClickEdit} />}
        {activeTab === 2 && <HistoryNotifications />}
      </div>
    </div>
  );
};

export default SendNotification;
