import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Icons from "../../Constants/Icons";

const CustomPagination = ({
  pageCount,
  pageRangeDisplayed,
  marginPagesDisplayed,
  onPageChange,
  initialPage,
}) => {
  return (
    <ReactPaginate
      forcePage={initialPage}
      // initialPage={initialPage}
      pageCount={pageCount}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginPagesDisplayed}
      onPageChange={onPageChange}
      containerClassName={"pagination"}
      previousLinkClassName={"pagination__link"}
      nextLinkClassName={"pagination__link"}
      activeClassName={"pagination__link--active"}
      previousLabel={
        <img
          src={Icons.ic_white_left_arrow}
          alt="Next"
          style={{
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
            height: "1.3rem",
            width: "1.3rem",
            objectFit: "contain",
          }}
        />
      }
      nextLabel={
        <img
          src={Icons.ic_white_right_arrow}
          alt="Next"
          style={{
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
            height: "1.3rem",
            width: "1.3rem",
            objectFit: "contain",
          }}
        />
      }
    />
  );
};

export default CustomPagination;
