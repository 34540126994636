import React from "react";
import Icons from "../../../../../Constants/Icons";
import Colors from "../../../../../Constants/Colors";
import { useDispatch } from "react-redux";
import CopyToClipboard from "../../../../../Components/CopyToClipboard";
function Newregistration({
  data,
  onAccept,
  onReject,
  amount,
  action,
  handleScroll,
  commentsContainerRef,
}) {
 

  const handleReject = (item) => {
    onReject(item);
  };

  const handleAccept = (item) => {
    onAccept(item);
  };

  return (
    <div
      onScroll={handleScroll}
      style={{ maxHeight: "500px" }}
      ref={commentsContainerRef}
      className="scrollable-container"
    >
      {data.map((item, index) => {
        return (
          <div
            style={{
              padding: "1rem",
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                gap: "0.5rem",
                alignSelf: "stretch",
              }}
            >
              <div
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                  alignSelf: "stretch",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    fontFamily: "Poppins-Regular",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    color: "#fff",
                    position: "relative",
                  }}
                  className="fontSize18Weight400"
                >
                  {item.astrologerId}
                </div>
                <CopyToClipboard  text={""}>
                <img
                  style={{ cursor: "pointer" }}
                  className="icon_copy"
                  alt=""
                  src={Icons.ic_copy}
                />
                </CopyToClipboard>
              </div>
              <div
                style={{
                  width: "14%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <div style={{}} className="indian-man-smiling-astrologer">
                  <img
                    style={{
                      position: "relative",
                      width: "2.63rem",
                      height: "2.63rem",
                    }}
                    alt=""
                    src={Icons.ic_default_user}
                  />
                </div>
                <div className="fontSize18Weight400">{item.astrologerName}</div>
              </div>
              <div
                style={{
                  width: "18%",
                  fontSize: "0.88rem",
                  fontFamily: "Poppins-Regular",
                  color: Colors.white,
                  position: "relative",
                }}
                className="fontSize18Weight400"
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    gap: "0.4rem",
                  }}
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "contain" }}
                    src={Icons.ic_sms}
                  />
                  {item.email}
                </div>
                <div
                  style={{
                    paddingTop: "0.5rem",
                    alignItems: "center",
                    display: "flex",
                    gap: "0.4rem",
                  }}
                  className="fontSize18Weight400"
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "contain" }}
                    src={Icons.ic_call}
                  />
                  {item.contact}
                </div>
              </div>
              <div
                style={{
                  width: "9%",
                }}
                className="fontSize18Weight400"
              >
                {item.experience}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.25rem",
                  fontSize: "0.75rem",
                  fontFamily: "Poppins-Regular",
                  width: "20%",
                  flexWrap: "wrap",
                }}
              >
                {item.expertises.map((item) => {
                  return (
                    <div
                      style={{
                        borderRadius: "46px",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        textAlign: "center",
                        padding: "5px 12px",
                      }}
                      className="my-account"
                    >
                      {item.expertise}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  width: "7%",
                }}
                className="fontSize18Weight400"
              >
                {item.gender}
              </div>
              <div
                style={{
                  width: "8%",
                  // border:"1px solid red",
                  // background: "none",
                  // border: "none",
                }}
              >
                {/* {item.action !== null ? (
                  item.action ? (
                    <button
                      style={{
                        color: "Green",
                        background: "none",
                        border: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <img src={Icons.ic_linky} style={{ width: "20px" }} />
                      Approve
                    </button>
                  ) : (
                    <button
                      style={{
                        color: "red",
                        background: "none",
                        border: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <img src={Icons.ic_linkx} style={{ width: "20px" }} />
                      Reject
                    </button>
                  )
                ) : ( */}
                <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  <button
                    onClick={() => handleAccept(item)}
                    style={{
                      color: "green",
                      background: "none",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={Icons.ic_linky} style={{ width: "20px" }} />
                    Approve
                  </button>
                  <button
                    onClick={() => handleReject(item)}
                    // className="bg-red-500 text-white px-2 py-1 rounded"
                    style={{
                      color: "red",
                      background: "none",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={Icons.ic_linkx} style={{ width: "20px" }} />
                    Reject
                  </button>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Newregistration;
