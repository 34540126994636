import React, { useCallback, useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import Rating from "react-rating";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Icons from "../../../Constants/Icons";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { useDispatch } from "react-redux";
import { ApiProductGetProductReviews } from "../../../Core/Apicall";
import moment from "moment";

function RatingScreen({productId}) {
  const [rating, setRating] = useState(2.5);
  const [progress, setProgress] = useState(50);
  const [reviewShow, sendReviewShow] = useState(false);
  const [reviewDataSelect, setReviewDataSelect] = useState(false);
  const [ratingReview, setRatingReview] = useState([]);
  const [selectItemBackgroundColor, setSelectBackgroundChange] = useState(null);
  const dispatch = useDispatch();
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const [averageReview, setAverageReview] = useState(0);
  const [totalPaginationCount, setTotalPaginationCount] = useState(0);
  const [totalReview, setTotalReview] = useState(0);
  const [totalOneStarRatingCount, setTotalOneStarRatingCount] = useState(0);
  const [totalTwoStarRatingCount, setTotalTwoStarRatingCount] = useState(0);
  const [totalThreeStarRatingCount, setTotalThreeStarRatingCount] = useState(0);
  const [totalFourStarRatingCount, setTotalFourStarRatingCount] = useState(0);
  const [totalFiveStarRatingCount, setTotalFiveStarRatingCount] = useState(0);

  useEffect(() => {
    onApiCallProductReview();
  }, [pageComments]);

  const onApiCallProductReview = () => {
    let param = {
      offset: 30 * pageComments,
      limit: 30,
    };
    let data = {
      productId:productId
    }
    dispatch(setLoadingTrue());
    ApiProductGetProductReviews(param,data)
      .then((response) => {
        setTotalPaginationCount(Math.ceil(response.totalReviewCount / 30))
        setAverageReview(response?.averageRating)
        setTotalReview(response?.totalReviewCount)
        setTotalOneStarRatingCount(response?.totalOneStarRatingCount)
        setTotalTwoStarRatingCount(response?.totalTwoStarRatingCount)
        setTotalThreeStarRatingCount(response?.totalThreeStarRatingCount)
        setTotalFourStarRatingCount(response?.totalFourStarRatingCount)
        setTotalFiveStarRatingCount(response?.totalFiveStarRatingCount)
        setRatingReview((prevData) => [
          ...prevData,
          ...response?.reviews,
        ])
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleSelect = (status) => {
    console.log("status", status);
  };

  const onClickReviewItem = (item) => {
    dispatch(setLoadingTrue());
    setSelectBackgroundChange(item.reviewId);
    setReviewDataSelect(item);
    sendReviewShow(true);
    dispatch(setLoadingFalse());
  };



  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalPaginationCount > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };


  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <div style={{ display: "flex", gap: "1rem" }}>
        <div style={{ flex: 3, alignSelf: "stretch" }}>
          <div style={{ marginBottom: "1rem" }} className="Main-Title-Product">
            Overall ratings
          </div>
          <div
            style={{
              background: "#25252D",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                marginTop: "1rem",
                marginBottom: "0.3rem",
                fontSize: "2rem",
              }}
              className="Main-Title-Product"
            >
              {averageReview}
            </div>
            <div style={{}}>
              <Rating
                readonly={true}
                initialRating={rating}
                emptySymbol={
                  <span style={{ padding: 5 }}>
                    <FontAwesomeIcon
                      icon={regularStar}
                      style={{ color: Colors.orangeRating }}
                    />
                  </span>
                }
                fullSymbol={
                  <span style={{ padding: 5 }}>
                    <FontAwesomeIcon
                      icon={solidStar}
                      style={{ color: Colors.orangeRating }}
                    />
                  </span>
                }
              />
            </div>
            <div style={{ marginTop: "0.5rem" }} className="Main-Title-Product">
              based on {totalReview} reviews
            </div>
            <div className="div-justify-content-set">
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
                5 Star
                <FontAwesomeIcon
                  icon={solidStar}
                  style={{ color: Colors.orangeRating, paddingLeft: "5px" }}
                />
              </div>

              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width:`${(totalFiveStarRatingCount / totalReview) * 100}%`}}
                ></div>
              </div>
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
                {totalFiveStarRatingCount}
              </div>
            </div>
            <div className="div-justify-content-set">
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
                4 Star
                <FontAwesomeIcon
                  icon={solidStar}
                  style={{ color: Colors.orangeRating, paddingLeft: "5px" }}
                />
              </div>

              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width:`${(totalFourStarRatingCount / totalReview) * 100}%`}}

                ></div>
              </div>
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
                {totalFourStarRatingCount}
              </div>
            </div>
            <div className="div-justify-content-set">
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
                3 Star
                <FontAwesomeIcon
                  icon={solidStar}
                  style={{ color: Colors.orangeRating, paddingLeft: "5px" }}
                />
              </div>

              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width:`${(totalThreeStarRatingCount / totalReview) * 100}%`}}

                ></div>
              </div>
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
              {totalThreeStarRatingCount}
              </div>
            </div>
            <div className="div-justify-content-set">
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
                2 Star
                <FontAwesomeIcon
                  icon={solidStar}
                  style={{ color: Colors.orangeRating, paddingLeft: "5px" }}
                />
              </div>

              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width:`${(totalTwoStarRatingCount / totalReview) * 100}%`}}
                ></div>
              </div>
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
               {totalTwoStarRatingCount}
              </div>
            </div>
            <div className="div-justify-content-set">
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
                1 Star
                <FontAwesomeIcon
                  icon={solidStar}
                  style={{ color: Colors.orangeRating, paddingLeft: "5px" }}
                />
              </div>

              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width:`${(totalOneStarRatingCount / totalReview) * 100}%`}}
                ></div>
              </div>
              <div
                style={{ fontSize: "0.8rem", fontWeight: 600 }}
                className="Main-Title-Product"
              >
               {totalOneStarRatingCount}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 4.5, alignSelf: "stretch" }}>
          <div
            style={{
              display: "flex",
              marginBottom: "1rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{}} className="Main-Title-Product">
              Reviews
            </div>
            <div
              style={{
                // borderRadius: "8px",
                // border: Colors.darkBorderColor,
                // width: "7rem",
                // display: "flex",
                // zIndex: 1,
                // padding: "0.3rem 0.3rem",
              }}
            >
              {/* <CustomDropdown
                dropDownLabel={"sort by"}
                items={["Hide"]}
                color="blue"
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: "400",
                  color: Colors.greyColor50,
                }}
              /> */}
            </div>
          </div>

          <div
            style={{
              borderRadius: "13px",
              border: "1px solid #FFFFFF1A",
            }}
          >
            <div
              style={{
                marginTop: "1rem",
                flex: "1",
                height: "30rem",
                gap: "0.5rem",
                overflowY: "scroll",
                maxHeight: "30rem",
              }}
              ref={commentsContainerRef}
              onScroll={handleScroll}
              className="scrollable-container"
            >
              {ratingReview?.map((review, index) => {
                return (
                  <div
                  key={index}
                    style={{
                      borderTop: index != 0 ? "1px solid #FFFFFF1A" : "",
                      padding:
                        selectItemBackgroundColor == review._id
                          ? "10px"
                          : "0px",
                    }}
                  >
                    <div
                      style={{
                        // cursor: "pointer",
                        background:
                          selectItemBackgroundColor == review._id
                            ? "#25252D"
                            : "transparent",
                        borderRadius: "7px",
                        padding: "10px",
                      }}
                      // onClick={() => onClickReviewItem(review)}
                    >
                      <div style={{}}>
                        {review?.hideReview && (
                          <div
                            style={{
                              fontSize: "0.66rem",
                              color: "#B1B1B1",
                              marginBottom: "7px",
                            }}
                            className="fontSize18Weight400"
                          >
                            This review is hide
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <div
                            style={{}}
                            className="indian-man-smiling-astrologer"
                          >
                            <img
                              style={{
                                position: "relative",
                                width: "2.63rem",
                                height: "2.63rem",
                              }}
                              alt=""
                              src={review.userImage??Icons.ic_default_user}
                            />
                          </div>
                          <div style={{}}>
                            <div className="fontSize18Weight400">
                              {review.userName}
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{}}>
                                <Rating
                                  readonly={true}
                                  initialRating={review.rating}
                                  emptySymbol={
                                    <span style={{ padding: 2 }}>
                                      <FontAwesomeIcon
                                        icon={regularStar}
                                        style={{ color: Colors.orangeRating }}
                                      />
                                    </span>
                                  }
                                  fullSymbol={
                                    <span style={{ padding: 2 }}>
                                      <FontAwesomeIcon
                                        icon={solidStar}
                                        style={{ color: Colors.orangeRating }}
                                      />
                                    </span>
                                  }
                                />
                              </div>
                              <div
                                style={{ marginLeft: "10px" }}
                                className="fontSize18Weight400"
                              >
                                {review.rating}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            fontSize: "0.66rem",
                            textAlign: "justify",
                            marginTop: "7px",
                            color: review?.hideReview ? "#B1B1B1" : "#FFF",
                          }}
                          className="fontSize18Weight400"
                        >
                          {review.review}
                        </div>
                        <div
                          style={{
                            fontSize: "0.66rem",
                            textAlign: "justify",
                            color: "#B1B1B1",
                            marginTop: "7px",
                          }}
                          className="fontSize18Weight400"
                        >
                          {moment(review.reviewDate).format("YYYY-MM-DD")} {", "}
                          {moment(review.reviewDate).format("hh:mm:ss a")}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {reviewShow && (
          <div
            style={{
              borderRadius: "13px",
              border: "1px solid #FFFFFF1A",
              flex: 3.5,
            }}
          >
            <div
              style={{
                padding: "13px",
                display: "flex",
                alignItems: "center",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              <div style={{}} className="Main-Title-Product">
                Replay review
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <div
                  style={{
                    cursor: "pointer",
                    border: "1px solid #FFFFFF1A",
                  }}
                  className="hide-delete-container"
                >
                  Hide review
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    border: "1px solid  #E44145",
                    color: "#E44145",
                  }}
                  className="hide-delete-container"
                >
                  Delete
                </div>
              </div>
            </div>
            <div style={{ border: "0.5px solid #FFFFFF1A" }}></div>
            <div
              style={{
                flex: "1",
                height: "26rem",
                gap: "0.5rem",
                overflowY: "scroll",
                maxHeight: "26rem",
              }}
              className="scrollable-container"
            >
              <div
                style={{
                  background: "transparent",
                  borderRadius: "7px",
                  padding: "10px 10px 10px 10px",
                }}
              >
                <div style={{}}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <div style={{}} className="indian-man-smiling-astrologer">
                      <img
                        style={{
                          position: "relative",
                          width: "2.63rem",
                          height: "2.63rem",
                        }}
                        alt=""
                        src={Icons.ic_default_user}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignSelf: "stretch",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div className="fontSize18Weight400">
                          {reviewDataSelect.name}
                        </div>
                        <div
                          style={{
                            fontSize: "0.66rem",
                            color: "#B1B1B1",
                          }}
                          className="fontSize18Weight400"
                        >
                          {reviewDataSelect.date}
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{}}>
                          <Rating
                            readonly={true}
                            initialRating={rating}
                            emptySymbol={
                              <span style={{ padding: 2 }}>
                                <FontAwesomeIcon
                                  icon={regularStar}
                                  style={{ color: Colors.orangeRating }}
                                />
                              </span>
                            }
                            fullSymbol={
                              <span style={{ padding: 2 }}>
                                <FontAwesomeIcon
                                  icon={solidStar}
                                  style={{ color: Colors.orangeRating }}
                                />
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ marginLeft: "10px" }}
                          className="fontSize18Weight400"
                        >
                          {reviewDataSelect.rating}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "0.66rem",
                      textAlign: "justify",
                      marginTop: "7px",
                    }}
                    className="fontSize18Weight400"
                  >
                    {reviewDataSelect.reviewText}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "center",
                padding: "15px",
              }}
            >
              <input
                className="modal-main-input"
                type="text"
                placeholder="Message"
                style={{
                  color: Colors.white,
                  flex: 1,
                  background: "#2D2D33",
                }}
                //   value={message}
                //   onChange={(event) => setMessage(event.target.value)}
                //   onKeyPress={(event) => {
                //     if (event.key === "Enter" && message !== "") {
                //       onClickSendMessage();
                //     }
                //   }}
              />
              <img
                style={{
                  position: "relative",
                  width: "2.3rem",
                  height: "2.3rem",
                  // cursor: message == "" ? "not-allowed" : "pointer",
                }}
                alt=""
                src={Icons.ic_send_message}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RatingScreen;
