import React from "react";
import Icons from "../../../../../Constants/Icons";
import { useNavigate } from "react-router-dom";
import Colors from "../../../../../Constants/Colors";
import StatusButton from "../../../../../Components/StatusButton/StatusButton";
import { useState } from "react";
import CopyToClipboard from "../../../../../Components/CopyToClipboard";

function Approvedlist({
  data,
  onMoveToOnboard,
  onEditAstrologer,
  handleScroll,
  commentsContainerRef,
}) {
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = (index) => {
    if (index === openMenuIndex) {
      setOpenMenuIndex(-1);
    } else {
      setOpenMenuIndex(index);
    }
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
    setOpenMenuIndex(-1);
  };

  const onClickPopUpEdit = (item) => {
    onEditAstrologer(item);
    closeMenu();
  };

  const onClickPopUp = (item) => {
    onMoveToOnboard(item);
    closeMenu();
  };
  return (
    <div
      onScroll={handleScroll}
      style={{ maxHeight: "500px" }}
      ref={commentsContainerRef}
      className="scrollable-container"
    >
      {data.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              padding: "1rem",
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                gap: "0.5rem",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                  alignSelf: "stretch",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    fontFamily: "Poppins-Regular",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    color: "#fff",
                    position: "relative",
                    wordBreak: "break-word",
                  }}
                  className="fontSize18Weight400"
                >
                  {item.astrologerId}
                </div>
                <CopyToClipboard text={item.astrologerId}>
                  <img
                    style={{ cursor: "pointer" }}
                    className="icon_copy"
                    alt=""
                    src={Icons.ic_copy}
                  />
                </CopyToClipboard>
              </div>
              <div
                style={{
                  width: "16%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <div style={{}} className="indian-man-smiling-astrologer">
                  <img
                    style={{
                      position: "relative",
                      width: "2.63rem",
                      height: "2.63rem",
                    }}
                    alt=""
                    src={item.astrologerProfile ?? Icons.ic_default_user}
                  />
                </div>
                <div className="fontSize18Weight400">
                  {item.astrologerName}
                  <br />
                  <span
                    style={{
                      color: "green",
                    }}
                  >
                    {item.Login}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "18%",
                  fontSize: "0.88rem",
                  fontFamily: "Poppins-Regular",
                  color: Colors.white,
                  position: "relative",
                }}
                className="fontSize18Weight400"
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    gap: "0.4rem",
                  }}
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "contain" }}
                    src={Icons.ic_sms}
                  />
                  {item.email}
                </div>
                <div
                  style={{
                    paddingTop: "0.5rem",
                    alignItems: "center",
                    display: "flex",
                    gap: "0.4rem",
                  }}
                  className="fontSize18Weight400"
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "contain" }}
                    src={Icons.ic_call}
                  />
                  {item.contact}
                </div>
              </div>
              <div
                style={{
                  width: "8%",
                }}
                className="fontSize18Weight400"
              >
                {item.experience}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.25rem",
                  fontSize: "0.75rem",
                  fontFamily: "Poppins-Regular",
                  width: "21%",
                  flexWrap: "wrap",
                }}
              >
                {item.expertises.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        borderRadius: "46px",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        textAlign: "center",
                        padding: "5px 12px",
                      }}
                      className="my-account"
                    >
                      {item.expertise}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  width: "8%",
                }}
                className="fontSize18Weight400"
              >
                {item.gender}
              </div>

              <StatusButton
                style={{
                  width: "12%",
                  backgroundColor: item.isDocumentSubmitted 
                  ? Colors.mainGreen01 :  Colors.mainOrange01,
                  borderColor: item.isDocumentSubmitted 
                  ? Colors.darkGreen : Colors.darkOrange,
                  color:  item.isDocumentSubmitted
                  ? Colors.darkGreen : Colors.darkOrange,
                }}
              >
                {item?.isDocumentSubmitted ? "Document Submitted" : "Document Pending"}
              </StatusButton>

              <div
                onClick={() => toggleMenu(index)}
                style={{ cursor: "pointer", width: "2%", display: "flex" }}
              >
                <img
                  style={{ width: "1.2rem", objectFit: "cover" }}
                  src={Icons.ic_more}
                />
              </div>
              <div
                style={{
                  marginBottom: index === data.length - 1 ? "80px" : "auto",
                  right: "2rem",
                  top: index == 0 ? "0px" : "auto",
                }}
                className="custom-menu-popup-container"
              >
                {openMenuIndex === index && (
                  <div
                    style={{ width: "auto" }}
                    onMouseLeave={closeMenu}
                    className="custom-menu-popup"
                  >
                    <div
                      onClick={() => onClickPopUpEdit(item)}
                      className="custom-menu-list"
                    >
                      Edit details
                    </div>
                    <div
                      onClick={() => onClickPopUp(item)}
                      className="custom-menu-list"
                    >
                      Move to onboard
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Approvedlist;
