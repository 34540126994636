import React, { useState } from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import StatusButton from "../StatusButton/StatusButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CopyToClipboard from "../CopyToClipboard";

function SessionListRender({ session }) {
  const navigate = useNavigate();
 
  return (
    <div
      onClick={() =>
        navigate("/ScheduledSessionInfo", { state: { sessionData: session } })
      }
      style={{
        cursor: "pointer",
        alignSelf: "stretch",
        borderBottom: Colors.darkBorderColor,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem",
        gap: "0.5rem",
      }}
    >
      <div
        style={{
          width: "11%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "0.5rem",
          alignSelf: "stretch",
          wordBreak: "break-word",
        }}
      >
        <div
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "0.8rem",
            fontWeight: 400,
            color: "#fff",
            position: "relative",
          }}
        >
          {session?._id}
        </div>
        <CopyToClipboard  text={session?._id}>
        <img
          style={{ cursor: "pointer", }}
          className="icon_copy"
          alt=""
          src={Icons.ic_copy}
        />
        </CopyToClipboard>
      </div>
      <div
        style={{
          width: "13%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <div style={{}} className="indian-man-smiling-astrologer">
          <img
            style={{
              position: "relative",
              width: "2.63rem",
              height: "2.63rem",
            }}
            alt=""
            src={session?.userImage ?? Icons.ic_default_user}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              position: "relative",

              fontSize: "0.8rem",
              fontWeight: 400,
              fontFamily: "Poppins-Regular",
              color: Colors.white,
            }}
          >
            {session?.userName}
          </div>
          {/* <div
            style={{
              position: "relative",
              fontSize: "0.8rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "0.5rem",
              color: Colors.greyColor50,
            }}
          >
            {transactionId}
          </div> */}
        </div>
      </div>
      <div
        style={{
          width: "13%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <div style={{}} className="indian-man-smiling-astrologer">
          <img
            style={{
              position: "relative",
              width: "2.63rem",
              height: "2.63rem",
            }}
            alt=""
            src={session?.astrologerImage ?? Icons.ic_default_user}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              position: "relative",
              fontFamily: "Poppins-Regular",
              fontSize: "0.8rem",
              fontWeight: 400,
              color: Colors.white,
            }}
          >
            {session?.astrologerName}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "9%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.5rem",
            fontSize: "0.8rem",
            color: Colors.white,
            position: "relative",
            fontWeight: 400,
            fontFamily: "Poppins-Regular",
          }}
        >
          {session?.totalAstrologerCharge}
        </div>
      </div>

      <div
        style={{
          width: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.5rem",
            fontSize: "0.8rem",
            color: Colors.white,
            position: "relative",
            fontWeight: 400,
            fontFamily: "Poppins-Regular",
          }}
        >
          {moment(session.date_and_time).format("YYYY-MM-DD")} <br />
          {moment(session.date_and_time).format("hh:mm:ss a")}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // gap: "0.25rem",
          fontSize: "0.75rem",
          width: "13%",
        }}
      >
        <div
          style={{
            borderRadius: "46px",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            boxSizing: "border-box",
            height: "1.75rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "0.38rem 1rem",
          }}
        >
          <div
            style={{
              color: Colors.white,
              position: "relative",
              fontWeight: 400,
              fontFamily: "Poppins-Regular",
            }}
          >
            {session?.sessionType}
          </div>
        </div>
      </div>
      <div
        style={{
          fontSize: "0.8rem",
          width: "7%",
          fontWeight: 400,
          fontFamily: "Poppins-Regular",
          color: Colors.white,
        }}
      >
        {session?.duration.split(" ")[0]} mins
      </div>
      <div
        style={{
          position: "relative",
          fontSize: "0.8rem",
          width: "7%",
          fontWeight: 400,
          fontFamily: "Poppins-Regular",
          color: Colors.white,
        }}
      >
        ₹{session?.pricePerMinute}/mins
      </div>
      <div
        style={{
          position: "relative",
          fontSize: "0.8rem",
          width: "10%",
          fontWeight: 400,
          fontFamily: "Poppins-Regular",
          color: Colors.white,
          display: "flex",
          alignItems: "center",
        }}
      >
        ₹{session?.totalAmount}
        <div
          style={{
            fontWeight: "400",
            fontFamily: "Poppins-Regular",
            position: "relative",
            fontSize: "0.6rem",
            marginLeft: "3px",
            // color: Colors.darkGreen,
            color:
              session?.isPaid == true ? Colors.darkGreen : Colors.darkOrange,
            // feesType == "Counting..."
            //   ? Colors.darkGreen
            //   : feesType == "Paid"
            //   ? Colors.darkGreen
            //   : Colors.darkOrange,
          }}
        >
          {session?.isPaid ? "Paid" : session?.isRefund ? "Refund" : ""}
        </div>
      </div>
      <StatusButton
        style={{
          width: "7%",
          backgroundColor:
            session?.sessionStatus === "completed"
              ? Colors.mainGreen01
              : session?.sessionStatus === "cancel" ||
                session?.sessionStatus == "declined"
              ? Colors.mainRed01
              : session?.sessionStatus === "scheduled"
              ? Colors.mainBlue01
              : session?.sessionStatus === "missed"
              ? Colors.lightGrey01
              : Colors.mainOrange01,
          borderColor:
            session?.sessionStatus === "completed"
              ? Colors.darkGreen
              : session?.sessionStatus === "cancel" ||
                session?.sessionStatus == "declined"
              ? Colors.darkRed
              : session?.sessionStatus === "scheduled"
              ? Colors.darkBlue
              : session?.sessionStatus === "missed"
              ? Colors.darkGrey
              : Colors.darkOrange,
          color:
            session?.sessionStatus === "completed"
              ? Colors.darkGreen
              : session?.sessionStatus === "cancel" ||
                session?.sessionStatus == "declined"
              ? Colors.darkRed
              : session?.sessionStatus === "scheduled"
              ? Colors.darkBlue
              : session?.sessionStatus === "missed"
              ? Colors.darkGrey
              : Colors.darkOrange,
        }}
      >
        {session?.sessionStatus}
      </StatusButton>
    </div>
  );
}

export default SessionListRender;
