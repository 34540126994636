import React from "react";

const StatusButton = ({ style, children }) => {
  const defaultStyles = {
    borderRadius: "46px",
    backgroundColor: "rgba(18, 183, 106, 0.1)",
    border: "1px solid #12b76a",
    boxSizing: "border-box",
    width: "9%",
    height: "1.75rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.65rem",
    color: "#12b76a",
  };

  const mergedStyles = { ...defaultStyles, ...style };

  return (
    <div style={mergedStyles}>
      <div style={{ position: "relative", fontWeight: "600" }}>{children}</div>
    </div>
  );
};

export default StatusButton;
