import { useEffect, useRef, useState } from "react";
import "./../../../Constants/CommonStyle.css";
import "./SendNotification.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  getApiCallScheduleNotificationList,
  apiCallDeleteNotification,
} from "../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import moment from "moment";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import Icons from "../../../Constants/Icons";
import Colors from "../../../Constants/Colors";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    padding: 0,
    margin: "auto",
    maxWidth: "400px",
    width: "100%",
    border: "none",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const ScheduleNotifications = ({ onClickEdit }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [scheduleNotificationList, setScheduleNotificationList] = useState([]);
  const [totalPaginationOrder, setTotalPaginationOrder] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteItemData, setDeleteIemData] = useState();
  const [initialPage, setInitialPage] = useState(0);

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);


  useEffect(() => {
    onGetScheduleNotification();
  }, [pageComments]);

  const onGetScheduleNotification = async (selectedPage) => {
    let param = {
      offset: 30 * pageComments,
      limit: 30,
    };
    try {
      dispatch(setLoadingTrue());
      const response = await getApiCallScheduleNotificationList(param);
      setInitialPage(selectedPage?.selected || 0);
      setScheduleNotificationList((prevData) => [...prevData, ...response?.data]);
      setTotalPaginationOrder(Math.ceil(response.totalNotificationCount / 30));
      dispatch(setLoadingFalse());
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const onCLickDelete = async () => {
    let param = {
      notificationId: deleteItemData._id,
    };
    try {
      dispatch(setLoadingTrue());
      const response = await apiCallDeleteNotification(param);
    
      setScheduleNotificationList((prevData) =>
        prevData.filter((item) => item._id !== deleteItemData._id)
      );

      dispatch(setLoadingFalse());
    } catch (err) {
      dispatch(setLoadingFalse());
    }

    setIsModalOpen(false);
    setDeleteIemData();
  };

  const onClickDeleteALertModalOpen = (item) => {
    setDeleteIemData(item);
    setIsModalOpen(true);
  };

  const renderCouponContent = () => {
    return (
      <div className="list-user-div-parent-container-coupon">
        <div
          style={{
            width: "55%",
          }}
          className="fontSize16WithoutFlex"
        >
          Notification information
        </div>
        <div
          style={{
            width: "30%",
          }}
          className="fontSize16WithoutFlex"
        >
          Date & Time
        </div>
        <div
          style={{
            width: "15%",
          }}
          className="fontSize16WithoutFlex"
        >
          Action
        </div>
      </div>
    );
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalPaginationOrder > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };


  const renderCouponList = () => {
    return (
      <div    ref={commentsContainerRef}
        onScroll={handleScroll} style={{ maxHeight: "500px" }} className="scrollable-container">
        {scheduleNotificationList.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "stretch",
                  padding: "1rem",
                  boxSizing: "border-box",
                  gap: "0.5rem",
                }}
              >
                <div
                  style={{
                    width: "55%",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 5 }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "0.8rem",
                        fontWeight: 400,
                        color: "#fff",
                      }}
                    >
                      {item?.title}
                    </div>
                    {/* <img
                      style={{
                        position: "relative",
                        width: "1.13rem",
                        height: "1.13rem",
                        overflow: "hidden",
                        flexShrink: "0",
                      }}
                      alt=""
                      src={Icons.ic_link}
                    /> */}
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "0.67rem",
                      fontWeight: 400,
                      color: "#FFFFFF80",
                    }}
                  >
                    {item?.content}
                  </div>
                </div>
                <div
                  style={{
                    width: "30%",
                  }}
                  className="itemTextDisplay"
                >
                  {moment(item?.date_and_time).format("DD-MM-YYYY, hh:mm a")}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "15%",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "46px",
                      border: "1px solid #FFFFFF1A",
                      boxSizing: "border-box",
                      width: "30%",
                      height: "1.75rem",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "0.65rem",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => onClickEdit(item)}
                  >
                    <div style={{ position: "relative", fontWeight: "600" }}>
                      {"Edit"}
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "46px",

                      border: "1px solid #F04438",
                      boxSizing: "border-box",
                      width: "60%",
                      height: "1.75rem",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "0.65rem",
                      color: "#F04438",
                      cursor: "pointer",
                    }}
                    onClick={() => onClickDeleteALertModalOpen(item)}
                  >
                    <div style={{ position: "relative", fontWeight: "600" }}>
                      {"Delete"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="borderTopStyle" />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={{ alignSelf: "stretch" }} className="">
      <div style={{ alignSelf: "stretch" }} className="list-user-div-container">
        {renderCouponContent()}
        {renderCouponList()}
      </div>
      {scheduleNotificationList?.length == 0 && <EmptyData />}
      {/* {scheduleNotificationList.length != 0 && (
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            pageCount={totalPaginationOrder}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={onGetScheduleNotification}
          />
        </div>
      )} */}

      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => {
          setDeleteIemData();
          setIsModalOpen(false);
        }}
        // re
      >
        <div
          style={{
            background: Colors.mainDarkColor,
            width: "100%",
            padding: 20,
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="fontSize24">
              Are you sure you want to delete this notification?
            </div>
            <img
              src={Icons.ic_close}
              onClick={() => setIsModalOpen(false)}
              alt="ic_close"
              style={{
                height: "1.3rem",
                cursor: "pointer",
                width: "1.3rem",
                objectFit: "contain",
              }}
            />
          </div>
          <div style={{ display: "flex", paddingTop: "40px", gap: "1rem" }}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "2rem",
                flex: 1,
                cursor: "pointer",
                borderRadius: "10rem",
                border: "1px solid #fff",
              }}
              onClick={() => {
                setDeleteIemData();
                setIsModalOpen(false);
              }}
              className="fontSize16"
            >
              No
            </div>
            <div
              onClick={() => onCLickDelete()}
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "2rem",
                cursor: "pointer",
                flex: 1,
                background: "#ffff",
                borderRadius: "10rem",
                color: Colors.black,
              }}
              className="fontSize16"
            >
              Yes
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleNotifications;
