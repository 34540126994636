import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Icons from "../../../Constants/Icons";
import "./MyAccount.css";
import Header from "../../../Components/Header/Header";
import Colors from "../../../Constants/Colors";
import Modal from "react-modal";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import Loader from "../../../Components/Loader/Loader";
import { persistor } from "../../../Config/store";
import { setProfileData } from "../../../Config/Actions/profileActions";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent background
  },
  content: {
    padding: 0, // Remove padding
    margin: "auto", // Center horizontally
    maxWidth: "400px", // Optional: set a maximum width for the modal
    width: "100%", // Optional: make the modal responsive
    border: "none", // Remove border (if any)
    background: "none", // Transparent background
    display: "flex", // Use flexbox for vertical centering
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
  },
};

const devicesList = [
  {
    id: 1,
    couponCode: "EXTRA50",
    date: "19-07-2023, 09:34 AM",
    transactionType: "Wallet recharge",
    transactionFromTo: "From: 19-07-2023, 09:30 AM To: 19-07-2023, 09:30 AM",
    description: "Get extra 50%",
    amount: 100,
    status: "Active",
  },
  {
    id: 2,
    couponCode: "SAVE20",
    date: "19-07-2023, 10:15 AM",
    transactionType: "Purchase",
    transactionFromTo: "From: 19-07-2023, 10:10 AM To: 19-07-2023, 10:15 AM",
    description: "Save 20% on your order",
    amount: 75,
    status: "Active",
  },
  {
    id: 3,
    couponCode: "FREESHIP",
    date: "19-07-2023, 11:45 AM",
    transactionType: "Shipping",
    transactionFromTo: "From: 19-07-2023, 11:40 AM To: 19-07-2023, 11:45 AM",
    description: "Free shipping on all orders",
    amount: 0,
    status: "Active",
  },
  {
    id: 4,
    couponCode: "SALE10",
    date: "19-07-2023, 12:30 PM",
    transactionType: "Purchase",
    transactionFromTo: "From: 19-07-2023, 12:25 PM To: 19-07-2023, 12:30 PM",
    description: "Save 10% on selected items",
    amount: 50,
    status: "Inactive",
  },
  {
    id: 5,
    couponCode: "BONUS25",
    date: "19-07-2023, 2:15 PM",
    transactionType: "Wallet recharge",
    transactionFromTo: "From: 19-07-2023, 2:10 PM To: 19-07-2023, 2:15 PM",
    description: "Get a 25% bonus on your recharge",
    amount: 200,
    status: "Inactive",
  },
];

const MyAccount = ({ onLogin }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState(""); // State for old password
  const [newPassword, setNewPassword] = useState(""); // State for new password
  const [confirmPassword, setConfirmPassword] = useState(""); // State f
  const [emailErroMsg, setEmailErrorMsg] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false); // State to toggle password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfrimPassword] = useState(false); // State to toggle password visibility
  const [successFullyImage, setSucceessfullyImage] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Email validation
    if (!oldPassword) {
      errors.oldPassword = "Please enter old password";
      isValid = false;
    }
    if (!newPassword) {
      errors.newPassword = "Please enter new password";
      isValid = false;
    }
    if (newPassword !== confirmPassword) {
      errors.confirmPassword =
        "New Password and Re-enter Password do not match";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleLogin = (e) => {
    if (validateForm()) {
      setIsModalOpen(false);
      dispatch(setLoadingTrue());
      setTimeout(() => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setSucceessfullyImage(true);
        setTimeout(() => {
          setSucceessfullyImage(false);
        }, 1000);
        dispatch(setLoadingFalse());
      }, 2000);
    }
  };

  const closeModal = () => {
    setErrors({})
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setIsModalOpen(false);
  };
  const renderDevicesList = () => {
    return (
      <div style={{ maxHeight: "500px" }} className="scrollable-container">
        {devicesList.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "stretch",
                  padding: "1rem",
                  boxSizing: "border-box",
                  gap: "0.5rem",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    fontSize: "1.13rem",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "1.13rem",
                      height: "1.13rem",
                      overflow: "hidden",
                      flexShrink: "0",
                    }}
                    alt=""
                    src={Icons.ic_devices}
                  />
                  <div
                    style={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "0.8rem",
                      fontWeight: 400,
                      color: "#fff",
                    }}
                  >
                    Windows · Chrome
                  </div>
                </div>
                <div
                  style={{
                    width: "30%",
                  }}
                  className="itemTextDisplay"
                >
                  {"Surat,Gujarat,India"}
                </div>
                <div
                  style={{
                    width: "30%",
                  }}
                  className="itemTextDisplay"
                >
                  {"2 hours ago"}
                </div>

                <div
                  style={{
                    borderRadius: "46px",
                    border: "1px solid #F04438",
                    boxSizing: "border-box",
                    width: "8%",
                    height: "1.75rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "0.7rem",
                    color: "#F04438",
                    gap: "0.5rem",
                    // fontWeight:"bold"
                  }}
                  className="itemTextDisplay"
                >
                  <img
                    style={{
                      position: "relative",
                      width: "0.6rem",
                      height: "0.6rem",
                      overflow: "hidden",
                      flexShrink: "0",
                    }}
                    alt=""
                    src={Icons.ic_red_close}
                  />
                  {"Remove"}
                </div>
              </div>
              <div className="borderTopStyle" />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      {successFullyImage && (
        <div
          style={{
            alignSelf: "stretch",
            alignItems: "center",
            position: "absolute",
            width: "82vw",
            justifyContent: "center",
            alignSelf: "center",
            display: "flex",
          }}
        >
          <img
            src={Icons.ic_password_successufly}
            style={{ objectFit: "contain", height: "auto", width: "15rem" }}
          />
        </div>
      )}
      <Header name={"My Account"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
        }}
      >
        <div
          style={{ border: "1px solid #FFFFFF1A", borderRadius: "10px" }}
          className="astrologer-inner"
        >
          <div style={{}} className="frame-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  fontSize: "0.7rem",
                }}
                className="fontSize24"
              >
                Email
              </div>

              <div
                onClick={() => {
                  dispatch(setLoadingTrue());
                  setTimeout(() => {
                    dispatch(setProfileData(null));
                    const event = new CustomEvent("tabEventListener", {
                      detail: "/Home",
                    });
                    window.dispatchEvent(event);
                    navigate("/");
                    dispatch(setLoadingFalse());
                  }, 1000);
                }}
                style={{
                  cursor: "pointer",
                  flex: 0.01,
                  padding: "0.4rem",
                  gap: 10,
                  borderRadius: 20,
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  fontSize: "0.7rem",
                }}
                className="fontSize24"
              >
                <img
                  className="logout_icon_style_list"
                  alt=""
                  src={Icons.ic_logout}
                />
                Logout
              </div>
            </div>

            <div className="email-border">
              <div className="fontSize16">abc@gmail.com</div>
            </div>
            {/* <div
              onClick={() => setIsModalOpen(true)}
              style={{
                marginTop: "0.5rem",
                color: "#2E90FA",
                fontSize: "0.7rem",
                cursor: "pointer",
              }}
              className="fontSize16"
            >
              Reset Password
            </div> */}
          </div>
        </div>
      </div>

      {/* <div
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div
          onClick={() => navigate("/UserProfile")}
          style={{ cursor: "pointer" }}
          className="fontSize24"
        >
          Devices
        </div>
      </div> */}
      {/* <div className="list-user-div-container">{renderDevicesList()}</div> */}

      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isModalOpen}
        // onRequestClose={closeModal}
      >
        <div
          style={{
            background: Colors.mainDarkColor,
            border: "1px solid rgba(255, 255, 255, 0.1)",
            borderRadius: "15px",
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <div
            style={{
              padding: "10px 20px 10px 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div
                style={{ textAlign: "center", flexGrow: 1 }}
                className="title_reset_password"
              >
                Reset Password
              </div>
              <img
                onClick={closeModal}
                style={{ cursor: "pointer", marginLeft: "auto" }}
                src={Icons.ic_close}
                alt="ic_close"
                className="modal-ic_close"
              />
            </div>
            <div className="title_password_main">Old password</div>
            <div
              style={{
                border: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "30px",
                width: "45vh",
                display: "flex",
                padding: "6px 15px",
              }}
            >
              <input
                type={showOldPassword ? "text" : "password"}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="searchTextInput"
                placeholder="Enter password"
                style={{
                  width: "100%", // Ensure the input fills the parent div
                }}
              />
              <img
                onClick={() => setShowOldPassword(!showOldPassword)}
                src={
                  showOldPassword ? Icons.show_password : Icons.hide_password
                }
                style={{ cursor: "pointer", height: "1rem", width: "1rem" }}
              />
            </div>
            {errors.oldPassword && (
              <div style={{ fontSize: "0.77rem", color: "red" }}>
                {errors.oldPassword}
              </div>
            )}
            <div className="title_password_main">New password</div>
            <div
              style={{
                border: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "30px",
                width: "45vh",
                display: "flex",
                padding: "6px 15px",
              }}
            >
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="searchTextInput"
                placeholder="Enter password"
                style={{
                  width: "100%",
                }}
              />
              <img
                onClick={() => setShowNewPassword(!showNewPassword)}
                src={
                  showNewPassword ? Icons.show_password : Icons.hide_password
                }
                style={{ cursor: "pointer", height: "1rem", width: "1rem" }}
              />
            </div>
            {errors.newPassword && (
              <div style={{ fontSize: "0.77rem", color: "red" }}>
                {errors.newPassword}
              </div>
            )}

            <div className="title_password_main">Re-enter new password</div>
            <div
              style={{
                border: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "30px",
                display: "flex",
                width: "45vh",
                padding: "6px 15px",
              }}
            >
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="searchTextInput"
                placeholder="Enter password"
                style={{
                  width: "100%", // Ensure the input fills the parent div
                }}
              />
              <img
                onClick={() => setShowConfrimPassword(!showConfirmPassword)}
                src={
                  showConfirmPassword
                    ? Icons.show_password
                    : Icons.hide_password
                }
                style={{ cursor: "pointer", height: "1rem", width: "1rem" }}
              />
            </div>
            {errors.confirmPassword && (
              <div style={{ fontSize: "0.77rem", color: "red" }}>
                {errors.confirmPassword}
              </div>
            )}
            <div
              onClick={() => handleLogin()}
              className="reset_password_on_click"
            >
              Reset Password
            </div>

            <Loader />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyAccount;
