import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import CustomInventoryRenderItemList from "./CustomInventoryRenderItemList";
import EmptyData from "../EmptyData/EmptyData";

function CustomInventoryTitleList({
  data,
  currentStockSort,
  sellingPriceSort,
  dailyOrdersSort,
  totalOrderSort,
  onClickDeleteInventory,
  handleScroll,commentsContainerRef
}) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        flex: 1,
        borderRadius: "8px",
        border: Colors.darkBorderColor,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
      }}
    >
    
      <div
        style={{
          alignSelf: "stretch",
          flex: 1,
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0.8rem",
        }}
      >
        <div style={{ width: "30%" }} className="contentName">
          Product information
        </div>

        <div
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            width: "15%",
            cursor: "pointer",
          }}
          onClick={currentStockSort}
          className="contentName"
        >
          Current stock
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            width: "15%",
            cursor: "pointer",
          }}
          onClick={sellingPriceSort}
          className="contentName"
        >
          Selling price
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            width: "15%",
            cursor: "pointer",
          }}
          className="contentName"
          onClick={dailyOrdersSort}
        >
          Daily orders
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            width: "15%",
            cursor: "pointer",
          }}
          className="contentName"
          onClick={totalOrderSort}
        >
          Total orders
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div style={{ width: "10%" }} className="contentName">
          Status
        </div>
        <div style={{ width: "2%" }} className="contentName"></div>
        <div style={{ width: "3px" }} className="contentName"></div>
      </div>

      <div   ref={commentsContainerRef}
        onScroll={handleScroll} style={{ maxHeight: "500px", }} className="scrollable-container">
        {/* {data.map((item, index) => ( */}
          <CustomInventoryRenderItemList
          data={data}
          // // item={item}
          //   key={index}
            onClickDeleteInventory={onClickDeleteInventory}
          />
        {/* ))} */}
      </div>
    </div>
  );
}

export default CustomInventoryTitleList;
