import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Icons from "../../../Constants/Icons";
import Colors from "../../../Constants/Colors";
import StatusButton from "../../../Components/StatusButton/StatusButton";
import CopyToClipboard from "../../../Components/CopyToClipboard";

function BannerListItem({ data, onClickEditBanner, onClickInActiveBanner }) {
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = (index) => {
    if (index === openMenuIndex) {
      setOpenMenuIndex(-1);
    } else {
      setOpenMenuIndex(index);
    }
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
    setOpenMenuIndex(-1);
  };

  return (
    <div style={{ maxHeight: "500px" }} className="scrollable-container">
      {data.map((item, index) => {
        return (
          <div
          key={index}
            style={{
              padding: "1rem",
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                gap: "0.5rem",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                  alignSelf: "stretch",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    fontFamily: "Poppins-Regular",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    color: "#fff",
                    position: "relative",
                  }}
                  className="fontSize18Weight400"
                >
                  {item._id}
                </div>
                <CopyToClipboard  text={item._id}>
                <img
                  style={{ cursor: "pointer" }}
                  className="icon_copy"
                  alt=""
                  src={Icons.ic_copy}
                />
                </CopyToClipboard>
              </div>
              <div
                style={{
                  width: "10%",
                }}
                className="fontSize18Weight400"
              >
                {item.bannerType}
              </div>

              <div
                style={{
                  width: "18%",
                }}
                className="fontSize18Weight400"
              >
                {item.title}
              </div>


              <div
                style={{
                  width: "10%",
                }}
                className="fontSize18Weight400"
              >
                {item.discountPercentage ? item.discountPercentage+"%":item.discountPercentage}
              </div>
              <div
                style={{
                  width: "20%",
                }}
                className="fontSize18Weight400"
              >
                <div
                  style={{
                    width: "4rem",
                    height: "4rem",
                    borderRadius: "10px",
                  }}
                  className="indian-man-smiling-astrologer"
                >
                  <img
                    style={{
                      position: "relative",
                      width: "4rem",
                      height: "4rem",
                    }}
                    alt=""
                    src={item.imageUrl}
                  />
                </div>
              </div>

              <div
                style={{
                  cursor: "pointer",
                  width: "15%",
                }}
                // onClick={() => onClickActiveInActiveAstrologer()}
              >
                <StatusButton
                  style={{
                    width: "100%",
                    backgroundColor: item?.isActive
                      ? Colors.mainGreen01
                      : Colors.mainRed01,
                    borderColor: item?.isActive
                      ? Colors.darkGreen
                      : Colors.darkRed,
                    color: item?.isActive ? Colors.darkGreen : Colors.darkRed,
                  }}
                >
                  {item?.isActive ? "Active" : "InActive"}
                </StatusButton>
              </div>
              <div
                onClick={() => toggleMenu(index)}
                style={{ cursor: "pointer", width: "2%", display: "flex" }}
              >
                <img
                  style={{ width: "1.2rem", objectFit: "cover" }}
                  src={Icons.ic_more}
                />
              </div>
              <div
                style={{
                  marginBottom: index === data.length - 1 ? "80px" : "auto",
                  right: "2rem",
                  top: index == 0 ? "0px" : "auto",
                }}
                className="custom-menu-popup-container"
              >
                {openMenuIndex === index && (
                  <div
                    style={{ width: "auto" }}
                    onMouseLeave={closeMenu}
                    className="custom-menu-popup"
                  >
                    <div
                      onClick={() => {
                        closeMenu();
                        onClickEditBanner(item);
                      }}
                      className="custom-menu-list"
                    >
                      Edit
                    </div>
                    <div
                      onClick={() => {
                        closeMenu();
                        onClickInActiveBanner(item);
                      }}
                      className="custom-menu-list"
                    >
                      {item.isActive ? "Inactive" : "Active"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default BannerListItem;
