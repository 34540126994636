import React, { useCallback, useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import "./Shop.css";
import Header from "../../../Components/Header/Header";
import Icons from "../../../Constants/Icons";
import Images from "../../../Constants/Images";
import CustomOrderListTitle from "../../../Components/CustomOrderListScreen/CustomOrderListTitle";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import RatingScreen from "./RatingScreen";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  post_api_product_details,
  post_api_shop_getProductOrderList,
  post_delete_product_deleteProduct,
} from "../../../Core/Apicall";
import StatusButton from "../../../Components/StatusButton/StatusButton";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import CopyToClipboard from "../../../Components/CopyToClipboard";
function InventoryProductDescription() {
  const [imageArray, setImageArray] = useState([]);
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  const [totalProductList, setTotalProductList] = useState(0);

  const location = useLocation();
  const dispatch = useDispatch();

  const statusDropdownItem = [
    "All",
    "New",
    "Dispatched",
    "Delivered",
    "Return",
  ];
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectImageShowBig, setSelectImageShowBig] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [initialPage, setInitialPage] = useState(0);
  const [productDetailsData, setProductDetailsData] = useState();
  const [subOrderListData, setSubOrderListData] = useState([]);
  const [subOrderListAllData, setSubOrderListAllData] = useState([]);
  const [productDetailsDataEdit, setProductDetailsEditData] = useState();
  const tabs = ["Orders","Reviews"];

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  
  // const tabs = ["Orders", "Reviews"];
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleSelect = (status) => {
    setDropDownSelectedValue(status);
    setSearchValue("");
    if (status === "All") {
      setSubOrderListData(subOrderListAllData); // Show all items
    } else {
      const filteredData = subOrderListAllData.filter(
        (filData) => filData.orderStatus === status
      );
      setSubOrderListData(filteredData);
    }
  };
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };
  const sortByHeader = (property) => {
    const sortedData = [...subOrderListData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });
    setSubOrderListData(sortedData);
  };
  const searchFilterFunction = (text) => {
    setDropDownSelectedValue("All");
    if (text) {
      const newData = subOrderListAllData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setSubOrderListData(newData);
    } else {
      setSubOrderListData(subOrderListAllData);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);

  const fetchProductDetails = () => {
    let param = {
      productId: location?.state?.productId,
    };
    dispatch(setLoadingTrue());
    post_api_product_details(param)
      .then((response) => {
        let dataGet = response.product;
        const data = {
          productName: dataGet?.productName,
          skuId: dataGet?.SKUId,
          mainProductImage: dataGet?.productImage,
          images: dataGet?.productImages,
          description: dataGet?.productDescription,
          weight: dataGet?.weight,
          price: dataGet?.productPrice,
          discount_price: dataGet?.beforeDiscountAmount,
          cost_per_item: dataGet?.costPerItemAmount,
          profit: dataGet?.profitAmount,
          margin: dataGet?.productName,
          initial_quantity: dataGet?.initialQuantityOnHand,
          reorder_print: dataGet?.reOrderPoint,
          weight_kg: dataGet?.productName,
          product_status:
            dataGet?.productStatus === "inStock"
              ? "In Stock"
              : dataGet?.productStatus === "outOfStock"
              ? "Out Stock"
              : dataGet?.productStatus === "inActive"
              ? "In Active"
              : "Low Stock",
          shop_category: dataGet?.categoryId,
          physicalProductChecked: dataGet?.isPhysicalProduct,
          chargeTaxChargeProduct: dataGet?.productName,
          isValidityToDate: new Date(dataGet?.date),
          productId: location?.state?.productId,
          about: dataGet?.aboutProduct,
        };

        const productImages = response?.product?.productImages || [];
        const updatedImageArray = productImages.map((image, index) => ({
          imageUrl: image,
          selected: index === 0 ? true : false,
        }));
        setImageArray(updatedImageArray);
        setProductDetailsData(response.product);
        setProductDetailsEditData(data);
        dispatch(setLoadingFalse());
      })
      .catch((error) => dispatch(setLoadingFalse()));
  };

  const onClickEdit = () => {
    const dataEdit = true;
    navigate("/AddInventory", {
      state: { data: productDetailsDataEdit, dataEdit },
    });
  };

  const onClickDuplicate = () => {
    navigate("/AddInventory", { state: { data: productDetailsDataEdit } });
  };

  const onClickDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmed) {
      dispatch(setLoadingTrue());
      try {
        let param = {
          productId: location?.state?.productId,
        };
        await post_delete_product_deleteProduct(param);
        navigate(-1);
      } catch (error) {
        dispatch(setLoadingFalse());
      }
    }
  };

  const handleSelectImageClick = (item, index) => {
    setSelectImageShowBig(item.imageUrl);
    const updatedImages = imageArray.map((imageObj, i) => {
      if (i === index) {
        return { imageUrl: imageObj.imageUrl, selected: true };
      } else {
        return { imageUrl: imageObj.imageUrl, selected: false };
      }
    });
    setImageArray(updatedImages);
  };

  useEffect(() => {
    onGetProductWiseOrderList();
  }, [pageComments]);

  const onGetProductWiseOrderList = (selectedPage) => {
    const param = {
      offset: 30 * pageComments,
      limit: 30,
    };
    const data = {
      productId: location?.state?.productId,
    };
    dispatch(setLoadingTrue());
    setDropDownSelectedValue("All");
    setSearchValue("");
    post_api_shop_getProductOrderList(param, data)
      .then((response) => {
        setInitialPage(selectedPage?.selected || 0);
        

        setSubOrderListData((prevData) => [...prevData, ...response?.orders]);

        setSubOrderListAllData((prevData) => [
          ...prevData,
          ...response?.orders,
        ]);


        setTotalProductList(Math.ceil(response.totalOrdersCount / 30));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };


  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalProductList > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };


  return (
    <div
      style={{
        padding: 20,
        paddingBottom: "5rem",
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Zigzek shop / Inventory / " + productDetailsData?.SKUId} />
      <div
        style={{
          backgroundColor: "#1e1e25",
          fontSize: "1.5rem",
          alignSelf: "stretch",
          color: "#fff",
        }}
      >
        <div
          style={{
            borderRadius: "16px",
            backgroundColor: "#25252d",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1rem",
            boxSizing: "border-box",
            gap: "2rem",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  maxHeight: "18rem",
                  width: "auto",
                }}
                className="scrollable-container"
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "grid",
                    gridTemplateRows: "repeat(4, 1fr)", // Creating 4 columns
                    gap: "0.5rem",
                  }}
                >
                  {imageArray?.map((imageUrl, index) => (
                    <div
                      style={{
                        cursor: "pointer",
                        border: imageUrl.selected ? "1px solid #FFFFFF" : 0,
                      }}
                      className="product-image-category"
                      key={index}
                      onClick={() => handleSelectImageClick(imageUrl, index)}
                    >
                      <img
                        className="product-image-category-image-tag"
                        src={imageUrl.imageUrl}
                        alt={`Image ${index}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="product-big-image-show">
                <img
                  style={{
                    position: "relative",
                    flex: 1,
                    width: "10rem",
                    height: "10rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={selectImageShowBig ?? imageArray[0]?.imageUrl}
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flex: "1",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1.13rem",
                height: "18rem",
              }}
            >
              <div
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div className="Main-Title-Product">
                      {productDetailsData?.productName}
                    </div>
                    <div className="fontSize14Weight400">
                      {productDetailsData?.aboutProduct}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "0.25rem",
                      fontSize: "1rem",
                      color: "#12b76a",
                    }}
                  >
                    <StatusButton
                      style={{
                        width: "50%",
                        backgroundColor:
                          productDetailsData?.productStatus === "inStock"
                            ? Colors.mainGreen01
                            : productDetailsData?.productStatus === "inActive"
                            ? Colors.mainRed01
                            : productDetailsData?.productStatus === "outOfStock"
                            ? Colors.mainOrange01
                            : Colors.mainBlue01,
                        borderColor:
                          productDetailsData?.productStatus === "inStock"
                            ? Colors.darkGreen
                            : productDetailsData?.productStatus === "inActive"
                            ? Colors.darkRed
                            : productDetailsData?.productStatus === "outOfStock"
                            ? Colors.darkOrange
                            : Colors.darkBlue,
                        color:
                          productDetailsData?.productStatus === "inStock"
                            ? Colors.darkGreen
                            : productDetailsData?.productStatus === "inActive"
                            ? Colors.darkRed
                            : productDetailsData?.productStatus === "outOfStock"
                            ? Colors.darkOrange
                            : Colors.darkBlue,
                      }}
                    >
                      {productDetailsData?.productStatus}
                    </StatusButton>
                    {/* <div className="in-stock-second-Container">In stock</div> */}
                    <div className="product-description-id">
                      <div style={{ position: "relative", marginTop: 5 }}>
                        {location?.state?.productId}
                      </div>
                      <CopyToClipboard  text={location?.state?.productId}>
                      <img
                        style={{ cursor: "pointer" }}
                        className="icon_copy"
                        src={Icons.ic_copy}
                      />
                      </CopyToClipboard>
                    </div>
                    <div
                      className="product-description-id"
                      style={{
                        gap: "0.25rem",
                        color: "#2e90fa",
                      }}
                    >
                      <img
                        style={{
                          position: "relative",
                          width: "1rem",
                          height: "1rem",
                          objectFit: "cover",
                        }}
                        alt=""
                        src={Icons.ic_star}
                      />
                      <div style={{ position: "relative" }}>
                        {productDetailsData?.ratings}(
                        {productDetailsData?.reviewCount})
                      </div>
                    </div>
                  </div>
                  <div className="amountShowProduct">
                    ₹{productDetailsData?.productPrice}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    textAlign: "center",
                    fontSize: "0.8rem",
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className="edit-duplicate-main-div"
                    onClick={() => onClickEdit()}
                  >
                    <div className="edit-duplicate-text-div">Edit</div>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="edit-duplicate-main-div"
                    onClick={() => onClickDuplicate()}
                  >
                    <div className="edit-duplicate-text-div">Duplicate</div>
                  </div>
                  <div
                    className="edit-duplicate-main-div"
                    style={{
                      border: "1px solid #e44145",
                      color: "#e44145",
                      cursor: "pointer",
                    }}
                    onClick={() => onClickDelete()}
                  >
                    <div className="edit-duplicate-text-div">Delete</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                  fontSize: "1rem",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                <div className="order-border-main-div">
                  <div className="order-title-font">Total orders</div>
                  <div
                    className="order-title-value-display"
                    style={{
                      color: "#fff",
                    }}
                  >
                    {productDetailsData?.totalOrders ?? 0}
                  </div>
                </div>
                <div className="order-border-main-div">
                  <div className="order-title-font">Delivered orders</div>
                  <div
                    className="order-title-value-display"
                    style={{
                      color: "#12b76a",
                    }}
                  >
                    {productDetailsData?.totalDeliveredOrders}
                  </div>
                </div>
                <div className="order-border-main-div">
                  <div className="order-title-font">Canceled orders</div>
                  <div
                    className="order-title-value-display"
                    style={{
                      color: "#f04438",
                    }}
                  >
                    {productDetailsData?.totalCanceledOrders}
                  </div>
                </div>
                <div className="order-border-main-div">
                  <div className="order-title-font">New orders</div>
                  <div
                    className="order-title-value-display"
                    style={{
                      color: "#2e90fa",
                    }}
                  >
                    {productDetailsData?.totalNewOrders}
                  </div>
                </div>
                <div className="order-border-main-div">
                  <div className="order-title-font">Dispatched orders</div>
                  <div
                    className="order-title-value-display"
                    style={{
                      color: "#f79009",
                    }}
                  >
                    {productDetailsData?.totalDispatchOrders}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.5rem",
            }}
          >
            <div className="Main-Title-Product">Description</div>
            <div style={{ color: "#FFF" }} className="fontSize14Weight400">
              {productDetailsData?.productDescription}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: "1.3rem", gap: "2rem" }}
        className="user-profile-custom-container"
      >
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{
              padding: "10px",
              fontWeight: "400",
              color: index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
              borderBottom: index != activeTab ? "" : "1px solid #fff",
              cursor: "pointer",
            }}
            className="fontSize18"
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>

      {activeTab === 0 && (
        <div style={{}}>
          <div
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
            className="sectionDivStyle"
          >
            <div
              className="title24FontWeight400WHite"
              style={{
                flex: "1",
                position: "relative",
                alignSelf: "stretch",
                alignItems: "center",
                display: "flex",
              }}
            >
              Order list
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  border: Colors.darkBorderColor,
                  boxSizing: "border-box",
                  width: "9.38rem",
                  display: "flex",
                  zIndex: 1,
                  padding: "0.5rem 0.5rem",
                }}
              >
                <CustomDropdown
                  dropDownLabel={dropdownSelected}
                  items={statusDropdownItem}
                  color="blue"
                  onSelect={handleSelect}
                  customLabelStyle={{
                    fontWeight: "400",
                    color: Colors.greyColor50,
                  }}
                />
              </div>

              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxSizing: "border-box",
                  width: "15.75rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem 0.5rem",
                  gap: "0.5rem",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.5rem",
                    height: "1.5rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={Icons.ic_search}
                />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <CustomOrderListTitle
            handleScroll={handleScroll}
            commentsContainerRef={commentsContainerRef}
            hideMore={true}
            data={subOrderListData}
            productInformationShow={false}
            amountSort={() => sortByHeader("amount")}
          />
          {subOrderListData?.length == 0 && <EmptyData />}
          {/* {subOrderListData?.length != 0 && (
            <div
              style={{
                alignItems: "flex-end",
                display: "flex",
                flex: 1,
                alignSelf: "stretch",
                justifyContent: "flex-end",
              }}
            >
              <CustomPagination
                pageCount={totalProductList}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={onGetProductWiseOrderList}
              />
            </div>
          )} */}
        </div>
      )}

      {activeTab === 1 && <RatingScreen productId={location?.state?.productId}/>}
    </div>
  );
}

export default InventoryProductDescription;
