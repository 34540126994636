export const allUser = "users/allUserList";
export const allAstrologerList = "astrologer/astrologers";
export const getNewAstrologerRegistration =
  "astrologer/getNewAstrologerRegistration";
export const setAstrologerRegisterStatus =
  "astrologer/setAstrologerRegisterStatus";
export const getAstrologerApproveOrReject =
  "astrologer/getAstrologerApproveOrReject";
export const moveAstrologerToOnboard = "astrologer/moveAstrologerToOnboard";
export const astrologerUserDetails = "astrologer/astrologerDetail";
export const userDetails = "users/userDetail";
export const getTicketList = "ticket/getTicketList";
export const updateTicket = "ticket/updateTicket";
export const supportAllMessage = "message/allMessage";
export const loginAdmin = "admin/loginAdmin";
export const expertises = "expertises";
export const uploadastrologerimages = "astrologer/upload-astrologer-images";
export const addNewAstrologer = "astrologer/addNewAstrologer";
export const deleteAstrologer = "astrologer/deleteAstrologer";
export const astrologerDetail = "astrologer/astrologerDetail";
export const editAstrologerDetails = "astrologer/editAstrologerDetails";
export const getUserSessionList = "users/getUserSessionList";
export const getUserQuickSessionInfo = "users/getUserQuickSessionInfo";
export const getUserTransactionHistory = "users/getUserTransactionHistory";
export const session_overview_details = "session/session-overview-details";
export const getAllOrderList = "shop/getAllOrderList";
export const changeOrderStatus = "shop/changeOrderStatus";
export const sessionGetOverview = "session/getOverview";
export const session_getSessionList = "session/getSessionList";
export const transaction_overview = "transaction/transaction-overview";
export const incomeByTransactionType = "transaction/incomeByTransactionType";
export const userTransactionHistory = "transaction/userTransactionHistory";
export const live_session_live_session_list = "live-session/live-session-list";
export const live_session_getLiveCallWaitingList =
  "live-session/getLiveCallWaitingList";
export const live_session_getLiveStreamMessages =
  "live-session/getLiveStreamMessages";
export const live_session_getLiveCallList = "live-session/getLiveCallList";
export const sendGiftToUser = "users/sendGiftToUser";
export const astrologer_getAstrologerLiveUpdates =
  "astrologer/getAstrologerLiveUpdates";
export const product_addNewProduct = "product/addNewProduct";
export const shop_getProductList = "shop/getProductList";
export const product_deleteProduct = "product/deleteProduct";
export const shop_getInventoryOverview = "shop/getInventoryOverview";
export const product_editProduct = "product/editProduct";
export const shop_getProductDetails = "shop/getProductDetails";
export const shop_getProductOrderList = "shop/getProductOrderList";
export const shop_mainOverview = "shop/mainOverview";
export const transaction_astrologerTransactionOverview =
  "transaction/astrologerTransactionOverview";
export const transaction_getAstrologerTransactionHistory =
  "transaction/getAstrologerTransactionHistory";
export const transaction_addTransactions = "transaction/addTransactions";
export const transaction_getAstrologerList = "transaction/getAstrologerList";
export const transactionEarningTransactionOverview =
  "transaction/earningTransactionOverview";
export const transactionEarningTransactionHistory =
  "transaction/earningTransactionHistory";
export const couponAddNewCoupon = "coupon/add-new-coupon";
export const couponCouponOverview = "coupon/couponOverview";
export const couponGetCouponList = "coupon/getCouponList";
export const couponUpdateCouponStatus = "coupon/updateCouponStatus";
export const couponEditCoupon = "coupon/editCoupon";
export const transactionGetRevenue = "transaction/getRevenue";
export const transactionGetTaxAmount = "transaction/getTaxAmount";
export const usersCheckUserExists = "astrologer/checkMobileAndEmail";
export const astrologerGetAstrologerSessionList =
  "astrologer/getAstrologerSessionList";
export const astrologerGetAstrologerTransactionHistory =
  "astrologer/getAstrologerTransactionHistory";
export const astrologerGetAstrologerWithdrawalsHistory =
  "astrologer/getAstrologerWithdrawalHistory";
export const notificationSendNotifications = "notification/send-notifications";
export const getScheduleNotificationList =
  "notification/getScheduleNotificationList";
export const notificationGetAdminNotificationList =
  "notification/getAdminNotificationList";
export const notificationEditNotification = "notification/editNotification";
export const notificationDeleteNotification = "notification/deleteNotification";
export const astrologerActiveInactive = "astrologer/activeInactiveAstrologer";
export const bannerCreateNewBanner = "banner/createNewBanner";
export const bannerGetAllBanners = "banner/getAllBanners";
export const bannerEditBanner = "banner/editBanner";
export const bannerActiveInactiveBanner = "banner/activeInactiveBanner";
export const shopGetAllShopCategories = "shop/getAllShopCategories";
export const astrologerAddNewAstrologerWithPrimaryDetails = "astrologer/addNewAstrologerWithPrimaryDetails";
export const usersGetAllLanguageList = "users/getAllLanguageList";
export const liveSessionGetAllLiveCalls = "live-session/getAllLiveCalls";
export const liveSessionGetLiveGiftsHistory = "live-session/getLiveGiftsHistory";
export const PRODUCTGetProductReviews = "PRODUCT/getProductReviews";
export const sessionRefundAmount = "session/refundAmount";
