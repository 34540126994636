import React, { useEffect } from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import CustomSessionList from "./CustomSessionList";
import { useState } from "react";

function CustomSessionContent({
  userName,
  sessionType,
  duration,
  fees,
  totalFees,
  status,
  data,
  onClickSession,
  userId,
  handleScroll,
  commentsContainerRef
}) {
  const [sessionListData, setSessionListData] = useState(data);
  useEffect(() => {
    setSessionListData(data);
  }, [data]);
  const [sortOrder, setSortOrder] = useState("asc");
  const parseAvgTime = (value) => {
    const numericValue = parseFloat(
      value.replace(/₹/g, "").replace(/\/min/g, "")
    );
    return numericValue;
  };
  const onCLickSortingArray = (property) => {
    if (property == "duration") {
      const sortedData = [...sessionListData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        if (sortOrder === "asc") {
          return parseAvgTime(a[property]) - parseAvgTime(b[property]);
        } else {
          return parseAvgTime(b[property]) - parseAvgTime(a[property]);
        }
      });
      setSessionListData(sortedData);
    } else {
      const sortedData = [...sessionListData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        if (sortOrder === "asc") {
          return a[property] - b[property];
        } else {
          return b[property] - a[property];
        }
      });
      setSessionListData(sortedData);
    }
  };

  return (
    <div
      style={{
        alignSelf: "stretch",
        flex: 1,
        border: Colors.darkBorderColor,
        borderRadius: "8px",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          flex: "1",
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <div style={{ width: "25%" }} className="contentName">
          {userName}
        </div>
        <div style={{ width: "23%" }} className="contentName">
          {sessionType}
        </div>
        <div
          onClick={() => onCLickSortingArray("duration")}
          style={{
            width: "13%",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          className="contentName"
        >
          {duration}
          <img className="ic_sorting" alt="" src={Icons.ic_shorting} />
        </div>
        <div
          onClick={() => onCLickSortingArray("fees")}
          style={{
            width: "13%",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          className="contentName"
        >
          {fees}
          <img className="ic_sorting" alt="" src={Icons.ic_shorting} />
        </div>
        <div
          onClick={() => onCLickSortingArray("totalFees")}
          style={{
            width: "13%",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          className="contentName"
        >
          {totalFees}
          <img className="ic_sorting" alt="" src={Icons.ic_shorting} />
        </div>
        <div style={{ width: "13%" }} className="contentName">
          {status}
        </div>
      </div>

      <div
        style={{ maxHeight: "500px" }}
        ref={commentsContainerRef}
        onScroll={handleScroll}
        className="scrollable-container"
      >
      {sessionListData?.map((item, index) => (
        <CustomSessionList onClickSession={onClickSession} userId={userId} astrologerData={item} />
      ))}
      </div>
    </div>
  );
}

export default CustomSessionContent;
