// Home.js
import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./RegistrationStyle.css";
import "./../../../Constants/CommonStyle.css";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Icons from "../../../Constants/Icons";
import Header from "../../../Components/Header/Header";
import RegistrionRenderList from "../../../Components/RegistrionRenderList/RegistrionRenderList";
import CustomPagination from '../../../Components/CustomPagination/CustomPagination'
import EmptyData from "../../../Components/EmptyData/EmptyData";
const initialRegestrationData = [
  {
    id: 1,
    name: "Rajesh",
    phone: "+91233445555",
    email: "rajesh@example.com",
    avatar: "path_to_avatar_image1",
    status: "In progress",
    gender: "Male",
    quick: "Quick",
    chat: "In progress",
  },
  {
    id: 2,
    name: "John",
    phone: "+9123456789",
    email: "john@example.com",
    avatar: "path_to_avatar_image2",
    status: "Approved",
    gender: "Male",
    quick: "Quick",
    chat: "Chat",
  },
  {
    id: 3,
    name: "Alice",
    phone: "+9123456789",
    email: "alice@example.com",
    avatar: "path_to_avatar_image3",
    status: "In progress",
    gender: "Female",
    quick: "Quick",
    chat: "Chat",
  },
  {
    id: 4,
    name: "Bob",
    phone: "+9123456789",
    email: "bob@example.com",
    avatar: "path_to_avatar_image4",
    status: "In progress",
    gender: "Male",
    quick: "Quick",
    chat: "Chat",
  },
  {
    id: 5,
    name: "Eve",
    phone: "+9123456789",
    email: "eve@example.com",
    avatar: "path_to_avatar_image5",
    status: "New",
    gender: "Female",
    quick: "Quick",
    chat: "Chat",
  },
  {
    id: 6,
    name: "Michael",
    phone: "+9123456789",
    email: "michael@example.com",
    avatar: "path_to_avatar_image6",
    status: "In progress",
    gender: "Male",
    quick: "Quick",
    chat: "Chat",
  },
  {
    id: 7,
    name: "Sara",
    phone: "+9123456789",
    email: "sara@example.com",
    avatar: "path_to_avatar_image7",
    status: "Rejected",
    gender: "Female",
    quick: "Quick",
    chat: "Chat",
  },
  {
    id: 8,
    name: "David",
    phone: "+9123456789",
    email: "david@example.com",
    avatar: "path_to_avatar_image8",
    status: "In progress",
    gender: "Male",
    quick: "Quick",
    chat: "Chat",
  },
  {
    id: 9,
    name: "Linda",
    phone: "+9123456789",
    email: "linda@example.com",
    avatar: "path_to_avatar_image9",
    status: "Rejected",
    gender: "Female",
    quick: "Quick",
    chat: "Chat",
  },
  {
    id: 10,
    name: "Peter",
    phone: "+9123456789",
    email: "peter@example.com",
    avatar: "path_to_avatar_image10",
    status: "Approved",
    gender: "Male",
    quick: "Quick",
    chat: "Chat",
  },
];

function Registration() {
  const [registrationListData, setRegstrationListListData] = useState(
    initialRegestrationData
  );
  const statusDropdownItem = [
    "All",
    "In progress",
    "New",
    "Approved",
    "Rejected",
  ];
  const items = ["Item 1", "Item 2", "Item 3"];
  const [searchValue, setSearchValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const handleSelect = (status) => {
    setSearchValue("");
    if (status === "All") {
      setRegstrationListListData(initialRegestrationData); // Show all items
    } else {
      const filteredData = initialRegestrationData.filter(
        (astrologer) => astrologer.status === status
      );
      setRegstrationListListData(filteredData);
    }
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = initialRegestrationData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setRegstrationListListData(newData);
    } else {
      setRegstrationListListData(initialRegestrationData);
    }
  };
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Registration"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total registration</div>
                <div className="fontSize24">5237</div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total in review</div>
                <div
                  style={{ color: Colors.darkOrange }}
                  className="fontSize24"
                >
                  5184
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">New registration</div>
                <div style={{ color: Colors.darkBlue }} className="fontSize24">
                  4485
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Approved registration</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  699
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="astrologers-list-parent">
          <div className="fontSize24">Registration list</div>
          <div className="frame-parent2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  border: Colors.darkBorderColor,
                  boxSizing: "border-box",
                  width: "9.38rem",
                  display: "flex",
                  zIndex: 1,
                  padding: "0.5rem 0.5rem",
                }}
              >
                <CustomDropdown
                  dropDownLabel={"By status"}
                  items={statusDropdownItem}
                  color="blue"
                  onSelect={handleSelect}
                  customLabelStyle={{
                    fontWeight: "400",
                    color: Colors.greyColor50,
                  }}
                />
              </div>

              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxSizing: "border-box",
                  width: "15.75rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem 0.5rem",
                  gap: "0.5rem",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.5rem",
                    height: "1.5rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={Icons.ic_search}
                />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            flex: 1,
            border: Colors.darkBorderColor,
            borderRadius: "8px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: Colors.borderColor,
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: "1",
              padding: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // padding: "1rem",
                gap: "0.5rem",
              }}
            >
              <div style={{ width: "15%" }} className="contentName">
                Registration ID
              </div>
              <div style={{ width: "16%" }} className="contentName">
                Name
              </div>
              <div style={{ width: "20%" }} className="contentName">
                Contact
              </div>
              <div style={{ width: "10%" }} className="contentName">
                Experience
              </div>
              <div style={{ width: "18%" }} className="contentName">
                Expertise
              </div>
              <div style={{ width: "8%" }} className="contentName">
                Gender
              </div>
              <div style={{ width: "10%" }} className="contentName">
                Status
              </div>
              <div style={{ width: "2%" }}></div>
            </div>
          </div>
          <RegistrionRenderList data={registrationListData} />
        </div>
        {registrationListData.length <= 0 && <EmptyData />}
       {registrationListData.length != 0 && 
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            pageCount={10}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            // onPageChange={onClickAstrologerMainListApi}
          />
        </div>
       }
      </div>
    </div>
  );
}

export default Registration;
