import Colors from "../../../../Constants/Colors";
import "./../AstrologerProfile.css";
import "./../../../../Constants/CommonStyle.css";
import Icons from "../../../../Constants/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import moment from "moment";
import CopyToClipboard from "../../../../Components/CopyToClipboard";
function AstrologerProfileTab({ astrologerProfileData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getNumericValue = (str) => {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  };

  const onCLickEditPersonalInformation = () => {
    let param = {
      astrologerId: astrologerProfileData.astrologerId,
    };
    dispatch(setLoadingTrue());
    let profileData = astrologerProfileData;
    let data = {
      astrologerId: astrologerProfileData.astrologerId,
      userRole: profileData.userRole,
      firstName: profileData?.firstName ?? "",
      lastName: profileData?.lastName ?? "",
      displayname: profileData?.userName ?? "",
      email: profileData?.Email ?? "",
      mobileCode: profileData?.mobileCode ?? "+91",
      mobileNumber: profileData?.Mobile ?? "",
      productImage: profileData?.profileUrl ?? "",
      gender: profileData?.Gender ?? "",
      Languages:profileData?.Languages ?? [],
      experience: getNumericValue(profileData?.Experiance) ?? "",
      expertise: profileData?.Expertise ?? [],
      bio: profileData?.About ?? "",
      aadharNumber: profileData?.adharCardDetails?.adharCardNumber,
      aadharImageFrontSide:
        profileData?.adharCardDetails?.adharCardFrontImage ?? "",
      aadharImageBackSide:
        profileData?.adharCardDetails?.adharCardBackImage ?? "",
      pancardNumber: profileData?.panCardDetails?.panNumber ?? "",
      panCardImage: profileData?.panCardDetails?.panImage ?? "",
      accountHolderName: profileData?.bankDetails?.accountHolderName ?? "",
      bankAccountNumber: profileData?.bankDetails?.accountNumber ?? "",
      ifscCodeNumber: profileData?.bankDetails?.IFSCCode ?? "",
      bankName: profileData?.bankDetails?.bankName ?? "",
      priceperminut: profileData?.OldPrice ?? "",
      astrologercharge: profileData?.astrologerCharge ?? "",
      zigzekmargin: profileData?.zigzakMargin ?? "",
      marginpercentage: profileData?.zigzakMarginInPercentage ?? "",
      discountprice: profileData?.NewPrice ?? "",
      discount_percentage: profileData?.discountInPercentage ?? "",
    };
    const dataEdit = true;
    navigate("/AddAstrologer", { state: { data, dataEdit } });
    dispatch(setLoadingFalse());
  };


  return (
    <div
      style={{
        display: "flex",
        gap: "0.5rem",
        alignSelf: "stretch",
      }}
    >
      <div
        style={{
          borderRadius: "16px",
          height: "100%",
          boxSizing: "border-box",
          alignSelf: "stretch",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          flex: 0.6,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            alignSelf: "stretch",
          }}
        >
          <div style={{ flex: 1, display: "flex" }} className="fontSize20">
            Personal information
          </div>
          <div
            onClick={() => onCLickEditPersonalInformation()}
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              flex: 0.1,
              borderRadius: "20px",
              padding: "6px 10px 6px 10px",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              fontSize: "0.7rem",
            }}
            className="fontSize20"
          >
            <img
              style={{ height: "1rem", width: "1rem", marginRight: 10 }}
              src={Icons.ic_edit_info}
            />
            Edit
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0.75rem 1rem",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                className="fontSize16"
                style={{
                  fontWeight: 400,
                  color: Colors.greyColor50,
                  position: "relative",
                }}
              >
                ID
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                }}
              >
                <div className="fontSize18">
                  {astrologerProfileData?.astrologerId}
                </div>
                <CopyToClipboard  text={astrologerProfileData?.astrologerId}>
                <img
                  style={{
                    position: "relative",
                    width: "1.2rem",
                    cursor: "pointer",
                    objectFit: "contain ",
                    height: "1.2rem",
                    flexShrink: "0",
                  }}
                  alt=""
                  src={Icons.ic_copy}
                />
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Full name
            </div>
            <div className="fontSize18">{astrologerProfileData?.userName}</div>
          </div>

          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Performance Status
            </div>
            <div className="fontSize18">{astrologerProfileData?.profileHealth}</div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              boxSizing: "border-box",
              height: "0.06rem",
            }}
          />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Email
            </div>
            <div className="fontSize18">{astrologerProfileData?.Email}</div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Phone
            </div>
            <div className="fontSize18">{astrologerProfileData?.Mobile}</div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Account holder name
            </div>
            <div className="fontSize18">
              {astrologerProfileData?.bankDetails?.accountHolderName}
            </div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Bank account number
            </div>
            <div className="fontSize18">
              {astrologerProfileData?.bankDetails?.accountNumber}
            </div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              IFSC Code
            </div>
            <div className="fontSize18">
              {astrologerProfileData?.bankDetails?.IFSCCode}
            </div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Bank name
            </div>
            <div className="fontSize18">
              {astrologerProfileData?.bankDetails?.bankName}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "100%", // Set the height to 100% to match the other column's height
          borderRadius: "16px",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          boxSizing: "border-box",
          alignSelf: "stretch",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ padding: "1rem" }} className="fontSize20">
          About
        </div>
        <div
          style={{
            alignSelf: "stretch",
          }}
        >
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Experience
            </div>
            <div className="fontSize18">
              {astrologerProfileData?.Experiance}
            </div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Gender
            </div>
            <div className="fontSize18">{astrologerProfileData?.Gender}</div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Registration date
            </div>
            <div className="fontSize18">
              {moment(astrologerProfileData?.createdAt).format("DD-MM-YYYY")}
            </div>
          </div>
          <div className="borderTopStyle" />
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              padding: "0.75rem 1rem 1rem",
              gap: "0.5rem",
            }}
          >
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Expertise
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.5rem",
                color: "#fff",
                flexFlow: "wrap",
                flex: 1,
              }}
            >
              {astrologerProfileData?.Expertise.map((item,index) => {
                return (
                  <div key={index} className="experticeTitle">
                    <div className="fontSize18">{item.expertise}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="borderTopStyle" />
          <div style={{}} className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              About me
            </div>
            <div className="fontSize18">{astrologerProfileData?.About}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AstrologerProfileTab;
