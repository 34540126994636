const initialState = null; // Initial state can be null to indicate no profile data

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROFILE_DATA":
      return action.payload; // Set the state to the profile data received in payload
    default:
      return state;
  }
};

export default profileReducer;