import Colors from "../../Constants/Colors";

const TransactionCustomStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: 1,
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    fontSize: "0.88rem",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0.5rem 1rem",
    gap: "0.5rem",
  },
  userImage: {
    position: "relative",
    width: "2.63rem",
    height: "2.63rem",
  },
  userDetails: {
    flex: "1",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
  },
  userName: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: "1.13rem",
    color: Colors.white,
  },
  transactionMainName: {
    fontFamily: "Poppins-Regular",
    fontSize: "0.8rem",
    position: "relative",
    fontWeight: "bold",
  },
  transactionMainId: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5rem",
    fontSize: "0.88rem",
    color: Colors.greyColor50,
  },
  transactionAmount: {
    width: "20%",
  },
  walletBalance: {
    width: "20%",
  },
  transactionStatus: {
    width: "22%",
    color: Colors.darkGreen,
    justifyContent: "flex-end",
  },
};

export default TransactionCustomStyle;
