import React, { useState } from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import StatusButton from "../StatusButton/StatusButton";
import { useNavigate } from "react-router-dom";
import { post_api_product_details } from "../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import { useDispatch } from "react-redux";

function CustomInventoryRenderItemList({ data, onClickDeleteInventory }) {
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (key) => {
    if (key === openMenuIndex) {
      setOpenMenuIndex(-1);
    } else {
      setOpenMenuIndex(key);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setOpenMenuIndex(-1);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickEditInventory = (item) => {
    const dataEdit = true;
    let param = {
      productId: item.productId,
    };
    dispatch(setLoadingTrue());
    post_api_product_details(param)
      .then((response) => {
        let dataGet = response.product;
        const data = {
          productName: dataGet?.productName,
          skuId: dataGet?.SKUId,
          mainProductImage: dataGet?.productImage,
          images: dataGet?.productImages,
          description: dataGet?.productDescription,
          weight: dataGet?.weight,
          price: dataGet?.productPrice,
          discount_price: dataGet?.beforeDiscountAmount,
          cost_per_item: dataGet?.costPerItemAmount,
          profit: dataGet?.profitAmount,
          margin: dataGet?.productName,
          initial_quantity: dataGet?.initialQuantityOnHand,
          reorder_print: dataGet?.reOrderPoint,
          weight_kg: dataGet?.productName,
          product_status:
            dataGet?.productStatus == "inStock"
              ? "In Stock"
              : dataGet?.productStatus == "outOfStock"
              ? "Out Stock"
              : dataGet?.productStatus == "inActive"
              ? "In Active"
              : "Low Stock",
          shop_category: dataGet?.categoryId,
          physicalProductChecked: dataGet?.isPhysicalProduct,
          chargeTaxChargeProduct: dataGet?.productName,
          isValidityToDate: new Date(dataGet?.date),
          productId: item.productId,
          about: dataGet?.aboutProduct,
        };
        navigate("/AddInventory", { state: { data, dataEdit } });
        dispatch(setLoadingFalse());
      })
      .catch((error) => dispatch(setLoadingFalse()));
  };
  return (
    <div>
      {data.map((item, index) => {
        return (
          <div
          key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              height: index === data.length - 1 && 70,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              padding: "0.8rem",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate("/InventoryProductDescription", {
                  state: {
                    productId: item.productId,
                  },
                })
              }
            >
              <div style={{}} className="indian-man-smiling-astrologer">
                <img
                  style={{
                    position: "relative",
                    width: "2.63rem",
                    height: "2.63rem",
                  }}
                  alt=""
                  src={item.productImage}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400">{item?.productName}</div>
                <div
                  style={{
                    color: Colors.greyColor50,
                  }}
                  className="fontSize18Weight400"
                >
                  {item?.productSKUID}
                </div>
              </div>
            </div>
            <div
              className="fontSize18Weight400"
              style={{
                width: "15%",
              }}
            >
              {item?.currentStock}
            </div>
            <div
              className="fontSize18Weight400"
              style={{
                width: "15%",
              }}
            >
              {item?.sellingPrice}
            </div>

            <div
              className="fontSize18Weight400"
              style={{
                width: "15%",
              }}
            >
              {item?.dailyOrders}
            </div>
            <div
              className="fontSize18Weight400"
              style={{
                width: "15%",
              }}
            >
              {item?.totalOrders ?? 0}
            </div>
            <StatusButton
              style={{
                width: "10%",
                backgroundColor:
                  item.productStatus === "inStock"
                    ? Colors.mainGreen01
                    : item.productStatus === "inActive"
                    ? Colors.mainRed01
                    : item.productStatus === "outOfStock"
                    ? Colors.mainOrange01
                    : Colors.mainBlue01,
                borderColor:
                  item.productStatus === "inStock"
                    ? Colors.darkGreen
                    : item.productStatus === "inActive"
                    ? Colors.darkRed
                    : item.productStatus === "outOfStock"
                    ? Colors.darkOrange
                    : Colors.darkBlue,
                color:
                  item.productStatus === "inStock"
                    ? Colors.darkGreen
                    : item.productStatus === "inActive"
                    ? Colors.darkRed
                    : item.productStatus === "outOfStock"
                    ? Colors.darkOrange
                    : Colors.darkBlue,
              }}
            >
              {item.productStatus}
            </StatusButton>
            <div
              onClick={() => toggleMenu(index)}
              style={{
                visibility: item.productStatus === "inActive" && "hidden",
                zIndex: 1,
                cursor: "pointer",
                width: "2%",
                display: "flex",
              }}
            >
              <img
                style={{ width: "1.2rem", objectFit: "cover" }}
                src={Icons.ic_more}
              />
            </div>
            <div
              style={{
                marginBottom: index === data.length - 1 ? "80px" : "auto",
                right: "1rem",
                top: index == 0 ? "13px" : "auto",
              }}
              className="custom-menu-popup-container"
            >
              {openMenuIndex === index && (
                <div
                  style={{ width: "auto" }}
                  onMouseLeave={closeMenu}
                  className="custom-menu-popup"
                >
                  <div
                    onClick={() => onClickEditInventory(item)}
                    className="custom-menu-list"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => onClickDeleteInventory(item)}
                    className="custom-menu-list"
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CustomInventoryRenderItemList;
