import { useEffect, useRef, useState } from "react";
import Colors from "../../../../Constants/Colors";
import "./../../../../Constants/CommonStyle.css";
import "./../../Astrologer/AstrologerStyle.css";
import CustomDropdown from "../../../../Components/DropDown/CustomDropdown";
import CustomSessionContent from "../../../../Components/CustomSessionScreen/CustomSessionContent";
import { useNavigate } from "react-router-dom";
import EmptyData from "../../../../Components/EmptyData/EmptyData";
import { apiGetUserSessionList } from "../../../../Core/Apicall";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";

function UserSessionTab({ userId }) {
  const [sessionListData, setSessionListData] = useState([]);
  const [sessionListAllData, setSessionAllListData] = useState([]);
  const [totalSession, setTotalSession] = useState(0);
  const [scheduleSessions, setScheduleSessions] = useState(0);
  const [totalSessionPagination, setTotalSessionPagination] = useState(0);
  const [callSessions, setCallSessions] = useState(0);
  const [liveSessions, setLiveSessions] = useState(0);
  const [chatSessions, setChatSessions] = useState(0);
  const [quickSessions, setQuickSessions] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const sortByItem = ["Duration", "Fees", "Total fees"];
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const statusBySort = [
    "All",
    "Approved",
    "Cancel",
    "Declined",
    "Missed",
    "Completed",
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    onClickMainSessionListApi();
  }, [pageComments]);

  const onClickMainSessionListApi = () => {
    let param = {
      offset: 30 * pageComments,
      limit: 30,
    };
    let data = {
      userId: userId,
    };
    dispatch(setLoadingTrue());
    apiGetUserSessionList(param, data)
      .then((response) => {
        setDropDownSelectedValue("All");
        // setSessionListData(response.sessionlist);
        // setSessionAllListData(response.sessionlist);

        setSessionListData((prevData) => [
          ...prevData,
          ...response.sessionlist,
        ]);
        setSessionAllListData((prevData) => [
          ...prevData,
          ...response.sessionlist,
        ]);

        setTotalSession(response.totalSessions);
        setLiveSessions(response.liveSessions);
        setChatSessions(response.chatSessions);
        setCallSessions(response.callSessions);
        setQuickSessions(response.quickSessions);
        setScheduleSessions(response.scheduleSessions);
        setTotalSessionPagination(Math.ceil(response.totalSessions / 30));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalSessionPagination > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  const parseAvgTime = (value) => {
    const numericValue = parseFloat(
      value.replace(/₹/g, "").replace(/\/min/g, "")
    );
    return numericValue;
  };
  const sortByDuration = (property) => {
    setDropDownSelectedValue("All");
    let setProperty;
    if (property == "Duration") {
      setProperty = "duration";
      const sortedData = [...sessionListData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        const valueA = a[setProperty] || 0;
        const valueB = b[setProperty] || 0;
        if (sortOrder === "asc") {
          return parseAvgTime(valueA) - parseAvgTime(valueB);
        } else {
          return parseAvgTime(valueB) - parseAvgTime(valueA);
        }
      });
      setSessionListData(sortedData);
    } else if (property == "Fees") {
      const sortedData = [...sessionListData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        const valueA = a["fees"] || 0;
        const valueB = b["fees"] || 0;
        if (sortOrder === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      });
      setSessionListData(sortedData);
    } else if (property == "Total fees") {
      const sortedData = [...sessionListData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        const valueA = a["totalFees"] || 0;
        const valueB = b["totalFees"] || 0;
        if (sortOrder === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      });
      setSessionListData(sortedData);
    }
  };
  const handlePageChange = (selectedPage) => {};
  const filterAndSortData = (sessionStatus) => {
    setDropDownSelectedValue(sessionStatus);
    if (sessionStatus === "All") {
      setSessionListData(sessionListAllData);
    } else {
      const statusMap = {
        Approved: "approved",
        Completed: "completed",
        Cancel: "cancel",
        Declined: "declined",
        Missed: "missed",
        Accept: "accept",
      };
      const filteredData = sessionListAllData.filter(
        (astrologer) => astrologer.sessionStatus === statusMap[sessionStatus]
      );
      setSessionListData(filteredData);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "0.5rem",
        alignSelf: "stretch",
      }}
    >
      <div
        style={{
          borderRadius: "16px",
          height: "100%",
          boxSizing: "border-box",
          alignSelf: "stretch",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          flex: 0.2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ padding: "1rem" }} className="fontSize20">
          Sessions overview
        </div>
        <div
          style={{
            alignSelf: "stretch",
          }}
        >
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Total sessions
            </div>
            <div className="fontSize18">{totalSession}</div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              boxSizing: "border-box",
              height: "0.06rem",
            }}
          />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Quick sessions
            </div>
            <div className="fontSize18">{quickSessions}</div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Schedule sessions
            </div>
            <div className="fontSize18">{scheduleSessions}</div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Call sessions
            </div>
            <div className="fontSize18">{callSessions}</div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Chat sessions
            </div>
            <div className="fontSize18">{chatSessions}</div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Live sessions
            </div>
            <div className="fontSize18">{liveSessions}</div>
          </div>
        </div>
      </div>

      <div
        style={{
          flex: 0.8,
          height: "100%", // Set the height to 100% to match the other column's height
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            padding: "1rem",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              // onClick={() => navigate("/QuickSessionInfo")}
              // style={{ cursor: "pointer" }}
              className="fontSize24"
            >
              Sessions list
            </div>
            <div style={{}} className="">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  fontSize: "1rem",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.5rem 0.5rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"Sort by"}
                    items={sortByItem}
                    onSelect={sortByDuration}
                    customLabelStyle={{
                      fontWeight: "400",
                      color: Colors.greyColor50,
                    }}
                  />
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "8.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.5rem 0.5rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={dropdownSelected}
                    items={statusBySort}
                    color="blue"
                    onSelect={(selectedStatus) => {
                      filterAndSortData(selectedStatus);
                    }}
                    customLabelStyle={{
                      fontWeight: "400",
                      color: Colors.greyColor50,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{}}>
            <CustomSessionContent
              handleScroll={handleScroll}
              commentsContainerRef={commentsContainerRef}
              userName={"Astrologer Name"}
              sessionType={"Session Type"}
              duration={"Duration"}
              fees={"Fees"}
              totalFees={"Total fees"}
              status={"Status"}
              data={sessionListData}
              userId={userId}
            />
          </div>
          {sessionListData?.length <= 0 && <EmptyData />}
        </div>
      </div>
    </div>
  );
}

export default UserSessionTab;
