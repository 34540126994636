import axios from "axios";
import { BaseApiUrl } from "../Constants/Until";
import { store } from "../Config/store";
import { setProfileData } from "../Config/Actions/profileActions";
import { toast } from "react-toastify";
store.subscribe(listener);

function listener() {
  if (store.getState() !== undefined && store.getState() !== null) {
    if (store.getState().isProfileData != null) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${
        store.getState().isProfileData.token
      }`;
    }
  }
}

const instance = axios.create({
  baseURL: BaseApiUrl,
});
instance.defaults.headers.post["Content-Type"] = "application/json";
let toastShown = false;
instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("api error", error);
    if (error.response.data.message === "Please provide valid token") {
      handleLogout();
    }
    return Promise.reject(error);
  }
);
const handleLogout = () => {
  if (!toastShown) {
    toast.error("Your session has expired. Please log in again to continue.", {
      autoClose: 2000,
    });
    toastShown = true;
  }
  store.dispatch(setProfileData(null));
};
export default instance;
