import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import StatusButton from "../StatusButton/StatusButton";
import moment from "moment-timezone";
import CopyToClipboard from "../CopyToClipboard";

function TransactionHistoryList({
  name,
  transactionId,
  amount,
  dateTime,
  transaction_for,
  status,
  fromHide,
  toHide,
  transactionTypeHide,
  transactionData,
}) {
 
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        padding: "0.8rem",
        alignSelf: "stretch",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: "20%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "0.5rem",
          alignSelf: "stretch",
        }}
      >
        <div style={{}} className="fontSize18Weight400">
          {transactionData?.transactionId}
        </div>
        <CopyToClipboard text={transactionData?.transactionId}>
        <img
          style={{ cursor: "pointer" }}
          className="icon_copy"
          alt=""
          src={Icons.ic_copy}
        />
        </CopyToClipboard>
      </div>
      {!fromHide && (
        <div
          style={{
            width: "17%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "0.5rem",
          }}
        >
          <div style={{}} className="indian-man-smiling-astrologer">
            <img
              style={{
                position: "relative",
                width: "2.63rem",
                height: "2.63rem",
              }}
              alt=""
              src={transactionData?.from?.image ?? Icons.ic_default_user}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div className="fontSize18Weight400">
              {transactionData?.from?.username}
            </div>
            <div
              style={{
                color: Colors.greyColor50,
              }}
              className="fontSize18Weight400"
            >
              {transactionData?.from?.paymentType}
            </div>
          </div>
        </div>
      )}
      {!toHide && (
        <div
          style={{
            width: "17%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "0.5rem",
          }}
        >
          <div style={{}} className="indian-man-smiling-astrologer">
            <img
              style={{
                position: "relative",
                width: "2.63rem",
                height: "2.63rem",
              }}
              alt=""
              src={transactionData?.to?.image ?? Icons.ic_default_user}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div className="fontSize18Weight400">
              {transactionData?.to?.username ?? "-"}
            </div>
            <div
              style={{
                color: Colors.greyColor50,
              }}
              className="fontSize18Weight400"
            >
              {transactionData?.to?.paymentType}
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          width: "12%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div style={{textAlign:"center"}} className="fontSize18Weight400">
          {moment
            .utc(transactionData?.date_and_time)
            .local()
            .format("DD-MM-YYYY")}<br/>
          {moment
            .utc(transactionData?.date_and_time)
            .local()
            .format("hh:mm A")}
        </div>
      </div>
      {!transactionTypeHide && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "0.25rem",
            width: "12%",
          }}
        >
          {/* {transaction_for?.map((item) => { */}
            {/* return ( */}
              <div
                style={{
                  borderRadius: "46px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxSizing: "border-box",
                  // height: "1.75rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0.38rem 1rem",
                  
                }}
              >
                <div className="fontSize18Weight400">{transactionData?.transactionPurpose}</div>
              </div>
            {/* ); */}
          {/* })} */}
        </div>
      )}
      <div
        className="fontSize18Weight400"
        style={{
          width: "10%",
        }}
      >
        {"₹" + transactionData?.amount}
      </div>
      <StatusButton
        style={{
          width: "10%",
          backgroundColor:
            status === "success" ? Colors.mainGreen01 : Colors.mainRed01,
          borderColor:
            status === "success" ? Colors.darkGreen : Colors.darkRed,

          color: status === "success" ? Colors.darkGreen : Colors.darkRed,
        }}
      >
        {transactionData?.status}
      </StatusButton>
    </div>
  );
}

export default TransactionHistoryList;
