import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import Header from "../../../../Components/Header/Header";
import Newregistationlist from "./Customelist/Newregistationlist";
import EmptyData from "../../../../Components/EmptyData/EmptyData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import {
  getAllAstrologerNewRegistration,
  setAstrologerRegisterStatusApi,
} from "../../../../Core/Apicall";
import UseDebounce from "../../../../Components/UseDebounce";

function Registration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registrationListData, setRegstrationListListData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalAstrologer, setTotalAstrologer] = useState(0);
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const debouncedInput = UseDebounce(searchValue, 500);

  useEffect(() => {
    setPageComments(0);
    setRegstrationListListData([]);
    onAstrologerNewRegistration(searchValue, 0);
  }, [debouncedInput]);

  const onAstrologerNewRegistration = (item, pageComments) => {
    let param = {
      offset: 30 * pageComments,
      limit: 30,
      search: item,
    };
    dispatch(setLoadingTrue());
    getAllAstrologerNewRegistration(param)
      .then((response) => {
        setRegstrationListListData((prevData) => [
          ...prevData,
          ...response.data,
        ]);
        setTotalAstrologer(Math.ceil(response.total / 30));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onClickHandleAccept = (item) => {
    let param = {
      astrologerId: item.astrologerId,
      processStatus: "approved",
    };
    dispatch(setLoadingTrue());
    setAstrologerRegisterStatusApi(param)
      .then((response) => {
        setRegstrationListListData((prevData) =>
          prevData.filter((data) => data.astrologerId !== item.astrologerId)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onClickHandleReject = (item) => {
    let param = {
      astrologerId: item.astrologerId,
      processStatus: "reject",
    };
    dispatch(setLoadingTrue());
    setAstrologerRegisterStatusApi(param)
      .then((response) => {
        setRegstrationListListData((prevData) =>
          prevData.filter((data) => data.astrologerId !== item.astrologerId)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalAstrologer > pageComments + 1) {
        setPageComments((prevPage) => {
          const nextPage = prevPage + 1;
          onAstrologerNewRegistration(searchValue, nextPage);
          return nextPage;
        });
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Astrologers / New registration"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologers-list-parent">
          <div className="fontSize24">Astrologers list</div>
          <div className="frame-parent2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxSizing: "border-box",
                  width: "15.75rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem 0.5rem",
                  gap: "0.5rem",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.5rem",
                    height: "1.5rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={Icons.ic_search}
                />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>

              <div
                style={{
                  cursor: "pointer",
                  background: Colors.white,
                  padding: "7px 7px 7px 7px",
                  gap: "0.5rem",
                  borderRadius: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => navigate("/AddAstrologers")}
              >
                <img
                  src={Icons.ic_add}
                  style={{ height: "1rem", width: "1rem", objectFit: "cover" }}
                />
                <div className="darkGreyFont16" style={{ color: Colors.black }}>
                  Add Manually
                </div>
              </div>

              <div
                style={{
                  cursor: "pointer",
                  background: Colors.white,
                  padding: "7px 10px 7px 10px",
                  gap: "0.5rem",
                  borderRadius: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => navigate("/AddAstrologer")}
              >
                <img
                  src={Icons.ic_add}
                  style={{ height: "1rem", width: "1rem", objectFit: "cover" }}
                />
                <div className="darkGreyFont16" style={{ color: Colors.black }}>
                  Add Astrologer
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            flex: 1,
            border: Colors.darkBorderColor,
            borderRadius: "8px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: Colors.borderColor,
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: "1",
              padding: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <div style={{ width: "20%" }} className="contentName">
                Registration ID
              </div>
              <div style={{ width: "14%" }} className="contentName">
                Name
              </div>
              <div style={{ width: "18%" }} className="contentName">
                Contact
              </div>
              <div style={{ width: "9%" }} className="contentName">
                Experience
              </div>
              <div style={{ width: "20%" }} className="contentName">
                Expertise
              </div>
              <div style={{ width: "7%" }} className="contentName">
                Gender
              </div>
              <div
                style={{ width: "8%", display: "flex" }}
                className="contentName"
              >
                Action
              </div>
            </div>
          </div>
          <Newregistationlist
            handleScroll={handleScroll}
            commentsContainerRef={commentsContainerRef}
            onReject={onClickHandleReject}
            onAccept={onClickHandleAccept}
            data={registrationListData}
          />
        </div>
        {registrationListData.length <= 0 && <EmptyData />}
      </div>
    </div>
  );
}

export default Registration;
