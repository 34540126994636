import { useState } from "react";
import Colors from "../../../../Constants/Colors";
import "../../../../Constants/CommonStyle.css";
import { useNavigate } from "react-router-dom";
import Header from "../../../../Components/Header/Header";
const OnBoards = () => {
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Sessions / Live sessions"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
      </div>
    </div>
  );
};

export default OnBoards;
