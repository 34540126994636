import React, { useEffect, useState } from 'react';
import Icons from '../Constants/Icons';

const CopyToClipboard = ({ text, children }) => {
  const [showMessage, setShowMessage] = useState(false); 

  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text).then(() => {
      setShowMessage(true); 
      setTimeout(() => setShowMessage(false), 500);
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={handleCopy} style={{ cursor: 'pointer' }}>
        {children} 
      </div>
      {showMessage && (
        <div style={{
          position: 'absolute',
          top: '-30px', // Adjust this value to position it as needed
          left: '10px', // Adjust this value to position it as needed
          backgroundColor: '#12b76a', // Green background for success
          color: 'white',
          padding: '5px 5px',
          borderRadius: '4px',
          zIndex: 1000,
          fontSize:"13px",
          fontWeight:"bold",
          whiteSpace: 'nowrap',
          display: 'flex', // Use flex to align items
          alignItems: 'center' // Center items vertically
        }}>
          <img 
            src={Icons.ic_green_right} // Update with your check icon path
            alt="Check"
            style={{ width: '16px', height: '16px', marginRight: '5px' }} // Adjust size as needed
          />
          Copied!
        </div>
      )}
    </div>
  );
};

export default CopyToClipboard;
