import { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./AstrologerProfile.css";
import "./../../../Constants/CommonStyle.css";
import Icons from "../../../Constants/Icons";
import AstrologerProfileTab from "./CustomeScreen/AstrologerProfileTab";
import TransactionContentTitle from "../../../Components/CustomTransactionScreen/TransactionContentTitle";
import AstrologerSessionTab from "./CustomeScreen/AstrologerSessionTab";
import Header from "../../../Components/Header/Header";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  ApiLiveSessionGetAllLiveCalls,
  APILiveSessionGetLiveGiftsHistory,
  getAstrologerTransactionHistoryList,
  getAstrologerUserDetails,
  getAstrologerWithdrawalHistoryList,
  onApiCallAstrologerActiveInactive,
} from "../../../Core/Apicall";
import CustomWithdrawalTitle from "../../../Components/CustomWithdrawal/CustomWithdrawalTitle";
import StatusButton from "../../../Components/StatusButton/StatusButton";
import LiveSessionList from "../../../Components/SessionList/LiveSessionList";
import GiftSessionList from "../../../Components/SessionList/GiftSessionList";
import UseDebounce from "../../../Components/UseDebounce";
import EmptyData from "../../../Components/EmptyData/EmptyData";

const AstrologerProfile = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { astrologerId } = location.state;

  const [getAstrologerId, setGetAstrologerId] = useState(astrologerId);
  const tabs = [
    "Profile",
    "Sessions",
    "Wallet transactions",
    "Withdrawals",
    "Live",
    "Gift",
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [astrologerProfileData, setAstrologerProfileData] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [withdrawalsHistoryData, setWithdrawalHistoryData] = useState([]);
  const [
    totalTransactionHistoryPageCount,
    setTotalTransactionHistoryPageCount,
  ] = useState(0);
  const [totalWithdrawalHistoryPageCount, setTotalWithdrawalHistoryPageCount] =
    useState(0);
  const sortByItem = ["Date & Time"];
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const commentsSecondContainerRef = useRef(null);
  useEffect(() => {
    dispatch(setLoadingTrue());
    getAstrologerUserDetails({ astrologerId: getAstrologerId })
      .then((response) => {
        setAstrologerProfileData(response.profileData);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  }, []);

  useEffect(() => {
    if (activeTab == 2) {
      onClickTransactionHistoryApi();
    } else if (activeTab == 3) {
      onClickWithdrawalHostoryApi();
    }
  }, [activeTab, pageComments]);

  const onClickWithdrawalHostoryApi = () => {
    let param = {
      offset: 30 * pageComments,
      limit: 30,
    };

    let data = {
      astrologerId: getAstrologerId,
    };
    dispatch(setLoadingTrue());
    getAstrologerWithdrawalHistoryList(param, data)
      .then((response) => {
        setWithdrawalHistoryData((prevData) => [...prevData, ...response.data]);
        setTotalWithdrawalHistoryPageCount(
          Math.ceil(response.totalTransactionsCount / 30)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };
  const onClickTransactionHistoryApi = () => {
    let param = {
      offset: 30 * pageComments,
      limit: 30,
    };

    let data = {
      astrologerId: getAstrologerId,
    };
    dispatch(setLoadingTrue());
    getAstrologerTransactionHistoryList(param, data)
      .then((response) => {
        setDropDownSelectedValue("All");
        setTransactionHistoryData((prevData) => [
          ...prevData,
          ...response.data,
        ]);

        setTotalTransactionHistoryPageCount(
          Math.ceil(response.totalTransactionsCount / 30)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleTabClick = (index) => {
    setPageComments(0);
    setWithdrawalHistoryData([]);
    setTransactionHistoryData([]);
    setActiveTab(index);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (activeTab == 2) {
        if (totalTransactionHistoryPageCount > pageComments + 1) {
          setPageComments(pageComments + 1);
        }
      } else {
        if (totalWithdrawalHistoryPageCount > pageComments + 1) {
          setPageComments(pageComments + 1);
        }
      }
    }
  };

  const sortByDuration = (property) => {
    setDropDownSelectedValue("All");
    if (property === "Date & Time") {
      const sortedData = [...transactionHistoryData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");

      sortedData.sort((a, b) => {
        const dateA = new Date(a.date_and_time);
        const dateB = new Date(b.date_and_time);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      setTransactionHistoryData(sortedData);
    }
  };
  const sortByWithdrawalDuration = (property) => {
    if (property === "Date & Time") {
      const sortedData = [...withdrawalsHistoryData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        const dateA = new Date(a.dateAndTime);
        const dateB = new Date(b.dateAndTime);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      setWithdrawalHistoryData(sortedData);
    }
  };

  const sortByAMount = (property) => {
    const sortedData = [...transactionHistoryData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] - b[property];
      } else {
        return b[property] - a[property];
      }
    });
    setTransactionHistoryData(sortedData);
  };
  const sortByAmountWithdrawal = (property) => {
    const sortedData = [...withdrawalsHistoryData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] - b[property];
      } else {
        return b[property] - a[property];
      }
    });
    setWithdrawalHistoryData(sortedData);
  };

  const onClickActiveInActiveAstrologer = async () => {
    try {
      dispatch(setLoadingTrue());
      let param = {
        astrologerId: astrologerProfileData?.astrologerId,
        type: astrologerProfileData.isActive ? "inActive" : "active",
      };
      let resp = await onApiCallAstrologerActiveInactive(param);
      if (resp.status) {
        getAstrologerUserDetails({ astrologerId: getAstrologerId })
          .then((response) => {
            setAstrologerProfileData(response.profileData);
            dispatch(setLoadingFalse());
          })
          .catch((err) => {
            dispatch(setLoadingFalse());
          });
      }
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const [searchValueGift, setSearchValueGift] = useState("");
  const [sessionListGift, setSessionListDataGift] = useState([]);
  const [sessionPageCount, setTotalSessionPageCount] = useState(0);
  const [pageCommentsGift, setPageCommentsGift] = useState(0);
  const commentsContainerRefGift = useRef(null);
  const debouncedInputSearchValueGift = UseDebounce(searchValueGift, 500);
  useEffect(() => {
    if (activeTab == 5) {
      setPageCommentsGift(0);
      setSessionListDataGift([]);
      const search = debouncedInputSearchValueGift || searchValueGift;
      onApiCallGetSessionList(search,0);
    }
  }, [ activeTab,debouncedInputSearchValueGift]);

  const onApiCallGetSessionList = (searchValueGift, pageCommentsGift) => {
    let param = {
      offset: 30 * pageCommentsGift,
      limit: 30,
      astrologerId: astrologerId,
      search: searchValueGift,
    };
    dispatch(setLoadingTrue());
    APILiveSessionGetLiveGiftsHistory(param)
      .then((response) => {
        setSessionListDataGift((prevData) => [
          ...prevData,
          ...response?.giftsDetails,
        ]);
        setTotalSessionPageCount(Math.ceil(response.totalGiftsCount / 30));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleInputChangeGift = (e) => {
    const searchText = e.target.value;
    setSearchValueGift(searchText);
  };

  const handleScrollGift = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRefGift?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (sessionPageCount > pageCommentsGift + 1) {
        setPageCommentsGift((prevPage) => {
          const nextPage = prevPage + 1;
          onApiCallGetSessionList(searchValueGift, nextPage);
          return nextPage;
        });
      }
    }
  };

  const [searchValueLive, setSearchValueLive] = useState("");
  const [sessionListDataLive, setSessionListDataLive] = useState([]);
  const [pageCommentsLive, setPageCommentsLive] = useState(0);
  const [pageCommentsTotalLive, setPageTotalCommentsLive] = useState(0);
  const commentsContainerRefLive = useRef(null);
  const debouncedInputLive = UseDebounce(searchValueLive, 500);
  useEffect(() => {
    if (activeTab == 4) {
      setPageCommentsLive(0);
      setSessionListDataLive([]);
      const searchQuery = debouncedInputLive || searchValueLive;
      onApiCallGetSessionListLive(searchQuery, 0);
    }
  }, [activeTab, debouncedInputLive]);

  const onApiCallGetSessionListLive = (searchQuery, pageCommentsLive) => {
    const searchStatus = searchQuery || "";
    let param = {
      offset: 30 * pageCommentsLive,
      limit: 30,
      astrologerId: astrologerId,
      search: searchStatus,
    };

    dispatch(setLoadingTrue());
    ApiLiveSessionGetAllLiveCalls(param)
      .then((response) => {
        setSessionListDataLive((prevData) => [
          ...prevData,
          ...response.sessionHistoryDetails,
        ]);
        setPageTotalCommentsLive(Math.ceil(response.totalSessionsCount / 30));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleInputChangeLive = (e) => {
    const searchText = e.target.value;
    setSearchValueLive(searchText);
  };

  const handleScrollLive = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRefLive?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (pageCommentsTotalLive > pageCommentsLive + 1) {
        setPageCommentsLive((prevPage) => {
          const nextPage = prevPage + 1;
          onApiCallGetSessionListLive(searchValueLive, nextPage);
          return nextPage;
        });
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Astrologers / " + astrologerProfileData?.userName} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "stretch",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              flex: 0.45,
              display: "flex",
              alignSelf: "stretch",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1.5rem",
            }}
          >
            <div
              style={{
                borderRadius: "87px",
                backgroundColor: "#25252d",
                width: "5rem",
                height: "5rem",
                overflow: "hidden",
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <img
                style={{
                  width: "5rem",
                  height: "5rem",
                  objectFit: "cover",
                }}
                alt=""
                src={
                  astrologerProfileData?.profileUrl ??
                  Icons.ic_default_user
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <div style={{ wordBreak: "break-all" }} className="fontSize24">
                  {astrologerProfileData?.userName}
                </div>
                <div
                  className="fontSize14"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.25rem",
                    color:
                      astrologerProfileData?.userStatus === "online"
                        ? Colors.darkGreen
                        : astrologerProfileData?.userStatus === "away"
                        ? Colors.darkOrange
                        : Colors.darkRed,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "50%",
                        backgroundColor:
                          astrologerProfileData?.userStatus === "online"
                            ? Colors.darkGreen
                            : astrologerProfileData?.userStatus === "away"
                            ? Colors.darkOrange
                            : Colors.darkRed,
                        filter: "blur(9px)",
                        width: "0.38rem",
                        height: "0.38rem",
                        opacity: "0.5",
                      }}
                    />
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "50%",
                        backgroundColor:
                          astrologerProfileData?.userStatus === "online"
                            ? Colors.darkGreen
                            : astrologerProfileData?.userStatus === "away"
                            ? Colors.darkOrange
                            : Colors.darkRed,
                        width: "0.38rem",
                        height: "0.38rem",
                        marginLeft: "-0.37rem",
                      }}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    {astrologerProfileData?.userStatus}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="fontSize16"
                  style={{ color: Colors.greyColor50 }}
                >
                  {astrologerProfileData?.userRole}
                </div>
                <div
                  className="fontSize16"
                  style={{ color: Colors.greyColor50 }}
                >
                  {astrologerProfileData?.location}
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "1.2rem",
                      objectFit: "contain",
                    }}
                    alt=""
                    src={Icons.ic_white_wallet}
                  />
                  <div
                    className="fontSize16"
                    style={{ textAlign: "center", color: Colors.white }}
                  >
                    {astrologerProfileData?.totalOutStandingAmount.toFixed(2)}
                  </div>
                </div>

                <div
                  style={{
                    cursor: "pointer",
                    marginTop: "10px",
                    // width: "auto",
                  }}
                  onClick={() => onClickActiveInActiveAstrologer()}
                >
                  <StatusButton
                    style={{
                      width: "auto",
                      padding: "10px 20px 10px 20px",
                      backgroundColor: astrologerProfileData?.isActive
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      borderColor: astrologerProfileData?.isActive
                        ? Colors.darkGreen
                        : Colors.darkRed,
                      color: astrologerProfileData?.isActive
                        ? Colors.darkGreen
                        : Colors.darkRed,
                    }}
                  >
                    {astrologerProfileData?.isActive ? "Active" : "Inactive"}
                  </StatusButton>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1rem",
              flex: 0.7,
            }}
          >
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Sessions fees
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                <span>₹{astrologerProfileData?.astrologerCharge ?? 0}</span>
                <span style={{ fontSize: "1rem" }}>/min</span>
              </div>
            </div>
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Avg. session duration
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                ₹{astrologerProfileData?.avgSessionDuration ?? 0}
              </div>
            </div>
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Ratings
              </div>
              <div
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                <img
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                  alt=""
                  src={Icons.ic_star}
                />
                <div
                  className="fontSize24"
                  style={{ marginLeft: "5px", position: "relative" }}
                >
                  {astrologerProfileData?.Rating}
                  {astrologerProfileData?.ReviewCount == undefined
                    ? "(0)"
                    : "(" + astrologerProfileData?.ReviewCount + ")"}
                </div>
              </div>
            </div>

            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Outstanding amount
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                ₹{astrologerProfileData?.totalOutStandingAmount ?? 0}
              </div>
            </div>
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Total earnings
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                ₹{astrologerProfileData?.totalWalletBalance ?? 0}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            gap: "1rem",
            alignSelf: "stretch",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{
                padding: "10px",
                fontWeight: "400",
                color:
                  index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
                borderBottom: index != activeTab ? "" : "1px solid #fff",
                cursor: "pointer",
              }}
              className="fontSize18"
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </div>
          ))}
        </div>

        {activeTab === 0 && (
          <AstrologerProfileTab astrologerProfileData={astrologerProfileData} />
        )}
        {activeTab === 1 && (
          <AstrologerSessionTab
            onClickSession={false}
            astrologerProfileData={astrologerProfileData}
          />
        )}
        {(activeTab === 2 || activeTab == 3) && (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignSelf: "stretch",
            }}
          >
            <div style={{}} className="sectionDivStyle">
              <div
                className="title24FontWeight400WHite"
                style={{
                  flex: "1",
                  position: "relative",
                  alignSelf: "stretch",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {activeTab === 2 ? "Transaction history" : "Withdrawals"}
              </div>
              <div style={{}} className="">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    fontSize: "1rem",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "8px",
                      border: Colors.darkBorderColor,
                      boxSizing: "border-box",
                      width: "8.38rem",
                      display: "flex",
                      zIndex: 1,
                      padding: "0.5rem 0.5rem",
                    }}
                  >
                    <CustomDropdown
                      dropDownLabel={"Sort by"}
                      items={sortByItem}
                      color="blue"
                      onSelect={sortByDuration}
                      customLabelStyle={{
                        fontWeight: "400",
                        color: Colors.greyColor50,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {activeTab == 3 ? (
              <CustomWithdrawalTitle
                handleScroll={handleScroll}
                commentsContainerRef={commentsContainerRef}
                data={withdrawalsHistoryData}
                dateTimeSort={() => sortByWithdrawalDuration("Date & Time")}
                amountSort={() => sortByAmountWithdrawal("totalPaidAmount")}
              />
            ) : (
              <TransactionContentTitle
                handleScroll={handleScroll}
                commentsContainerRef={commentsContainerRef}
                data={transactionHistoryData}
                fromHide={activeTab === 2 ? false : true}
                toHide={activeTab === 2 ? false : true}
                transactionID={"Transaction ID"}
                from={"From"}
                to={"To"}
                dateTimeSort={() => sortByDuration("Date & Time")}
                amountSort={() => sortByAMount("amount")}
                dateTime={"Date & Time"}
                transactionTypeHide={activeTab === 2 ? false : true}
                transactionType={"Transaction for"}
                amount={"Amount"}
                status={"Status"}
              />
            )}
            {/* {activeTab == 3 && withdrawalsHistoryData.length != 0 && (
              <div
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomPagination
                  pageCount={totalWithdrawalHistoryPageCount}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={onClickWithdrawalHostoryApi}
                />
              </div>
            )} */}
            {/* {activeTab == 2 && transactionHistoryData.length != 0 && (
              <div
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomPagination
                  pageCount={totalTransactionHistoryPageCount}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={onClickTransactionHistoryApi}
                />
              </div>
            )} */}
          </div>
        )}

        {activeTab == 4 && (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignSelf: "stretch",
            }}
          >
            <div style={{}} className="sectionDivStyle">
              <div
                className="title24FontWeight400WHite"
                style={{
                  flex: "1",
                  position: "relative",
                  alignSelf: "stretch",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Astrologer Live List
              </div>
              <div style={{}} className="">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    fontSize: "1rem",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "8px",
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                      boxSizing: "border-box",
                      width: "15.75rem",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "0.5rem 0.5rem",
                      gap: "0.5rem",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "1.5rem",
                        height: "1.5rem",
                        objectFit: "contain",
                      }}
                      alt=""
                      src={Icons.ic_search}
                    />
                    <input
                      type="text"
                      className="searchTextInput"
                      placeholder="Search"
                      value={searchValueLive}
                      onChange={handleInputChangeLive}
                    />
                  </div>
                </div>
              </div>
            </div>
            <LiveSessionList
              handleScroll={handleScrollLive}
              commentsContainerRef={commentsContainerRefLive}
              data={sessionListDataLive}
              sessionId={"Session ID"}
              user={"User"}
              astrologer={"Astrologer"}
              dateTime={"Date & Time"}
              sessionType={"Session type"}
              duration={"Duration"}
              fees={"Fees"}
              totalfees={"Total fees"}
              status={"Status"}
            />
          </div>
        )}

        {activeTab == 5 && (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignSelf: "stretch",
            }}
          >
            <div style={{}} className="sectionDivStyle">
              <div
                className="title24FontWeight400WHite"
                style={{
                  flex: "1",
                  position: "relative",
                  alignSelf: "stretch",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Astrologer Gift List
              </div>
              <div style={{}} className="">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    fontSize: "1rem",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "8px",
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                      boxSizing: "border-box",
                      width: "15.75rem",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "0.5rem 0.5rem",
                      gap: "0.5rem",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "1.5rem",
                        height: "1.5rem",
                        objectFit: "contain",
                      }}
                      alt=""
                      src={Icons.ic_search}
                    />
                    <input
                      type="text"
                      className="searchTextInput"
                      placeholder="Search"
                      value={searchValueGift}
                      onChange={handleInputChangeGift}
                    />
                  </div>
                </div>
              </div>
            </div>
            <GiftSessionList
              handleScroll={handleScrollGift}
              commentsContainerRef={commentsContainerRefGift}
              data={sessionListGift}
              sessionId={"Session ID"}
              user={"User"}
              astrologer={"Astrologer"}
              dateTime={"Date & Time"}
              sessionType={"Session type"}
              duration={"Duration"}
              fees={"Fees"}
              totalfees={"Total fees"}
              status={"Status"}
            />
          </div>
        )}
      {activeTab == 5 && sessionListGift.length == 0 && <EmptyData />}
      {activeTab == 4 && sessionListDataLive.length == 0 && <EmptyData />}
      </div>
    </div>
  );
};

export default AstrologerProfile;
