const Images = {
  ic_chat_empty: require("../Assets/Images/ic_chat_empty.png"),
  ic_default_man: require("../Assets/Images/ic_default_man.png"),
  image_default_second_image: require("../Assets/Images/image_default_second_image.png"),
  images_no_data: require("../Assets/Images/images_no_data.png"),
  image_wave: require("../Assets/Images/image_wave.gif"),
  ic_new_audio_image: require("../Assets/Images/ic_new_audio_image.gif"),
  image_wave_effect: require("../Assets/Images/image_wave_effect.gif"),
  rudraksha: require("../Assets/Images/rudraksha.png"),
  image_first: require("../Assets/Images/image_first.jpg"),
  image_second: require("../Assets/Images/image_second.jpg"),
  image_third: require("../Assets/Images/image_third.jpg"),
};
export default Images;
