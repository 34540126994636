import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import StatusButton from "../StatusButton/StatusButton";
import moment from "moment-timezone";
import CopyToClipboard from "../CopyToClipboard";

function CustomWithdrawalRenderItem({
  transactionData,
}) {
 
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        padding: "0.8rem",
        alignSelf: "stretch",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "0.5rem",
          alignSelf: "stretch",
        }}
      >
        <div className="fontSize18Weight400">
          {transactionData?.transactionId}
        </div>
        <CopyToClipboard text={transactionData?.transactionId}>
        <img
          style={{ cursor: "pointer" }}
          className="icon_copy"
          alt=""
          src={Icons.ic_copy}
        />
        </CopyToClipboard>
      </div>
      <div
        style={{
          width: "28%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div className="fontSize18Weight400">
          {moment
            .utc(transactionData?.dateAndTime)
            .local()
            .format("DD-MM-YYYY hh:mm A")}
        </div>
      </div>
      <div
        className="fontSize18Weight400"
        style={{
          width: "28%",
        }}
      >
        {"₹" + transactionData?.totalPaidAmount}
      </div>
      <StatusButton
        style={{
          width: "14%",
          backgroundColor:
          transactionData?.status === "successful" ? Colors.mainGreen01 : Colors.mainRed01,
          borderColor:
          transactionData?.status ==="successful" ? Colors.darkGreen : Colors.darkRed,
          color: transactionData?.status ==="successful"? Colors.darkGreen : Colors.darkRed,
        }}
      >
        {transactionData?.status}
      </StatusButton>
    </div>
  );
}

export default CustomWithdrawalRenderItem;
