import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import Loader from "../../../Components/Loader/Loader";
import Icons from "../../../Constants/Icons";

import "./auth.css";
import Colors from "../../../Constants/Colors";
const ForgotPassword = ({ onLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); 
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };


  const handleLogin = (e) => {
    if (validateForm()) {

    dispatch(setLoadingTrue());
    setTimeout(() => {
    navigate("/SetForgotPassword")
      dispatch(setLoadingFalse());
    }, 3000);
  }
  };

  return (
    <div
      style={{
        // display: "flex",
        // background: Colors.darkBlack,
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",

        display: "flex",
        background: Colors.darkBlack,
        flexDirection: "column",
        alignItems: "center",
              height: "100vh",
              width: "100vw",
           
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 7,
          marginTop: "50px",
          alignItems: "center",
        }}
      >
        <img
          src={Icons.ic_zigzek_logo}
          style={{ objectFit: "contain", height: "1.6rem", width: "1.6rem" }}
        />
        <div
          style={{
            color: "#FFF",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          Zigzek
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          background: Colors.mainDarkColor,
          border: "1px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "15px",
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            padding: "10px 20px 10px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ textAlign: "center", marginBottom: "10px" }}
            className="title_reset_password"
          >
           Forgot password?
          </div>
          <div className="forgot_sub_title">Enter the email address associated with your account and <br/> we’ll send you a link to reset your password.</div>
          <div className="title_password_main">Email</div>
          <div
            style={{
              border: "1px solid rgba(255, 255, 255, 0.1)",
              borderRadius: "30px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="searchTextInput"
              placeholder="Enter email"
              style={{
                width: "100%", 
              }}
            />
          </div>
          {errors.email && <div style={{fontSize:"0.77rem", color: "red" }}>{errors.email}</div>}
          <div
            style={{
              marginTop: "20px",
            }}
            onClick={() => handleLogin()}
            className="reset_password_on_click"
          >
            Send Link
          </div>
        </div>
        <Loader />
      </div>
    </div>
  );
};

export default ForgotPassword;
