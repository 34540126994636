import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import Header from "../../../Components/Header/Header";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  apiCallUsersCheckUserExistsMobileNumber,
  getExpertises,
  addAstrologerManually,
  apiCallUserLanguage,
} from "../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import "react-phone-input-2/lib/bootstrap.css";
import useDownloader from "react-use-downloader";
import Icons from "../../../Constants/Icons";
import { CustomUploadImage } from "../../../Core/CustomUploadImage";

function AddAstrologers() {
  const location = useLocation();
  const { data, dataEdit } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userExpertiesRole, setUserExpertiesRole] = useState([]);
  const fileProductInputRef = useRef(null);
  const { download } = useDownloader();
  const isProfile = useSelector((state) => state.isProfileData);

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    displayname: false,
    email: false,
    mobileCode: false,
    userRole: false,
    mobileNumber: false,
    gender: false,
    experience: false,
    expertise: false,
    productImage: false,
  });

  const [emailExisting, setEmailExisting] = useState(false);
  const [mobileExisting, setMobileExisting] = useState(false);

  const [formData, setFormData] = useState({
    Languages: [],
    firstName: "",
    lastName: "",
    displayname: "",
    email: "",
    mobileCode: "+91",
    mobileNumber: "",
    gender: "",
    experience: "",
    expertise: [],
    userRole: "",
    productImage: "",
  });

  useEffect(() => {
    onApiCallGetExpiertes();
    onApiCallLanguageList();
  }, []);

  const onApiCallLanguageList = () => {
    dispatch(setLoadingTrue());
    apiCallUserLanguage()
      .then((response) => {
        const updatedList = response?.Languages.map((item1) => ({
          ...item1,
          selected: false,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          Languages: updatedList,
        }));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onApiCallExistingMobileNumber = async (item) => {
    let param = {
      Email: formData.email,
      Mobile: formData.mobileNumber,
      countryCode: formData.mobileCode,
      checkType: item,
    };

    try {
      const response = await apiCallUsersCheckUserExistsMobileNumber(param);
      if (item == "Email") {
        setEmailExisting(
          data?.email == formData.email ? false : response?.isEmailExists
        );
      } else {
        setMobileExisting(
          data?.mobileNumber == formData.mobileNumber
            ? false
            : response?.isMobileExists
        );
      }
    } catch (err) {
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallGetExpiertes = () => {
    dispatch(setLoadingTrue());
    getExpertises()
      .then((response) => {
        setUserExpertiesRole(
          response.Expertises.map((expertise) => expertise?.ExpertiseName)
        );
        const updatedList = response?.Expertises.map((expertise) => ({
          ...expertise,
          selected: data?.expertise.some(
            (item) => item.expertiseId === expertise._id
          ),
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          expertise: updatedList,
        }));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleLanguageSelected = (index) => {
    const updatedData = formData?.Languages.map((item, i) => {
      if (i === index) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      Languages: updatedData,
    }));
  };

  const handleItemClick = (index) => {
    const updatedServiceData = formData.expertise.map((item, i) => {
      if (i === index) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      expertise: updatedServiceData,
    }));
  };

  const handleInputChange = (e, fieldName) => {
    let value = e.target.value;

    if (fieldName == "experience" || fieldName == "mobileNumber") {
      if (!/^\d*$/.test(value)) {
        return; // Ignore non-numeric input
      }
    }
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleDownload = async (url, filename) => {
    try {
      const fileUrl = url;
      download(fileUrl, filename);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const handleImageChangeProduct = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          productImage: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
    const formData = new FormData();
    formData.append("astrologerImages", event.target.files[0]);
    formData.append("astrologerId", 123456);
    formData.append("imageType", "uploadAstrologerProfile");
    try {
      const data = await CustomUploadImage(formData, isProfile.token);
      if (data && data.status === true) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          productImage: data.location,
        }));
      } else {
      }
    } catch (error) {}
  };

  const validateForm = () => {
    const errors = {
      firstName: formData.firstName == "",
      email: !/\S+@\S+\.\S+/.test(formData.email),
      lastName: formData.lastName == "",
      displayname: formData.displayname == "",
      mobileNumber: formData.mobileNumber.length != 10,
      gender: formData.gender == "",
      experience: formData.experience == "",
      userRole: formData.userRole == "",
      productImage: formData.productImage == "",
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const onClickSave = () => {
    if (validateForm()) {
      if (!emailExisting && !mobileExisting) {
        let selectedLanguages = formData?.Languages.filter(
          (item) => item.selected
        );
        let newArrayLanguage = selectedLanguages.map((item) => {
          return {
            id: item._id,
            language: item.LanguageName,
          };
        });
        if (newArrayLanguage.length == 0) {
          alert("Please Select Language");
          return;
        }
        let selectedExperties = formData.expertise.filter(
          (item) => item.selected
        );
        let newArray = selectedExperties.map((item) => {
          return {
            expertiseId: item._id,
            expertise: item.ExpertiseName,
          };
        });
        if (newArray.length == 0) {
          alert("Please Select expertise's");
          return;
        }
        let param = {
          firstname: formData.firstName,
          lastname: formData.lastName,
          Email: formData.email,
          Mobile: formData.mobileNumber,
          gender: formData.gender,
          experience: formData.experience,
          expertise: newArray,
          userRole: formData.userRole,
          countryCode: formData.mobileCode,
          display_name: formData.displayname,
          display_name: formData.displayname,
          Languages: newArrayLanguage,
          profileUrl: formData.productImage,
          
        };
        dispatch(setLoadingTrue());
        addAstrologerManually(param)
          .then(() => {
            const event = new CustomEvent("subTabEventListner", {
              detail: "/Approveded",
            });
            window.dispatchEvent(event);
            navigate("/Approveded");
            dispatch(setLoadingFalse());
          })
          .catch((err) => {
            dispatch(setLoadingFalse());
          });
      }
    }
  };

  const onClickDiscardAstrologer = () => {
    // navigate("/Newregistration")
    const event = new CustomEvent("subTabEventListner", {
      detail: "/Newregistration",
    });
    window.dispatchEvent(event);
    navigate("/Newregistration");
    dispatch(setLoadingFalse());
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={`Astrologers / Add Astrologer`} />
      <div
        style={{
          display: "flex",
          borderRadius: "10px",
          padding: "10px",
          alignItems: "center",
          background: Colors.mainDarkColor,
          justifyContent: "space-between",
        }}
      >
        <div className="fontSize24" style={{ fontWeight: 400 }}>
          {`${dataEdit ? "Edit" : "Add"} Astrologer`}
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <div
            onClick={() => onClickDiscardAstrologer()}
            className="fontSize16"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 500,
              border: "1px solid #ffffff10",
              borderRadius: "50px",
              padding: "4px 10px 4px 10px",
              cursor: "pointer",
            }}
          >
            Discard
          </div>
          <div
            className="fontSize16"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 500,
              border: "1px solid #ffffff10",
              background: "#FFF",
              color: "#1E1E25",
              borderRadius: "50px",
              padding: "4px 10px 4px 10px",
            }}
            onClick={() => onClickSave()}
          >
            Save
          </div>
        </div>
      </div>
      <div
        style={{
          maxHeight: window.innerHeight - 150,
          paddingBottom: "20px",
          marginTop: "20px",
        }}
        className="scrollable-container"
      >
        <div className="add-inventory-container">
          <div style={{}}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                fontSize: "1rem",
              }}
            >
              <div
                style={{ marginTop: "20px", background: "#25252D" }}
                className="add-inventory-main-div-section"
              >
                <div className="">
                  <div style={{}} className="fontSize16">
                    First name
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.firstName
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      maxLength={30}
                      className="modal-dropdown"
                      type="text"
                      placeholder="eg. Rudraksha"
                      value={formData.firstName}
                      onChange={(e) => handleInputChange(e, "firstName")}
                    />
                  </div>
                </div>
                <div style={{}} className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Last name
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.lastName
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      maxLength={30}
                      className="modal-dropdown"
                      type="text"
                      placeholder="eg. Maynk"
                      value={formData.lastName}
                      onChange={(e) => handleInputChange(e, "lastName")}
                    />
                  </div>
                </div>
                <div style={{}} className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Email
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.email
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      className="modal-dropdown"
                      type="text"
                      placeholder="eg. maynk@gmail.com"
                      value={formData.email}
                      onChange={(e) => handleInputChange(e, "email")}
                      onBlur={() => onApiCallExistingMobileNumber("Email")}
                    />
                  </div>
                </div>
                {emailExisting && (
                  <div
                    style={{
                      display: "flex",
                      width: "40vh",
                      marginLeft: 5,
                      color: "#FF5733",
                      fontSize: "13px",
                    }}
                  >
                    This email has already registered
                  </div>
                )}

                <div style={{}} className="modal-second-div-title">
                  <div className="fontSize16">Country code</div>

                  <div style={{ display: "flex", gap: "0.7rem" }}>
                    <div
                      style={{ zIndex: 1, flex: 0.1 }}
                      className="modal-from-to-div"
                    >
                      <CustomDropdown
                        customLabelStyle={{ fontSize: "0.7rem" }}
                        dropDownLabel={"+91"}
                        items={["+91", "+97", "+99"]}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            mobileCode: value,
                          }));
                        }}
                      />
                    </div>
                    <input
                      style={{
                        flex: 1,
                        border: formErrors.mobileNumber
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-second-dropdown"
                      type="text"
                      maxLength={10}
                      placeholder="Enter 10 digit number"
                      value={formData.mobileNumber}
                      onChange={(e) => handleInputChange(e, "mobileNumber")}
                      onBlur={() => onApiCallExistingMobileNumber("Mobile")}
                    />
                  </div>
                  {mobileExisting && (
                    <div
                      style={{
                        display: "flex",
                        width: "40vh",
                        marginLeft: 5,
                        color: "#FF5733",
                        fontSize: "13px",
                      }}
                    >
                      This mobile number has already registered
                    </div>
                  )}
                </div>

                <div className="modal-second-div-title">
                  <div style={{}} className="fontSize16">
                    Profile images
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      alignSelf: "stretch",
                      borderRadius: "16px",
                      border: formErrors.productImage
                        ? "1px dashed red"
                        : "1px dashed rgba(255, 255, 255, 0.2)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "0.7rem 0.7rem",
                      gap: "0.5rem",
                      color: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileProductInputRef}
                      onChange={handleImageChangeProduct}
                    />
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          position: "relative",
                          width: formData.productImage == "" ? "7rem" : "10rem",
                          height:
                            formData.productImage == "" ? "7rem" : "10rem",
                          cursor: "pointer",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        alt=""
                        src={
                          formData.productImage
                            ? formData.productImage
                            : Icons.ic_upload_photo
                        }
                        onClick={() => {
                          fileProductInputRef.current.click();
                        }}
                      />
                      {formData.productImage != "" && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "1px",
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            gap: "15px",
                          }}
                        >
                          <img
                            style={{
                              height: "20px",
                              width: "35px",
                              cursor: "pointer",
                              objectFit: "cover",
                            }}
                            onClick={() =>
                              handleDownload(
                                formData.productImage,
                                "profile-pic.png"
                              )
                            }
                            alt=""
                            src={Icons.ic_download}
                          />
                        </div>
                      )}
                    </div>
                    {formData.productImage == "" && (
                      <div
                        style={{ color: "#FFFFFF80", textAlign: "center" }}
                        className="fontSize16"
                      >
                        Upload image{<br />}
                        <span style={{ fontSize: "0.7rem" }}>
                          (120px x 120px)
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{ background: "#25252D" }}
                className="add-inventory-main-div-section"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fontSize24" style={{ fontWeight: 700 }}>
                    About astrologer
                  </div>
                </div>
                <div className="">
                  <div style={{ marginTop: 5 }} className="fontSize16">
                    Display Name
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      border: formErrors.displayname
                        ? "1px solid red"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    }}
                    className="modal-coupon-code-type"
                  >
                    <input
                      maxLength={30}
                      className="modal-dropdown"
                      type="text"
                      placeholder="Display Name"
                      value={formData.displayname}
                      onChange={(e) => handleInputChange(e, "displayname")}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", gap: "20px", zIndex: 1 }}>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Astrologer role
                    </div>
                    <div
                      style={{
                        zIndex: 1,
                        padding: "2.5px 10px 2.5px 10px",
                        border: formErrors.userRole
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-from-to-div"
                    >
                      <CustomDropdown
                        customLabelStyle={{
                          color: formData?.userRole ? "#FFF" : "#FFFFFF33",
                          fontSize: "0.75rem",
                          fontWeight: "normal",
                          // fontWeight:0
                        }}
                        dropDownLabel={
                          formData?.userRole == ""
                            ? "Select Astrologer Role"
                            : formData?.userRole
                        }
                        items={userExpertiesRole}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            userRole: value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Gender
                    </div>
                    <div
                      style={{
                        zIndex: 1,
                        padding: "2.5px 10px 2.5px 10px",

                        border: formErrors.gender
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-from-to-div"
                    >
                      <CustomDropdown
                        customLabelStyle={{
                          color: formData?.gender ? "#FFF" : "#FFFFFF33",
                          fontSize: "0.75rem",
                          fontWeight: "normal",
                        }}
                        dropDownLabel={
                          formData?.gender == ""
                            ? "Select gender"
                            : formData?.gender
                        }
                        items={["Male", "Female"]}
                        onSelect={(value) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            gender: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Experience
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        border: formErrors.experience
                          ? "1px solid red"
                          : "1px solid rgba(255, 255, 255, 0.2)",
                      }}
                      className="modal-coupon-code-type"
                    >
                      <input
                        maxLength={2}
                        className="modal-dropdown"
                        type="text"
                        placeholder="Experience in years"
                        value={formData.experience}
                        onChange={(e) => handleInputChange(e, "experience")}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                >
                  <div style={{}} className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Languages
                    </div>

                    <div
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {formData?.Languages.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => handleLanguageSelected(index)}
                            style={{
                              background: item.selected
                                ? "#FFF"
                                : "transparent",
                              margin: "5px",
                              cursor: "pointer",
                              border: "1px solid #ffffff10",
                              borderRadius: "15px",
                              padding: "7px 10px 7px 10px",
                              color: item.selected && "#000",
                            }}
                            className="expertice-list-font"
                          >
                            {item?.LanguageName}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", marginTop: "10px", gap: "20px" }}
                >
                  <div style={{}} className="modal-second-div-title">
                    <div style={{}} className="fontSize16">
                      Expertise
                    </div>

                    <div
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {formData.expertise.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => handleItemClick(index)}
                            style={{
                              background: item.selected
                                ? "#FFF"
                                : "transparent",
                              margin: "5px",
                              cursor: "pointer",
                              border: "1px solid #ffffff10",
                              borderRadius: "15px",
                              padding: "7px 10px 7px 10px",
                              color: item.selected && "#000",
                            }}
                            className="expertice-list-font"
                          >
                            {item.ExpertiseName}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAstrologers;
