import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import TransactionHistoryList from "./TransactionHistoryList";
import EmptyData from "../EmptyData/EmptyData";

function TransactionContentTitle({
  transactionID,
  from,
  to,
  dateTime,
  transactionType,
  amount,
  status,
  data,
  dateTimeSort,
  amountSort,
  fromHide,
  toHide,
  transactionTypeHide,
  handleScroll,
  commentsContainerRef,
}) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        flex: 1,
        borderRadius: "8px",
        border: data.length == 0 ? "" : Colors.darkBorderColor,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          flex: "1",
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.8rem",
        }}
      >
        <div style={{ width: "20%" }} className="contentName">
          {transactionID}
        </div>
        {!fromHide && (
          <div style={{ width: "17%" }} className="contentName">
            {from}
          </div>
        )}
        {!toHide && (
          <div style={{ width: "17%" }} className="contentName">
            {to}
          </div>
        )}
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            width: "12%",
          }}
          onClick={dateTimeSort}
          className="contentName"
        >
          {dateTime}
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        {!transactionTypeHide && (
          <div style={{ width: "12%" }} className="contentName">
            {transactionType}
          </div>
        )}
        <div
          onClick={amountSort}
          style={{
            width: "10%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {amount}
          </div>
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div style={{ width: "10%" }} className="contentName">
          {status}
        </div>
        <div style={{ width: "3px" }}></div>
      </div>
      {data.length == 0 ? (
        <EmptyData />
      ) : (
        <div
          style={{ maxHeight: "500px" }}
          ref={commentsContainerRef}
          onScroll={handleScroll}
          className="scrollable-container"
        >
          {data.map((transaction, index) => (
            <TransactionHistoryList
              transactionData={transaction}
              key={index}
              name={transaction.name}
              transactionId={transaction.transactionId}
              amount={transaction.amount}
              dateTime={transaction.dateTime}
              transaction_for={transaction.transaction_for}
              status={transaction.status}
              fromHide={fromHide}
              toHide={toHide}
              transactionTypeHide={transactionTypeHide}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default TransactionContentTitle;
