import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import Header from "../../../Components/Header/Header";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Icons from "../../../Constants/Icons";
import CustomOrderListTitle from "../../../Components/CustomOrderListScreen/CustomOrderListTitle";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getApiAllOrderList,
  postChangeOrderStatus,
} from "../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import UseDebounce from "../../../Components/UseDebounce";

function OrderShop() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orderListData, setOrderListData] = useState([]);
  const [orderListAllData, setOrderListAllData] = useState([]);
  const [totalNewOrder, setTotalNewOrder] = useState(0);
  const [dispatchOrder, setDispatchOrder] = useState(0);
  const [deliveredOrder, setDeliverdOrder] = useState(0);
  const [totalPaginationOrder, setTotalPaginationOrder] = useState(0);
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  const statusDropdownItem = [
    "All",
    "New",
    "Dispatched",
    "Delivered",
    "Return",
  ];
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchValue, setSearchValue] = useState("");

  const debouncedInput = UseDebounce(searchValue, 500);


  const handleSelect = (status) => {
    setDropDownSelectedValue(status);
    if (status === "All") {
      setOrderListData(orderListAllData); 
    } else {
      const filteredData = orderListAllData.filter(
        (filData) => filData.orderStatus === status
      );
      setOrderListData(filteredData);
    }
  };

  useEffect(() => {
    setPageComments(0);
    setOrderListData([])
    setOrderListAllData([])
    const searchQuery = debouncedInput || searchValue;
    onClickUserMainListApiCall(searchQuery, 0);
  }, [debouncedInput]);
  const onClickUserMainListApiCall = (search, pageComments) => {
    const searchStatus = search || "";
    let param = {
      offset: 30 * pageComments,
      limit: 30,
      search: searchStatus,
    };
    try {
      dispatch(setLoadingTrue());
      getApiAllOrderList(param).then((response) => {
        setDropDownSelectedValue("All");
        setTotalPaginationOrder(Math.ceil(response.totalOrderCount / 30));
        setTotalNewOrder(response.neworder);
        setDispatchOrder(response.dispatchedorder);
        setDeliverdOrder(response.deliveredorder);
        setOrderListData((prevData) => [...prevData, ...response.orderlist]);
        setOrderListAllData((prevData) => [...prevData, ...response.orderlist]);
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalPaginationOrder > pageComments + 1) {
        setPageComments((prevPage) => {
          const nextPage = prevPage + 1;
          onClickUserMainListApiCall(searchValue, nextPage);
          return nextPage;
        });
      }
    }
  };

  const orderStatusChange = async (status, item) => {
    let statusString;
    if (status == "Dispatched") {
      statusString = "dispatched";
    } else if (status == "Delivered") {
      statusString = "delivered";
    } else {
      statusString = "canceled";
    }

    let param = {
      orderId: item.orderId,
      type: statusString, // "dispatched", "delivered", "canceled"
    };
    try {
      dispatch(setLoadingTrue());
      await postChangeOrderStatus(param);

      const updatedOrderListData = orderListData?.map((data) => {
        if (data.orderId === item.orderId) {
          return { ...data, orderStatus: status };
        }
        return data;
      });
      setOrderListData(updatedOrderListData);
      setOrderListAllData(updatedOrderListData);
      dispatch(setLoadingFalse());
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };


  const handleInputChange = (e) => {
    setDropDownSelectedValue("All");
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const sortByHeader = (property) => {
    const sortedData = [...orderListData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });
    setOrderListData(sortedData);
  };
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Zigzek shop / Orders"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Order overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">New orders</div>
                <div className="fontSize24">{totalNewOrder}</div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Dispatched orders</div>
                <div
                  style={{ color: Colors.darkOrange }}
                  className="fontSize24"
                >
                  {dispatchOrder}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Delivered orders</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {deliveredOrder}
                </div>
              </div>
              {/* <div className="total-astrologers-parent">
                <div className="fontSize16">Return orders</div>
                <div style={{ color: Colors.darkRed }} className="fontSize24">
                  {totalReturnsOrders}
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="sectionDivStyle">
          <div
            className="title24FontWeight400WHite"
            style={{
              flex: "1",
              position: "relative",
              alignSelf: "stretch",
              alignItems: "center",
              display: "flex",
            }}
          >
            Order list
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              fontSize: "1rem",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "9.38rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={dropdownSelected}
                items={statusDropdownItem}
                color="blue"
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: "400",
                  color: Colors.greyColor50,
                }}
              />
            </div>

            <div
              style={{
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                boxSizing: "border-box",
                width: "15.75rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0.5rem 0.5rem",
                gap: "0.5rem",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "1.5rem",
                  height: "1.5rem",
                  objectFit: "contain",
                }}
                alt=""
                src={Icons.ic_search}
              />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <CustomOrderListTitle
          data={orderListData}
          commentsContainerRef={commentsContainerRef}
          handleScroll={handleScroll}
          productInformationShow={true}
          orderStatusChange={(state, item) => orderStatusChange(state, item)}
          amountSort={() => sortByHeader("amount")}
        />
        {orderListData?.length <= 0 && <EmptyData />}
      </div>
    </div>
  );
}

export default OrderShop;
