import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import Loader from "../../../Components/Loader/Loader";
import Icons from "../../../Constants/Icons";

import "./auth.css";
import Colors from "../../../Constants/Colors";
const SetForgotPassword = ({}) => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [showRePassword, setShowRePassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!newPassword) {
      errors.newPassword = "Password is required";
      isValid = false;
    }

    if (newPassword !== reEnterPassword) {
      errors.reEnterPassword =
        "New Password and Re-enter Password do not match";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleLogin = (e) => {
    if (validateForm()) {
      dispatch(setLoadingTrue());
      setTimeout(() => {
        navigate("/");
        dispatch(setLoadingFalse());
      }, 3000);
    }
  };

  return (
    <div
      style={{
        // display: "flex",
        // background: Colors.darkBlack,
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
        display: "flex",
        background: Colors.darkBlack,
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 7,
          marginTop: "50px",
          alignItems: "center",
        }}
      >
        <img
          src={Icons.ic_zigzek_logo}
          style={{ objectFit: "contain", height: "1.6rem", width: "1.6rem" }}
        />
        <div
          style={{
            color: "#FFF",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          Zigzek
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          background: Colors.mainDarkColor,
          border: "1px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "15px",
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            padding: "10px 20px 10px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ textAlign: "center", marginBottom: "10px" }}
            className="title_reset_password"
          >
            Set new password
          </div>

          <div className="title_password_main">New password</div>
          <div
            style={{
              border: "1px solid rgba(255, 255, 255, 0.1)",
              borderRadius: "30px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="searchTextInput"
              placeholder="Enter password"
              style={{
                width: "100%",
              }}
            />
            <img
              onClick={() => setShowNewPassword(!showNewPassword)}
              src={showNewPassword ? Icons.show_password : Icons.hide_password}
              style={{ cursor: "pointer", height: "1rem", width: "1rem" }}
            />
          </div>

          {errors.newPassword && (
            <div style={{ fontSize: "0.77rem", color: "red" }}>
              {errors.newPassword}
            </div>
          )}

          <div className="title_password_main">Re-enter new password</div>
          <div
            style={{
              border: "1px solid rgba(255, 255, 255, 0.1)",
              borderRadius: "30px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              type={showRePassword ? "text" : "password"}
              value={reEnterPassword}
              onChange={(e) => setReEnterPassword(e.target.value)}
              className="searchTextInput"
              placeholder="Enter password"
              style={{
                width: "100%",
              }}
            />
            <img
              onClick={() => setShowRePassword(!showRePassword)}
              src={showRePassword ? Icons.show_password : Icons.hide_password}
              style={{ cursor: "pointer", height: "1rem", width: "1rem" }}
            />
          </div>
          {errors.reEnterPassword && (
            <div style={{ fontSize: "0.77rem", color: "red" }}>
              {errors.reEnterPassword}
            </div>
          )}
          <div
            style={{
              marginTop: "20px",
            }}
            onClick={() => handleLogin()}
            className="reset_password_on_click"
          >
            Set new password
          </div>
        </div>
        <Loader />
      </div>
    </div>
  );
};

export default SetForgotPassword;
