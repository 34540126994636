import React, { useEffect, useRef, useState } from "react";
import "./CustomDropDownStyle.css";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import Modal from "react-modal";
import CustomPagination from "../CustomPagination/CustomPagination";
import EmptyData from "../EmptyData/EmptyData";

const customStyles = {
  overlay: {
    zIndex: 1111111,
    backgroundColor: "rgba(0, 0, 0, 0.3)", // Transparent background
  },
  content: {
    padding: 0,
    margin: 0,
    maxWidth: "100%",
    width: "auto",
    border: "none",
    background: "transparent", // Transparent background
    display: "flex", // Use flexbox for centering
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
  },
};

function CustomDropdownPagination({
  items,
  onSelect,
  searchTextGet,
  customLabelStyle,
  searchText,
  transactionCount,
  selectedAstrologer,
  onClickPaginationApi,
  handleScroll,
  commentsContainerRef,
}) {
  const [isOpenModal, setIsOpen] = useState(false);

  const handleItemClick = (item) => {
    onSelect(item);
    setIsOpen(false);
    // onSelect(item);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    searchTextGet(value);
  };

  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenModal]);
  const allItemsEnabled = items.every((item) => item.isEnabled === true);
  const showEmptyData = items.length === 0 || allItemsEnabled;
  return (
    <div
      // onMouseLeave={() => handleMouseLeave()}
      // onClick={()=>setIsOpen(true)}
      className="custom-dropdown"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 26,
          cursor: "pointer",
        }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div
          style={{
            ...customLabelStyle,
            color:selectedAstrologer == "Select Astrologer" ?  "#818181" :"#FFF",
            fontWeight: "400",
          }}
          className="custom-dropdown2"
        >
          {selectedAstrologer == "Select Astrologer"
            ? selectedAstrologer
            : selectedAstrologer.astrologerName}
        </div>
        <div style={{ display: "flex" }}>
          <img className="downArrowIcon" alt="" src={Icons.ic_down} />
        </div>
      </div>
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isOpenModal}
        onRequestClose={() => {
          document.body.style.overflow = "unset";
          setIsOpen(false);
        }}
      >
        <div
          style={{
            background: Colors.mainDarkColor,
            borderRadius: "10px",
            height: "80%",
            width: "40%",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <img
            onClick={() => setIsOpen(false)}
            style={{
              cursor: "pointer",
              float: "right",
              marginBottom: "15px",
              marginTop: "15px",
            }}
            src={Icons.ic_close}
            alt="ic_close"
            className="modal-ic_close"
          />

          <div style={{ width: "100%", boxSizing: "border-box" }}>
            <div>
              <input
                style={{
                  background: "none",
                  border: "none",
                  border: "1px solid #818181",
                  padding: "0px 10px",
                  boxSizing: "border-box",
                  borderRadius: "100px",
                  width: "100%",
                  height: "40px",
                  color: "white",
                  marginTop: "5px",
                  outline: "none",
                }}
                placeholder="Search Here..."
                type="text"
                value={searchText}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div
              onScroll={handleScroll}
              ref={commentsContainerRef}
              className="pagination-scrollbar-width-zero"
              style={{
                marginTop: "10px",
                width: "auto",
                maxHeight: "21rem",
                minHeight: "21rem",
                alignItems: "center",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {showEmptyData && <EmptyData />}
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
                className="dropdown-menu-pagination"
              >
                {items.map((item, index) => {
                  return (
                    <li
                      style={{}}
                      key={item.astrologerId}
                      onClick={() => handleItemClick(item)}
                    >
                      {item.isEnabled == false && (
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            padding: "8px",
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            src={item.astrologerImage}
                            alt={`${item.astrologerName}`}
                          />
                          <div style={{ marginLeft: 10 }}>
                            <div style={{ fontWeight: "bold" }}>
                              {item.astrologerName}
                            </div>
                            <div style={{ color: "#818181" }}>
                              {item.astrologerRole}
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* {items.length != 0 && (
              <div
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomPagination
                  pageCount={transactionCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  onPageChange={onClickPaginationApi}
                />
              </div>
            )} */}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CustomDropdownPagination;
