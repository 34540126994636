import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import EmptyData from "../EmptyData/EmptyData";
import CustomWithdrawalRenderItem from "./CustomWithdrawalRenderItem";

function CustomWithdrawalTitle({
 
  data,
  dateTimeSort,
  amountSort,
  handleScroll,
         commentsContainerRef
 
}) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        flex: 1,
        borderRadius: "8px",
        border: data.length == 0 ? "" : Colors.darkBorderColor,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          flex: 1,
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0.8rem",
        }}
      >
        <div style={{ width: "30%" }} className="contentName">
          {"Transaction ID"}
        </div>
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            width: "28%",
          }}
          onClick={dateTimeSort}
          className="contentName"
        >
          {"Date & Time"}
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div
          onClick={amountSort}
          style={{
            width: "28%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {"Amount"}
          </div>
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div style={{ width: "14%" }} className="contentName">
          {"Status"}
        </div>
        <div style={{ width: "0px" }}></div>
      </div>
      {data.length == 0 ? (
        <EmptyData />
      ) : (
        <div style={{ maxHeight: "500px" }}  ref={commentsContainerRef} onScroll={handleScroll} className="scrollable-container">
          {data.map((transaction, index) => (
            <CustomWithdrawalRenderItem
              transactionData={transaction}
              key={index}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomWithdrawalTitle;
