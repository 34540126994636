import React, { useState, useCallback, useEffect, useRef } from "react";
import Icons from "../../../Constants/Icons";
import Colors from "../../../Constants/Colors";
import { CustomUploadExcelFile } from "../../../Core/CustomUploadExcelFile";
import { useDispatch, useSelector } from "react-redux";
import { postApiCallTransactionAstrologer } from "../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import CustomDropdownPagination from "../../../Components/DropDown/CustomDropdownPagination";

const AddTransactionForm = ({
  onAddTransaction,
  onFileInputChange,
  closeModal,
}) => {
  const isProfile = useSelector((state) => state.isProfileData);
  const transactionsEndRef = useRef(null);
  const initialTransaction = {
    selectedAstrologer: "",
    transactionId: "",
    amount: "",
    note: "",
  };

  const [checked, setChecked] = useState(false);
  const [csvFileLink, setCSVFileLink] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileSizeMB, setFileSizeMB] = useState(null);
  const [transactionErrors, setTransactionErrors] = useState([]);
  const dispatch = useDispatch();
  const handleChange = useCallback((newChecked) => {
    setChecked(newChecked);
  }, []);

  const [transactions, setTransactions] = useState([initialTransaction]);
  const [astrologerList, setAstrologerList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [transactionCount, setAddNewTransactionCount] = useState(0);

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const fileInputRef = React.createRef();
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, [transactions]);

  // useEffect(() => {
  //   scrollToBottom(); // Scroll to bottom on initial render
  // }, [transactions]);

  const scrollToBottom = () => {
    transactionsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    onApiCallAstrologerList();
  }, [searchText, pageComments]);
  const onApiCallAstrologerList = (selectedPage) => {
    try {
      // dispatch(setLoadingTrue());
      const param = {
        offset: 30 * pageComments,
        limit: 30,
        astrologerName: searchText,
      };
      postApiCallTransactionAstrologer(param).then((response) => {
        setAddNewTransactionCount(Math.ceil(response.astrologersCount / 30));

        let isEnabledAdd = response?.astrologers.map((item) => {
          const isAstrologerSelected = transactions.some(
            (transaction) =>
              transaction.selectedAstrologer &&
              transaction.selectedAstrologer.astrologerId === item.astrologerId
          );
          return {
            ...item,
            isEnabled: isAstrologerSelected,
          };
        });
        if (searchText != "") {
          setAstrologerList(isEnabledAdd);
        } else {
          setAstrologerList((prevData) => [...prevData, ...isEnabledAdd]);
        }

        // setAstrologerList(isEnabledAdd);
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const simulateUploadProgress = (fileSizeInMB) => {
    const totalProgress = 100;
    const uploadSpeedMBps = 1;
    const totalUploadTime = (fileSizeInMB / uploadSpeedMBps) * 1000;
    const progressIncrement = totalProgress / (totalUploadTime / 100);

    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        const newProgress = prevProgress + progressIncrement;
        if (newProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newProgress;
      });
    }, 100);
  };
  const handleFileChange = async (event) => {
    const fileInput = event.target;

    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      onFileInputChange(file);
      const fileSizeInMB = file.size / (1024 * 1024);
      setFileSizeMB(fileSizeInMB.toFixed(2));
      const reader = new FileReader();
      reader.readAsText(file);
      simulateUploadProgress(fileSizeInMB);

      const formData = new FormData();
      formData.append("transactionFile", file);
      formData.append("astrologerId", 123456);
      formData.append("transactionId", "123456");
      try {
        const data = await CustomUploadExcelFile(formData, isProfile.token);
        setCSVFileLink(data.location);
      } catch (error) {
        console.error("Upload error:", error);
      }
    }
  };

  const handleAddOption = (index) => {
    const currentTransactions = [...transactions];
    currentTransactions.push({
      selectedAstrologer: "",
      transactionId: "",
      amount: "",
      note: "",
    });
    setTransactions(currentTransactions);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const handleAddTransaction = () => {
    const errors = transactions.map((transaction) => ({
      selectedAstrologer: !transaction.selectedAstrologer,
      transactionId: !transaction.transactionId,
      amount: !transaction.amount,
    }));
    setTransactionErrors(errors);

    const isComplete = errors.every(
      (error) =>
        !error.selectedAstrologer && !error.transactionId && !error.amount
    );

    if (!isComplete) {
      return;
    }

    const updatedListFinalTransactions = transactions.map((transaction) => ({
      astrologerId: transaction?.selectedAstrologer.astrologerId,
      transactionId: transaction?.transactionId,
      amount: JSON.parse(transaction?.amount),
      note: transaction?.note,
      fileURL: csvFileLink,
    }));

    onAddTransaction(updatedListFinalTransactions);

    setChecked(false);
    setCSVFileLink(null);
    setTransactions([initialTransaction]);
  };

  const convertArrayOfObjectsToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const csv = data.map((row) => Object.values(row).join(",")).join("\n");
    return `${header}\n${csv}`;
  };

  const downloadCSVFile = () => {
    const updatedListFinalTransactions = transactions.map((transaction) => ({
      astrologerName: transaction?.selectedAstrologer?.astrologerName,
      transactionId: transaction?.transactionId,
      amount: transaction?.amount,
      note: transaction?.note,
      fileURL: csvFileLink,
    }));

    const csvContent = convertArrayOfObjectsToCSV(updatedListFinalTransactions);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "astrologerTransaction.csv";
    link.click();
  };

  const onClickDeleteTransaction = (itemTransaction, index) => {
    const updatedTransactions = transactions.filter((_, i) => i !== index);
    setTransactions(updatedTransactions);

    const updatedAstrologerList = astrologerList.map((astrologer) =>
      astrologer.astrologerId ===
      itemTransaction?.selectedAstrologer?.astrologerId
        ? { ...astrologer, isEnabled: false }
        : { ...astrologer }
    );

    setAstrologerList(updatedAstrologerList);
  };

  const handleInputChange = (e, index) => {
    const updatedTransactions = [...transactions];
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, "");
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join("");
    }
    updatedTransactions[index].amount = value;
    setTransactions(updatedTransactions);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (transactionCount > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  const handleFileRemove = () => {
    setUploadProgress(0);
    setFileSizeMB(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  const isAnyFieldEmpty = transactions.some(
    (transaction) =>
      !transaction?.selectedAstrologer != "" ||
      !transaction?.transactionId != "" ||
      !transaction?.amount != ""
  );

  return (
    <div style={{ background: "#393942" }}>
      <form style={{}} Submit={handleAddTransaction}>
        <div className="title24FontWeight400WHite">Add new transactions</div>
        <img
          onClick={closeModal}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "15px",
            right: "20px",
          }}
          src={Icons.ic_close}
          alt="ic_close"
          className="modal-ic_close"
        />
        <div
          className="pagination-scrollbar-width"
          style={{
            maxHeight: "160px",
            alignItems: "center",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {transactions.map((transaction, index) => (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                gap: "10px",
                marginBottom: "10px",
              }}
              className="fontSize16 marker_inupt"
              key={index}
            >
              <label style={{ width: "25%" }}>
                Astrologer *
                <div
                  style={{
                    zIndex: 9,
                    border: `1px solid ${
                      transactionErrors[index]?.selectedAstrologer
                        ? "red"
                        : "#818181"
                    }`,
                    padding: "0px 10px",
                    borderRadius: "100px",
                    display: "flex",
                    alignItems: "center",
                    width: "85%",
                    height: "40px",
                    marginTop: "5px",
                  }}
                >
                  <CustomDropdownPagination
                    handleScroll={handleScroll}
                    commentsContainerRef={commentsContainerRef}
                    dropDownLabel="Select Astrologer"
                    customLabelStyle={{
                      fontWeight: "400",
                      color: "#818181", 
                    }}
                    customStyle={{
                      height: 500,
                    }}
                    transactionCount={transactionCount}
                    searchTextGet={(item) => {
                      if (item == "") {
                        setPageComments(0);
                        setAstrologerList([]);
                      }
                      setSearchText(item);
                    }}
                    items={astrologerList}
                    searchText={searchText}
                    transactions={transactions}
                    selectedAstrologer={
                      transaction.selectedAstrologer == ""
                        ? "Select Astrologer"
                        : transaction.selectedAstrologer
                    }
                    index={index}
                    onSelect={(item) => {
                      const updatedTransactions = [...transactions];
                      updatedTransactions[index].selectedAstrologer = item;
                      setTransactions(updatedTransactions);

                      const updatedAstrologerList = astrologerList.map(
                        (astrologer) =>
                          astrologer.astrologerId === item.astrologerId
                            ? { ...astrologer, isEnabled: !item.isEnabled }
                            : { ...astrologer }
                      );

                      setAstrologerList(updatedAstrologerList);
                    }}
                    onClickPaginationApi={(item) =>
                      onApiCallAstrologerList(item)
                    }
                  />
                </div>
              </label>
              <label style={{ width: "24%" }}>
                Transaction ID *
                <input
                  style={{
                    background: "none",
                    border: "none",
                    border: `1px solid ${
                      transactionErrors[index]?.transactionId
                        ? "red"
                        : "#818181"
                    }`,
                    padding: "0px 10px",
                    borderRadius: "100px",
                    width: "85%",
                    height: "40px",
                    color: "white",
                    marginTop: "5px",
                  }}
                  placeholder="Enter Id"
                  type="text"
                  value={transaction.transactionId}
                  onChange={(e) => {
                    const updatedTransactions = [...transactions];
                    updatedTransactions[index].transactionId = e.target.value;
                    setTransactions(updatedTransactions);
                  }}
                />
              </label>
              <label style={{ width: "24%" }}>
                Amount *
                <input
                  maxLength={7}
                  style={{
                    background: "none",
                    border: `1px solid ${
                      transactionErrors[index]?.amount ? "red" : "#818181"
                    }`,
                    padding: "0px 10px",
                    borderRadius: "100px",
                    width: "85%",
                    height: "40px",
                    color: "white",
                    marginTop: "5px",
                  }}
                  placeholder="Enter amount"
                  // type="number"
                  value={transaction.amount}
                  onChange={(e) => handleInputChange(e, index)}
                  // onChange={(e) => {
                  //   const updatedTransactions = [...transactions];
                  //   updatedTransactions[index].amount = e.target.value.replace(/[^0-9.]/g, "");
                  //   setTransactions(updatedTransactions);
                  // }}
                />
              </label>
              <label style={{ width: "24%" }}>
                Note
                <input
                  maxLength={200}
                  style={{
                    background: "none",
                    border: "none",
                    border: "1px solid  #818181",
                    padding: "0px 10px",
                    borderRadius: "100px",
                    width: "85%",
                    height: "40px",
                    color: "white",
                    marginTop: "5px",
                  }}
                  placeholder="Write note"
                  type="text"
                  value={transaction.note}
                  onChange={(e) => {
                    const updatedTransactions = [...transactions];
                    updatedTransactions[index].note = e.target.value;
                    setTransactions(updatedTransactions);
                  }}
                />
              </label>

              <label
                style={{
                  width: "4%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <br />
                {transactions.length != 1 && (
                  <img
                    onClick={() => onClickDeleteTransaction(transaction, index)}
                    style={{
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                      objectFit: "fill",
                    }}
                    src={Icons.ic_trash}
                  />
                )}
              </label>
            </div>
          ))}
          <div ref={transactionsEndRef} />
        </div>
        {!isAnyFieldEmpty && (
          <div
            className="fontSize16"
            style={{
              cursor: "pointer",
              marginTop: "10px",
              width: "200px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={handleAddOption}
          >
            <img
              src={Icons.Vector}
              style={{ height: "0.7rem", width: "0.7rem", objectFit: "cover" }}
            />
            <div onClick={() => handleAddOption()}>Add new transaction</div>
          </div>
        )}
        <br />
        <div style={{ textAlign: "center", color: "#FFF" }}>or</div>
        {/* File input for CSV */}
        <div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "left",
              alignItems: "baseline",
            }}
          >
            <div
              className="title24FontWeight400WHite"
              style={{ fontSize: "15px" }}
            >
              Select CSV File
            </div>
            <div
              onClick={downloadCSVFile}
              style={{
                cursor: "pointer",
                color: "rgba(46, 144, 250, 1)",
                fontSize: "14px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Download Transaction File
            </div>
          </div>
          <div
            style={{
              width: "100%",
              border: "1px dashed  #818181",
              borderRadius: "10px",
              position: "relative",
              height: "5rem",
              padding: "15px 0px 15px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div>
                {uploadProgress == 100 && (
                  <img
                    // onClick={() => {
                    //   setCSVFileLink(null);
                    //   setUploadProgress(0);
                    // }}
                    onClick={handleFileRemove}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      zIndex: 11111111,
                      top: "0px",
                      marginLeft: "150px",
                      height: "20px",
                      width: "20px",
                    }}
                    src={Icons.ic_close}
                    alt="ic_close"
                    className="modal-ic_close"
                  />
                )}
                <img
                  style={{ height: "40px", width: "40px" }}
                  src={
                    uploadProgress === 100
                      ? Icons.Fileicon
                      : Icons.ic_white_file
                  }
                />
              </div>
              {uploadProgress > 0 && uploadProgress < 100 && (
                <div className="fontSize16" style={{ color: "white" }}>
                  Uploading... {uploadProgress.toFixed(2)}%
                </div>
              )}
              {uploadProgress === 100 && (
                <div style={{ color: "white" }}>
                  {uploadProgress === 100 && (
                    <div style={{ fontSize: "13px" }} className="fontSize16">
                      File uploaded successfully. Size: {fileSizeMB} MB
                    </div>
                  )}
                  {/* Display the file size */}
                </div>
              )}
              {uploadProgress <= 0 && (
                <div
                  style={{ fontSize: "13px", cursor: "pointer" }}
                  className="title24FontWeight400WHite"
                >
                  Select a CSV file to import
                </div>
              )}
              {uploadProgress <= 0 && (
                <div
                  style={{
                    fontSize: "13px",
                    cursor: "pointer",
                    color: Colors.secondary,
                  }}
                >
                  or drag and drop here
                </div>
              )}
            </div>
            <input
              style={{
                width: "100%",
                height: "5rem",
                cursor: "pointer",
                opacity: "0",
                position: "absolute",
                top: "0px",
              }}
              ref={fileInputRef}
              type="file"
              accept=".csv"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            gap: "20px",
          }}
        >
          <div
            style={{
              borderColor: "#818181",
              border: "1px solid #818181",
              padding: "6px 50px",
              borderRadius: "100px",
              color: "white",
              fontFamily: "Poppins-Regular",
              fontSize: "0.8rem",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            Discard
          </div>
          <div
            style={{
              cursor: "pointer",
              background: Colors.white,
              padding: "7px 10px 7px 10px",
              gap: "0.5rem",
              borderRadius: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // background: isFormComplete
              //   ? Colors.white
              //   : Colors.tabBackgroundColor,
            }}
            onClick={handleAddTransaction}
          >
            <div
              // className="darkGreyFont16"
              style={{
                color: Colors.black,
                textAlign: "center",
                fontSize: "0.8rem",
                fontFamily: "Poppins-Regular",
              }}
            >
              Add New Transaction
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTransactionForm;
