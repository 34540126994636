import React from "react";
import Modal from "react-modal";
import Colors from "../../Constants/Colors";
import Icons from "../../Constants/Icons";
const customStyles = {
    overlay: {
      zIndex: 1,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent background
    },
    content: {
      padding: 0, // Remove padding
      margin: "auto", // Center horizontally
      maxWidth: "700px", // Optional: set a maximum width for the modal
      width: "100%", // Optional: make the modal responsive
      border: "none", // Remove border (if any)
      background: "none", // Transparent background
      display: "flex", // Use flexbox for vertical centering
      alignItems: "center", // Center vertically
      justifyContent: "center", // Center horizontally
    },
  };
const CustomModalOrderList = ({ isOpen, onRequestClose, orderList }) => {
  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
    >
      <div
        style={{
          background: Colors.mainDarkColor,
          width: "80%",
          padding: 20,
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div className="fontSize24">Order List</div>
          <img
            src={Icons.ic_close}
            alt="ic_close"
            onClick={onRequestClose}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              justifyContent: "center",
              display: "flex",
              height: "1.3rem",
              width: "1.3rem",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{ maxHeight: "25rem", marginTop: "10px" }}
          className="scrollable-container"
        >
          {orderList.map((item) => {
            return (
              <div style={{ marginTop: "0px", marginBottom: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "0.5rem",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{}} className="indian-man-smiling-astrologer">
                    <img
                      style={{
                        position: "relative",
                        width: "2.63rem",
                        height: "2.63rem",
                      }}
                      alt=""
                      src={Icons.ic_default_user}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div className="fontSize18Weight400">
                      {"item?.products[0]?.productName"}
                    </div>
                    <div
                      style={{
                        color: Colors.greyColor50,
                      }}
                      className="fontSize18Weight400"
                    >
                      {"item?.products[0]?.productPrice"}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div
          style={{ width: "50%", marginTop: "20px" }}
          className="contentName"
        >
          Address :
        </div>

        <div
          style={{ marginTop: "5px", flexDirection: "row", display: "flex" }}
        >
          <div style={{ width: "30%" }} className="contentName">
            Total :
          </div>
          <div style={{ width: "70%" }} className="contentName">
            Total :
          </div>
        </div>
        <div
          style={{ marginTop: "5px", flexDirection: "row", display: "flex" }}
        >
          <div style={{ width: "30%" }} className="contentName">
            Total :
          </div>
          <div style={{ width: "70%" }} className="contentName">
            Total :
          </div>
        </div>
        <div
          style={{ marginTop: "5px", flexDirection: "row", display: "flex" }}
        >
          <div style={{ width: "30%" }} className="contentName">
            Total :
          </div>
          <div style={{ width: "70%" }} className="contentName">
            Total :
          </div>
        </div>
      </div>
    </Modal>
  );
};

// CustomModal.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   onRequestClose: PropTypes.func.isRequired,
//   orderList: PropTypes.arrayOf(
//     PropTypes.shape({
//       products: PropTypes.arrayOf(
//         PropTypes.shape({
//           productName: PropTypes.string,
//           productPrice: PropTypes.string,
//         })
//       ),
//     })
//   ).isRequired,
//   title: PropTypes.string,
// };

// CustomModal.defaultProps = {
//   title: "Order List",
// };
export default CustomModalOrderList;
