// Home.js
import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./AstrologerStyle.css";
import "./../../../Constants/CommonStyle.css";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Icons from "../../../Constants/Icons";
import AstrologerRenderList from "./CustomeScreen/AstrologerRenderList";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import Header from "../../../Components/Header/Header";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { getAllAstrologerList } from "../../../Core/Apicall";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import { useNavigate } from "react-router-dom";
import UseDebounce from "../../../Components/UseDebounce";
function Astrologer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = ["All", "Live", "Consulting", "Away", "Online"];
  const [astrologersData, setAstrologersData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [totalAstrologer, setTotalAstrologer] = useState(0);
  const [totalAstrologerCount, setTotalAstrologerCount] = useState(0);
  const [totalBusyAstrologers, setTotalBusyAstrologers] = useState(0);
  const [totalOfflineAstrologers, setTotalOfflineAstrologers] = useState(0);
  const [totalOnlineAstrologers, setTotalOnlineAstrologers] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownSelected, setDropDownSelectedValue] = useState("All");

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  const debouncedInput = UseDebounce(searchValue, 500);
  useEffect(() => {
    setPageComments(0);
    setAstrologersData([]);
    const searchQuery = debouncedInput || searchValue;
    onClickAstrologerMainListApi(dropdownSelected, searchQuery, 0);
  }, [dropdownSelected, debouncedInput]);

  const onClickAstrologerMainListApi = (item, item2, pageComments) => {
    const FilterMethodMap = {
      Live: "live",
      Consulting: "consulting",
      Away: "away",
      Online: "online",
    };

    const filterMethodPass = FilterMethodMap[item] || "";
    const searchStatus = item2 || "";

    let param = {
      offset: 30 * pageComments,
      limit: 30,
      status: filterMethodPass,
      search: searchStatus,
    };
    dispatch(setLoadingTrue());
    getAllAstrologerList(param)
      .then((response) => {
        setAstrologersData((prevData) => [
          ...prevData,
          ...response.AstrologerProfilesData,
        ]);
        setTotalAstrologer(Math.ceil(response.totalCountForPagination / 30));
        setTotalAstrologerCount(response.totalAstrologer);
        setTotalBusyAstrologers(response.totalBusyAstrologers);
        setTotalOfflineAstrologers(response.totalOfflineAstrologers);
        setTotalOnlineAstrologers(response.totalOnlineAstrologers);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const parseAvgTime = (time) => {
    const [value] = time.split(" ");
    const numericValue = parseFloat(value);
    return numericValue;
  };

  const onCLickSortingArray = (property) => {
    const sortedData = [...astrologersData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    if (property == "avg") {
      sortedData.sort((a, b) => {
        if (sortOrder === "asc") {
          return parseAvgTime(a[property]) - parseAvgTime(b[property]);
        } else {
          return parseAvgTime(b[property]) - parseAvgTime(a[property]);
        }
      });
      setAstrologersData(sortedData);
    } else {
      sortedData.sort((a, b) => {
        if (sortOrder === "asc") {
          return a[property] > b[property] ? 1 : -1;
        } else {
          return a[property] < b[property] ? 1 : -1;
        }
      });
      setAstrologersData(sortedData);
    }
  };

  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalAstrologer > pageComments + 1) {
        setPageComments((prevPage) => {
          const nextPage = prevPage + 1;
          onClickAstrologerMainListApi(dropdownSelected, searchValue, nextPage);
          return nextPage;
        });
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Astrologers"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total astrologers</div>
                <div className="fontSize24">{totalAstrologerCount}</div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total online</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {totalOnlineAstrologers}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total busy</div>
                <div
                  style={{ color: Colors.darkOrange }}
                  className="fontSize24"
                >
                  {totalBusyAstrologers}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total offline</div>
                <div style={{ color: Colors.darkRed }} className="fontSize24">
                  {totalOfflineAstrologers}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="astrologers-list-parent">
          <div className="fontSize24">Astrologers list</div>
          <div className="frame-parent2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  border: Colors.darkBorderColor,
                  boxSizing: "border-box",
                  width: "9.38rem",
                  display: "flex",
                  zIndex: 1,
                  padding: "0.5rem 0.5rem",
                }}
              >
                <CustomDropdown
                  dropDownLabel={dropdownSelected}
                  items={items}
                  color="blue"
                  onSelect={(item) => setDropDownSelectedValue(item)}
                  customLabelStyle={{
                    fontWeight: "400",
                    color: Colors.greyColor50,
                  }}
                />
              </div>

              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxSizing: "border-box",
                  width: "15.75rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem 0.5rem",
                  gap: "0.5rem",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.5rem",
                    height: "1.5rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={Icons.ic_search}
                />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-parent3">
          <div
            style={{
              justifyContent: "flex-start",
              alignSelf: "stretch",
              flex: 1,
            }}
            className="name-parent"
          >
            <div
              style={{
                width: "20%",
              }}
              className="fontSize16WithoutFlex"
            >
              Name
            </div>
            <div
              onClick={() => onCLickSortingArray("Rating")}
              style={{
                justifyContent: "flex-start",
                cursor: "pointer",
                width: "10%",
              }}
              className="call-sessions-parent"
            >
              <div className="fontSize16WithoutFlex">Ratings</div>
              <img className="ic_sorting" alt="" src={Icons.ic_shorting} />
            </div>

            <div
              onClick={() => onCLickSortingArray("totalSession")}
              style={{
                width: "8%",
                cursor: "pointer",
                justifyContent: "flex-start",
              }}
              className="total-sessions-parent"
            >
              <div style={{}} className="fontSize16WithoutFlex">
               Astrologer Fees
              </div>
              <img
                style={{}}
                className="ic_sorting"
                alt=""
                src={Icons.ic_shorting}
              />
            </div>
            <div
              onClick={() => onCLickSortingArray("averageTimeSpend")}
              style={{
                cursor: "pointer",
                width: "17%",
                justifyContent: "center",
              }}
              className="call-sessions-parent"
            >
              <div style={{}} className="fontSize16WithoutFlex">
                Avg. time spends
              </div>
              <img className="ic_sorting" alt="" src={Icons.ic_shorting} />
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                width: "35%",
              }}
              className="fontSize16WithoutFlex"
            >
              Expertise
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                width: "10%",
              }}
              className="fontSize16WithoutFlex"
            >
              Status
            </div>
            {/* <div style={{ width: "3px" }}></div> */}
          </div>
          <div
            style={{ maxHeight: "500px" }}
            onScroll={handleScroll}
            ref={commentsContainerRef}
            className="scrollable-container"
          >
            {astrologersData.map((session, index) => (
              <AstrologerRenderList
                key={index}
                astrologerId={session.astrologerId}
                name={session.userName}
                profession={session.userRole}
                ratings={session.Rating}
                session_type={session.session_type}
                chatsessions={session.chatSessionCount}
                price={session.totalEarning}
                status={session.userStatus}
                expertise={session.expertise}
                avg={session.averageTimeSpend}
                liveSession={session.liveSessionCount}
                totalSession={session.totalSession}
                callsessions={session.callSessionCount}
                profileUrl={session.profileUrl}
                session={session}
              />
            ))}
          </div>
        </div>
        {astrologersData.length <= 0 && <EmptyData />}
      </div>
    </div>
  );
}

export default Astrologer;
