import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC1Sa31Onp0CGYfG8kg-xj0YZD2ttYMYdI",
  authDomain: "testing-web-79098.firebaseapp.com",
  projectId: "testing-web-79098",
  storageBucket: "testing-web-79098.appspot.com",
  messagingSenderId: "803808077098",
  appId: "1:803808077098:web:f09965386ddf39b97cea53",
  measurementId: "G-EWDB10QS84",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const registration = await navigator.serviceWorker.register(
      "/firebase-messaging-sw.js"
    );
    const currentToken = await getToken(messaging, {
      vapidKey: 'BCZ5-_LFxBSjXyFEJEELuOKUvx41DuxovSwoKLsZn2WwoC0innVtvZYQD8cYMBDW83BgHmBVi2c5-sOTVSrZf9U',
      serviceWorkerRegistration: registration,
    });
    if (currentToken) {
      return currentToken;
    } else {
     
    }
  } catch (err) {
    console.error("An error occurred while retrieving token:", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
