// apiService.js

import { BaseApiUrl } from "../Constants/Until";

export const CustomUploadImage = (formData,token) => {
  const requestOptions = {
    method: 'post',
    body: formData,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };

  return fetch(BaseApiUrl+"astrologer/upload-astrologer-images", requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error('There was an error uploading the profile picture: ' + error.message);
    });
};
