import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import Loader from "../../../Components/Loader/Loader";
import Icons from "../../../Constants/Icons";

import "./ResetPassword.css";
const ResetPassword = ({ onLogin }) => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState(""); // State for old password
  const [newPassword, setNewPassword] = useState(""); // State for new password
  const [confirmPassword, setConfirmPassword] = useState(""); // State f
  const [emailErroMsg, setEmailErrorMsg] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false); // State to toggle password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfrimPassword] = useState(false); // State to toggle password visibility
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    if(confirmPassword =='' || newPassword =='' ||confirmPassword =='' ){
      alert("Please fill in all required fields.");
      return true
    }
    if (newPassword != confirmPassword) {
        setPasswordsMatch(false);
        return true
    }
    dispatch(setLoadingTrue());
    setTimeout(() => {
      dispatch(setLoadingFalse());
    }, 3000);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "5rem",
        height: "100vh",
        minHeight: "100vh", // Set minimum height to cover the viewport
      }}
    >
      <div
        style={{
          color: "#FFF",
          paddingTop: "30px",
          fontSize: "1.6rem",
          fontWeight: "bold",
        }}
      >
        Reset Password
      </div>
      <div
        style={{
          marginTop: "50px",
          border: "1px solid white",
          borderRadius: "6px",
          width: "40vh",
          display: "flex",
          padding: "10px 15px",
        }}
      >
        <input
          type={showOldPassword ? "text" : "password"}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          className="searchTextInput"
          placeholder="Old Password"
          style={{
            width: "100%", // Ensure the input fills the parent div
          }}
        />
        <img
          onClick={() => setShowOldPassword(!showOldPassword)}
          src={showOldPassword ? Icons.show_password : Icons.hide_password}
          style={{ cursor: "pointer", height: "1.2rem", width: "1.2rem" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          border: "1px solid white",
          borderRadius: "6px",
          width: "40vh",
          display: "flex",
          padding: "10px 15px",
        }}
      >
        <input
          type={showNewPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="searchTextInput"
          placeholder="New Password"
          style={{
            width: "100%", // Ensure the input fills the parent div
          }}
        />
        <img
          onClick={() => setShowNewPassword(!showNewPassword)}
          src={showNewPassword ? Icons.show_password : Icons.hide_password}
          style={{ cursor: "pointer", height: "1.2rem", width: "1.2rem" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          border: "1px solid white",
          borderRadius: "6px",
          display: "flex",
          width: "40vh",
          padding: "10px 15px",
        }}
      >
        <input
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="searchTextInput"
          placeholder="Confirm New Password"
          style={{
            width: "100%", // Ensure the input fills the parent div
          }}
        />
        <img
          onClick={() => setShowConfrimPassword(!showConfirmPassword)}
          src={showConfirmPassword ? Icons.show_password : Icons.hide_password}
          style={{ cursor: "pointer", height: "1.2rem", width: "1.2rem" }}
        />
      </div>
      {!passwordsMatch && (
        <div
          style={{
          display: "flex",
          paddingTop: "5px",
          color: "red",
          width: "40vh",
          padding: "10px 15px",
          }}
        >
         Passwords do not match.
        </div>
      )}
      <div
        onClick={() => handleLogin()}
        style={{
          background: "#ecd5ff33",
          color: "#FFF",
          cursor: "pointer",
          fontSize: "1rem",
          width: "20vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          padding: "7px",
          borderRadius: "7px",
          fontWeight: "bold",
        }}
      >
        Submit
      </div>
      <Loader />
    </div>
  );
};

export default ResetPassword;
